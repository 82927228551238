import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment/moment';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

export default function RegPeriod() {
    const classes = useStyles();

    const [dataPeriodo, setDataPeriodo] = useState({
        idPeriodo:0,
        nombrePeriodo:"",
        anioPeriodo:"",
        inicioPeriodo: "",
        finPeriodo:"",
        numBecas:"",
    });

    const [formDataPeriodo, setFormDataPeriodo] = useState({
        nombrePeriodo:"",
        anioPeriodo:"",
        inicioPeriodo:"",
        finPeriodo:"",
        numBecas:"",
    });

    const [selectPeriodo, setSelectPeriodo] = useState([]);

    const [refrescar, setRefrescar] = useState(true);

    // Para control de mensajes de exito y error
    const [mensaje, setMensaje] = useState({
        mostrarMensaje: false,
        textoMensaje:"Se actualizo de manera correcta",
        tipoMensaje:"success"
    });
    // Fin control de mensajes

    const mensajeRequerido = "Campo requerido";
    const mensajeDatoNumerico = "El dato debe ser númerico";
    const mensajeDatoCadena = "La cadena no debe de tener carácteres especiales"
    const mensajeFechaFinMayor = "La fecha de inicio no debe ser mayor a la fecha fin"

    const regexTexto = /^[0-9a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-_]+$/u;
    const regexEntero = /^[0-9]+$/;

    useEffect(() => {
        limpiarDatos();
        axios.get('https://prod-backend-dot-driven-presence-373215.uc.r.appspot.com/api/v1/admin/periodo_becas_read/',
        { headers: {"Authorization" : `Bearer ${sessionStorage.getItem('token')}`}})
        .then((response) => {
            setSelectPeriodo(response.data.periodos_becas);
        })
        .catch((error) => {
            console.error(error);
        });
    }, [refrescar]);

    const handleChanguePeriodo = (idPeriodo) => {
        limpiarDatos();
        if(idPeriodo > 0) {
            const periodoAux = selectPeriodo.filter(periodo => periodo.id_periodo_becas === idPeriodo);
            setDataPeriodo(
                {
                    idPeriodo:periodoAux[0].id_periodo_becas,
                    nombrePeriodo:periodoAux[0].nombre_periodo,
                    anioPeriodo:periodoAux[0].anio_periodo,
                    inicioPeriodo: moment(periodoAux[0].fecha_inicio, "DD/MM/yyyy HH:mm:ss").format("yyyy-MM-DDTHH:mm"),
                    finPeriodo: moment(periodoAux[0].fecha_fin, "DD/MM/yyyy HH:mm:ss").format("yyyy-MM-DDTHH:mm"),
                    numBecas:periodoAux[0].max_number_becas,
                }
            )
        }
    }

    // validaciones 
    // tipoDato:1.-Cadena , 2.-Numero
    const handleChangueForm = (event,nombreCampo,tipoDato) => {
        const value = event.target.value;
        const mensaje = realizaValidaciones(value,tipoDato);
        setFormDataPeriodo({...formDataPeriodo,[nombreCampo]:mensaje})
        setDataPeriodo({...dataPeriodo,[nombreCampo]:value});
    };

    const realizaValidaciones = (value,tipoDato) => {
        const msjReq = validaRequerido(value);

        if(msjReq) {
            return msjReq;
        }

        switch(tipoDato) {
            case 1: return validaDatoCadena(value);
            case 2: return validaDatoNumerico(value);
            default: return '';
        }
    }

    const validaRequerido = (valorCampo) => {
        return (valorCampo === null || valorCampo === "") ? mensajeRequerido:"";
    }

    const validaDatoCadena = (valorCampo) => {
        return regexTexto.test(valorCampo) ? "":mensajeDatoCadena;
    }

    const validaDatoNumerico = (valorCampo) => {
        return regexEntero.test(valorCampo) ? "":mensajeDatoNumerico;
    }

    const validaFechas = () => {
        let mensaje = "";
        if(dataPeriodo.inicioPeriodo && dataPeriodo.finPeriodo) {
            const fechaInicio = new Date(dataPeriodo.inicioPeriodo);
            const fechaFin = new Date(dataPeriodo.finPeriodo);
            if(fechaInicio > fechaFin ) {
                mensaje = mensajeFechaFinMayor;
            }
        }
        setFormDataPeriodo({...formDataPeriodo, inicioPeriodo:mensaje, finPeriodo:mensaje});

        return mensaje ? false : true;
    }

    const validaForm = () => {
        for (const nombreCampo in formDataPeriodo) {
            if(!dataPeriodo[nombreCampo]) {
                setFormDataPeriodo({...formDataPeriodo,[nombreCampo]:mensajeRequerido});
                return false;
            }
            if(formDataPeriodo[nombreCampo]){
                return false;
            }
        }
        return true;
    }

    const limpiarDatos = () => {
        setDataPeriodo(
            {
                idPeriodo:0,
                nombrePeriodo:"",
                anioPeriodo:"",
                inicioPeriodo: "",
                finPeriodo:"",
                numBecas:"",
            }
        );

        setFormDataPeriodo({
            nombrePeriodo:"",
            anioPeriodo:"",
            inicioPeriodo:"",
            finPeriodo:"",
            numBecas:"",
        });
    }

    const guardarDatos = () => {
        if((validaFechas()&&validaForm())) {

            const periodData = new FormData();
            if(dataPeriodo.idPeriodo > 0) {
                periodData.append("id_periodo_becas",dataPeriodo.idPeriodo);
            }
            periodData.append("nombre_periodo",dataPeriodo.nombrePeriodo);
            periodData.append("anio_periodo",dataPeriodo.anioPeriodo);
            periodData.append("fecha_inicio", moment(dataPeriodo.inicioPeriodo,"yyyy-MM-DDTHH:mm").format("yyyy-MM-DD HH:mm:ss"));
            periodData.append("fecha_fin", moment(dataPeriodo.finPeriodo,"yyyy-MM-DDTHH:mm").format("yyyy-MM-DD HH:mm:ss"));
            periodData.append("max_number_becas", dataPeriodo.numBecas);

            axios.post('https://prod-backend-dot-driven-presence-373215.uc.r.appspot.com/api/v1/admin/periodo_becas_write/',periodData,
            { headers: {"Authorization" : `Bearer ${sessionStorage.getItem('token')}`}})
            .then(res => {
                if(res.data.respuesta ==='ok') {
                    setRefrescar(!refrescar);
                    setMensaje({
                        mostrarMensaje:true,
                        textoMensaje:"Se guardo correctamente la información.",
                        tipoMensaje:"success"
                    });
                } else {
                    setMensaje({
                        mostrarMensaje:true,
                        textoMensaje:"Se produjo un error al guardar la información.",
                        tipoMensaje:"error"
                    });
                }
            })
            .catch(error => {
                setMensaje({
                    mostrarMensaje:true,
                    textoMensaje:"Se produjo un error al guardar la información.",
                    tipoMensaje:"error"
                });
                console.error(error)
            })
        }
    }

    return (
    <>  
        <h3>Registro del periodo</h3>
        <hr /> <br />
        <Grid className='formRegPeriodCert' container rowSpacing={6} columnSpacing={6}>
            <Grid item xs={12} md={12}>
                <span className='nota_asterisco'>*</span><span className='nota'> Para modificar un periodo seleccionalo en el combo</span>
                <br />
                <Select
                    className='select_admin'
                    id="idPeriodo"
                    onChange={(event)=>{handleChanguePeriodo(event.target.value)}}
                    value={dataPeriodo.idPeriodo}
                >
                    <MenuItem value={0}>Nuevo Periodo</MenuItem>
                    {
                        selectPeriodo && 
                        selectPeriodo.map( periodo => (
                            <MenuItem key={periodo.id_periodo_becas} value={periodo.id_periodo_becas}>{periodo.nombre_periodo}</MenuItem>
                        ))
                    }
                </Select>
            </Grid>
            <Grid item xs={12} md={4}>
                <TextField
                id="nombrePeriodio"
                label="Nombre del periodo"
                type="text"
                value={dataPeriodo.nombrePeriodo}
                error={formDataPeriodo.nombrePeriodo}
                helperText={formDataPeriodo.nombrePeriodo}
                onChange={(event)=>handleChangueForm(event,'nombrePeriodo',1)}
                inputProps={{ maxLength: 50 }}
                className={classes.textField}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <TextField
                id="anioPeriodo"
                label="Año del periodo"
                type="text"
                value={dataPeriodo.anioPeriodo}
                error={formDataPeriodo.anioPeriodo}
                helperText={formDataPeriodo.anioPeriodo}
                onChange={(event)=>handleChangueForm(event,'anioPeriodo',2)}
                inputProps={{ maxLength: 4 }}
                className={classes.textField}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <TextField
                id="numBecas"
                label="Máximo de becas"
                type="text"
                value={dataPeriodo.numBecas}
                error={formDataPeriodo.numBecas}
                helperText={formDataPeriodo.numBecas}
                onChange={(event)=>handleChangueForm(event,'numBecas',2)}
                inputProps={{ maxLength: 7 }}
                className={classes.textField}
                />
            </Grid>
            <Grid item  xs={12} md={4}>
                <TextField
                    id="date_inicio"
                    label="Inicio Periodo"
                    type="datetime-local"
                    value={dataPeriodo.inicioPeriodo}
                    error={formDataPeriodo.inicioPeriodo}
                    helperText={formDataPeriodo.inicioPeriodo}
                    onChange={(event)=>handleChangueForm(event,'inicioPeriodo',3)}
                    className={classes.textField}
                    InputLabelProps={{
                    shrink: true,
                    }}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <TextField
                    id="date_fin"
                    label="Fin Periodo"
                    type="datetime-local"
                    value={dataPeriodo.finPeriodo}
                    error={formDataPeriodo.finPeriodo}
                    helperText={formDataPeriodo.finPeriodo}
                    onChange={(event)=>handleChangueForm(event,'finPeriodo',3)}
                    className={classes.textField}
                    InputLabelProps={{
                    shrink: true,
                    }}
                />
            </Grid>
        </Grid>
        <div className='form-button form-button-background-morado'>
            <Button variant="contained" 
            size="small" 
            onClick={guardarDatos}
            >Guardar</Button>
        </div>
        <Snackbar
            open={mensaje.mostrarMensaje}
            autoHideDuration={3000}
            onClose={()=>setMensaje({...mensaje,mostrarMensaje:false})}
        >
            <MuiAlert elevation={6} variant="filled" severity={mensaje.tipoMensaje}> {mensaje.textoMensaje} </MuiAlert>
        </Snackbar>
    </>   
    );
  
  }