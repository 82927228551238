import React, {useState} from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import TreeItem from '@mui/lab/TreeItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TreeView from '@mui/lab/TreeView';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TextField from "@mui/material/TextField";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';

const useStyles = makeStyles((theme) => ({
    heading: {
      fontSize: theme.typography.pxToRem(22),
      fontWeight: theme.typography.fontWeightMedium,
    },
}));

export default function SeccionAsk({soloPreguntasFrecuentes}) {
  
    const classes = useStyles();

    // Se declaran variables para validacion del envio a soporte y ayuda
    const [datos, setDatos] = useState(
      {
        nombreCompleto:"",
        correo:"",
        mensaje:"",
      }
    );

    const [mensajeDatos, setMensajeDatos] = useState({
      nombreCompleto:"",
      correo:"",
      mensaje:"",
    });

    const [mensajePrivacidad, setMensajePrivacidad] = useState(false);
    const [envioExitoso, setEnvioExitoso] = useState(0);

    const regexTexto = /^[0-9a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-_]+$/u;
    const regexCorreo = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    const mensajeRequerido = "Campo requerido";
    const mensajeDatoCadena = "La cadena no debe de tener carácteres especiales";
    const mensajeCorreoIncorrecto = "Por favor ingresa un correo valido";


    const metaData = {
      nombreCompleto:{requerido:true, regex:regexTexto, mensajeRegex:mensajeDatoCadena},
      correo:{requerido:true, regex:regexCorreo, mensajeRegex:mensajeCorreoIncorrecto},
      mensaje:{requerido:true, regex:regexTexto, mensajeRegex:mensajeDatoCadena},
    };

    const limpiarDatos = () => {
      const dataAux = {
        nombreCompleto:"",
        correo:"",
        mensaje:"",
      }

      setDatos({...dataAux});
      setMensajeDatos({...dataAux});
      setMensajePrivacidad(false);

    }

    const handleChange = (value,nombreParametro) => {
      setEnvioExitoso(0);
      setDatos({...datos,[nombreParametro]:value});
      validaDato(value,nombreParametro);
    }

    const validaDato = (value,nombreParametro) => {
      if(metaData[nombreParametro].regex && !value) {
        setMensajeDatos({...mensajeDatos,[nombreParametro]:mensajeRequerido});
        return false;
      }

      if(metaData[nombreParametro].regex && value && !metaData[nombreParametro].regex.test(value)) {
        setMensajeDatos({...mensajeDatos,[nombreParametro]:metaData[nombreParametro].mensajeRegex});
        return false;
      }

      setMensajeDatos({...mensajeDatos,[nombreParametro]:""});
      return true;
    }

    const enviaFormulario = () => {
      // Se validan datos
      for(const elemento in datos) {
        if(!validaDato(datos[elemento],elemento)) {
          return false;
        }
      }

      //Si todo sale bien se prepara el envio de la información
      const headersPost = {
        'content-type': 'text/json'
      }
      
      const request = {
        nombre: datos.nombreCompleto,
        email: datos.correo,
        mensaje: datos.mensaje
      }
  
      const headers_post = JSON.stringify(headersPost)
    
      const data = request

      axios.post('https://prod-backend-dot-driven-presence-373215.uc.r.appspot.com/api/v1/support_help/contact_by_email/', data, headers_post )
      .then(res => {
        setEnvioExitoso(1);
        limpiarDatos();
      }).catch(res =>{
        setEnvioExitoso(2);
        console.error(res);
      })
    }

    return (
        <div className={classes.root+' acordion-root container-cards'}>
          { !soloPreguntasFrecuentes&&
          <Accordion className='acordion-container'>
            <AccordionSummary
              className='acordion-item-ask'
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
                <Typography className={classes.heading}>
                    Condiciones de uso de la beca
                </Typography>
            </AccordionSummary>
            <AccordionDetails className='acordion-item-detail'>
              <Typography>
                <ul>
                  <li>
                    Deberás seleccionar uno de los 6 Certificados para poder solicitar la beca.​
                  </li>
                  <li>
                    Si eres seleccionada(o), recibirás un correo electrónico de Coursera en un plazo de 1 a 2 semanas, mediante el cual podrás inscribirte al Certificado que elegiste (dentro de las dos primeras semanas, de lo contrario, la beca será asignada a otra persona).​
                  </li>
                  <li>
                    Para comenzarlo debes entrar a tu cuenta de Coursera (si no tienes una, date de alta).​
                  </li>
                  <li>
                    Importante: el correo que registres inicialmente será con el que continúes durante todo tu proceso y no habrá ningún cambio. 
                  </li>
                  <li>
                    En caso de que quieras realizar más Certificados, deberás concluir primero aquél en el que estés inscrita(o); posteriormente podrás seleccionar otro directamente en nuestro portal.
                  </li>
                  <li>
                    Recuerda que tu beca expira el 31 de diciembre de 2024. Recibirás un Certificado final al momento de concluir todos los módulos que lo componen.
                  </li>
                  <li>
                    Te adelantamos que, para no perder tu beca en el camino, deberás tener avance continuo en el Certificado, si presentas inactividad en un plazo mayor a dos semanas se te dará de baja del Programa.
                  </li>
                </ul>
              </Typography>
            </AccordionDetails>
          </Accordion>
          }
          <Accordion id='ask-freq' className='acordion-container'>
            <AccordionSummary
              className='acordion-item-ask'
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
                <Typography className={classes.heading}>
                    Preguntas frecuentes
                </Typography>
            </AccordionSummary>
            <AccordionDetails className='acordion-item-detail'>
              {/* <Accordion className='acordion-container sub_acordeon'>
                <AccordionSummary
                  className='acordion-item-ask'
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className={classes.heading}>
                    ● Los Certificados de Carrera de Google
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className='acordion-item-detail'>
                  <Typography>
                    <TreeView className="seccion_preguntas"
                    aria-label="file system navigator" defaultCollapseIcon={<ExpandMoreIcon />} defaultExpandIcon={<ChevronRightIcon />} sx={{ flexGrow: 1 }}>
                      <TreeItem nodeId="301" style={{color:'black'}} label="¿Qué son los Certificados de Carrera de Google?">
                      <TreeItem nodeId="302" style={{color:'black'}}/>
                        <p>
                        Los Certificados de Carrera de Google son programas de formación online flexibles y diseñados
                        para adquirir competencias profesionales en campos con una demanda elevada y gran crecimiento. 
                        Desarrollan habilidades a través de actividades en un laboratorio virtual y otras evaluaciones 
                        interactivas que pondrán a prueba tus conocimientos.
                        </p>
                      </TreeItem>
                    </TreeView>
                  </Typography>
                </AccordionDetails>
              </Accordion> */}
              <Typography>
                <TreeView className="seccion_preguntas"
                  aria-label="file system navigator" defaultCollapseIcon={<ExpandMoreIcon />} defaultExpandIcon={<ChevronRightIcon />} sx={{ flexGrow: 1 }}>
                  
                  <TreeItem nodeId="3" style={{color:'#4285F4'}} label="Los Certificados de Carrera de Google">
                    
                    <TreeItem nodeId="301" style={{color:'black'}} label="¿Qué son los Certificados de Carrera de Google?">
                      <TreeItem nodeId="302" style={{color:'black'}}/>
                        <p>
                        Los Certificados de Carrera de Google son programas de formación online flexibles y diseñados
                        para adquirir competencias profesionales en campos con una demanda elevada y gran crecimiento. 
                        Desarrollan habilidades a través de actividades en un laboratorio virtual y otras evaluaciones 
                        interactivas que pondrán a prueba tus conocimientos.
                        </p>
                    </TreeItem>

                    <TreeItem nodeId="311" style={{color:'black'}} label="¿Se necesita formación o experiencia previa para realizar estos Certificados?">
                      <TreeItem nodeId="312" style={{color:'black'}}/>
                        <p>
                          ¡Ninguna! No se necesita formación o experiencia previa. Estos programas son excelentes opciones para quienes desean incursionar en cualquiera de los campos que abarcan los Certificados de Carrera de Google.
                        </p>
                    </TreeItem>

                    <TreeItem nodeId="321" style={{color:'black'}} label="¿Cuáles son los Certificados en los que aplica la beca?">
                      <TreeItem nodeId="322" style={{color:'black'}}/>
                        <p>
                        La beca aplica para los nuevos Certificados de Carrera de Google en español, siguientes:
                        </p>
                        <br></br>
                        <ul>
                        <li>Soporte de Tecnología de la Información</li>
                        <li>Análisis de Datos</li>
                        <li>Gestión de Proyectos</li>
                        <li>Diseño de Experiencia de Usuario</li>
                        <li>Marketing Digital e E-commerce</li>
                        <li>Ciberseguridad</li>
                        </ul>
                        <br></br>
                    </TreeItem>

                    <TreeItem nodeId="323" style={{color:'black'}} label="¿Qué es el curso de Fundamentos de IA?">
                      <TreeItem nodeId="324" style={{color:'black'}}/>
                        <p>
                        Es un curso que complementa el contenido de los Certificados de Google y que permite desarrollar habilidades esenciales en esta área en un tiempo de entre 10 y 15 horas.
                        </p>
                    </TreeItem>

                    <TreeItem nodeId="325" style={{color:'black'}} label="¿Para quien es el curso de Fundamentos de IA de Google?">
                      <TreeItem nodeId="326" style={{color:'black'}}/>
                        <p>
                        Este curso está destinado a quienes quieran adquirir conocimientos básicos sobre Inteligencia Artificial, donde aprenderán a utilizar herramientas de IA para la innovación de productos y servicios, sin necesidad de contar con habilidades previas. También participan especialistas en Inteligencia Artificial de Google para instruir y capacitar sobre esta tecnología, su potencial y cómo aplicarla en el día a día, entre otros aspectos.
                        </p>
                    </TreeItem>

                    <TreeItem nodeId="331" style={{color:'black'}} label="¿Puedo acceder a más de un Certificado de Carrera de Google con una sola beca?">
                      <TreeItem nodeId="332" style={{color:'black'}}/>
                        <p>
                          Si, pero tendrás que concluir primero el Certificado al cual te inscribiste inicialmente antes de solicitar otro, y terminarlo antes del 30 de abril de 2025. Por ello, te recomendamos concluir el primer Certificado/Curso lo antes posible y así tener el tiempo suficiente para concluir otros Certificados antes de que venza el plazo de tu beca.
                        </p>
                    </TreeItem>
                  
                    <TreeItem nodeId="341" style={{color:'black'}} label="¿Cuántas horas aproximadamente me llevará hacer un Certificado?">
                      <TreeItem nodeId="342" style={{color:'black'}}/>
                        <p>
                        <ul>
                          <li>Cada alumno tiene un ritmo distinto, pero normalmente cada Certificado requiere de 5 a 10 horas de aprendizaje por semana, para completarlo al 100% entre 3 y 6 meses. *El tiempo puede variar en función de la dedicación y los conocimientos previos de quien lo cursa.</li>
                          <li>Te sugerimos ampliamente organizar tu horario semanal para lograr concluir en tiempo y forma. ¡Aprovecha al máximo esta gran oportunidad! </li>
                        </ul>
                        </p>
                    </TreeItem>

                    <TreeItem nodeId="351" style={{color:'black'}} label="¿Los Certificados de Carrera/Curso de Google son 100% online?">
                      <TreeItem nodeId="352" style={{color:'black'}}/>
                        <p>
                        <ul>
                          <li>Los certificados son completamente en línea. Por lo tanto, no es necesario que te presentes a un salón de clases, ya que éstos se conforman por videos instructivos, lecturas y prácticas totalmente virtuales, así que, podrás acceder a ellos mediante la plataforma Coursera, cuando desees y desde cualquier lugar, a través de la Web o tu dispositivo móvil. </li>
                        
                          <li>Si ya tienes una cuenta en Coursera con el mismo correo que utilizaste para solicitar la beca (correo becado), podrás utilizarla, y si no, deberás crear una cuenta una vez que tu beca fue otorgada. </li>
                        </ul>
                        </p>
                    </TreeItem>


                    <TreeItem nodeId="361" style={{color:'black'}} label="¿Qué horario tendrán las clases?">
                      <TreeItem nodeId="362" style={{color:'black'}}/>
                        <p>
                        Los cursos son 100% de autoestudio online dentro de la plataforma Coursera y puedes 
                        realizarlos a tu propio ritmo en los horarios que más te convengan. 
                        La plataforma funciona 24 horas durante todo el año.
                        </p>
                    </TreeItem>


                    <TreeItem nodeId="371" style={{color:'black'}} label="¿Cuándo comenzará el Certificado/Curso?">
                      <TreeItem nodeId="372" style={{color:'black'}}/>
                        <p>
                        Podrás empezar el Certificado/Curso a partir del momento en el que se confirme tu beca. Esto lleva un plazo de una a dos semanas después de haber concluido tu solicitud. La beca estará vigente hasta el 30 de abril de 2025, fecha en la cual tendrás que haber terminado por lo menos uno de los seis Certificados. 
                        </p>
                    </TreeItem>
                  
                    <TreeItem nodeId="381" style={{color:'black'}} label="¿Qué es Coursera?">
                      <TreeItem nodeId="382" style={{color:'black'}}/>
                        <p>
                        Coursera es una plataforma global de aprendizaje en línea que ofrece acceso a cursos en línea. Google trabaja con Coursera para que sus Certificados de Carrera estén disponibles en su plataforma. Si ya tienes una cuenta Coursera (con el correo becado), podrás utilizarla, si no, deberás crear una cuenta nueva una vez que tu beca fue otorgada.
                        </p>
                    </TreeItem>

                    <TreeItem nodeId="391" style={{color:'black'}} label="¿Quién diseñó los Certificados de Carrera de Google?">
                      <TreeItem nodeId="392" style={{color:'black'}}/>
                        <p>
                        <ul>
                        <li>Expertos en la materia y profesionales avanzados de Google de cada campo de trabajo diseñaron y crearon los Certificados de Carrera de Google.</li>
                        <li>Los Certificados se crearon para brindarles a los alumnos conocimientos teóricos y prácticos, y habilidades de resolución de problemas de la vida real a fin de ser exitosos en un puesto básico. Organizaciones y plataformas expertas de la industria, como Project Management Institute para la administración de proyectos, Tableu para análisis de datos y Figma para diseño de experiencia del usuario, colaboraron con este material. </li>
                        </ul>
                        </p>
                    </TreeItem>
                  </TreeItem>

                  <TreeItem nodeId="40" style={{color:'#4285F4'}} label="Las Becas para los Fundamentos y Certificados de Carrera de Google">
                    
                    <TreeItem nodeId='401' style={{ color:'black'}} label="¿Cuáles son los requisitos para solicitar una beca?">
                      <TreeItem nodeId='402' style={{ color:'black' }} />
                      <p>
                        <ul>
                          <li>Ser una persona comprometida con ganas de crecer y desarrollarse, 
                            y con interés de aprender en temas relacionados a las becas ofrecidas.
                          </li>
                          <li>Con nacionalidad mexicana o residente de México.</li>
                          <li>Mayor de edad.</li>
                          <li>Aceso a computadora, tablet o dispositivo electrónico y a Internet.</li>
                          <li>Disponibilidad de tiempo y capacidad de autogestión.</li>
                          <li>Compromiso de concluir al menos uno de los 6 Certificados antes del 30 de abril de 2025. 
                          (Considera que cada Certificado requiere alrededor de 5 a 10 horas semanales (para completarlo al 100%  entre 3 y 6 meses.)).</li>
                        </ul>
                      </p>
                    </TreeItem>

                    <TreeItem nodeId='411' style={{ color:'black'}} label="¿Debo ser estudiante universitario para aplicar a esta beca?">
                      <TreeItem nodeId='412' style={{ color:'black' }} />
                      <p>
                        No es necesario.
                      </p>
                    </TreeItem>
                  

                    <TreeItem nodeId='421' style={{ color:'black'}} label="¿Existe un límite de edad para aplicar a las becas?">
                      <TreeItem nodeId='422' style={{ color:'black' }} />
                      <p>
                        <ul>
                          <li>Está abierto a cualquier persona, mayor de edad.</li>
                        </ul>
                      </p>
                    </TreeItem>

                    <TreeItem nodeId='431' style={{ color:'black'}} label="¿Cómo solicito una beca para preparar uno de los Certificados de Carrera de Google?">
                      <TreeItem nodeId='432' style={{ color:'black' }} />
                      <p>
                      Te invitamos a realizar una solicitud individual en el portal de INROADS Becas CCG (<a href='https://becas.inroads.org.mx' target="_blank" rel="noopener noreferrer">https://becas.inroads.org.mx</a>).
                      <br></br>
                      <ol>
                        <li>Regístrate con el correo que más utilices y crea una contraseña.</li>
                        <li>Valida tu cuenta, (recibirás un correo de sistemas@inroads.org.mx).</li>
                        <li>Completa el formulario y selecciona el Certificado de tu interés.</li>
                        <li>Lee y acepta los términos y condiciones de la beca.</li>
                        <li>Valida que tus datos sean correctos ya que estos no podrán ser modificados posteriormente.</li>
                        <li>Guarda y envía tu formulario. </li>
                      </ol>

                      <ul>
                          <li>El correo electrónico registrado será el medio por el cual estaremos en contacto contigo.</li>
                          <li>Solo podrás mandar tu solicitud una vez que esté completa. </li>
                      </ul>
                      <br></br>
                      </p>
                    </TreeItem>
                    
                    <TreeItem nodeId='451' style={{ color:'black'}} label="¿Puedo ceder mi beca a otra persona, cuenta, o compartir el acceso al Certificado/Curso con alguien más?">
                      <TreeItem nodeId='452' style={{ color:'black' }} />
                      <p>
                        La beca y el acceso al certificado/curso son exclusivos e intransferibles, vinculados al correo beneficiado
                      </p>
                    </TreeItem>


                    <TreeItem nodeId='461' style={{ color:'black'}} label="¿Cómo y cuándo sabré si se me ha concedido la beca?">
                      <TreeItem nodeId='462' style={{ color:'black' }} />
                      <p>
                      <ul>
                        <li>Tu petición de beca será confirmada en un plazo de una a dos semanas después de haber mandado tu solicitud.</li>
                        <li>Te invitamos a entrar regularmente al portal de INROADS (<a href='https://becas.inroads.org.mx' target="_blank" rel="noopener noreferrer">https://becas.inroads.org.mx</a>) e iniciar sesión para conocer el resultado.</li>
                        <li>Te estaremos avisando por correo. Verifica tus bandejas.</li>
                      </ul>
                      
                      Si se me concedió la beca, ¿Qué sigue? recibirás 2 correos: 
                      <ul>
                        <li>Uno de INROADS (sistemas@inroads.org.mx), de bienvenida, donde se te proporcionan indicaciones, así como una infografía del proceso para activar tu beca en Coursera.</li>
                        <li>Un correo con una invitación de parte de INROADS + Coursera, para activar tu Certificado (te pedimos que revises todas tus bandejas, incluida SPAM y promociones).</li>
                      </ul>
                      Tendrás 2 semanas para activar tu beca, inscribiéndote a tu Certificado en Coursera.
                      </p>
                    </TreeItem>


                    <TreeItem nodeId='471' style={{ color:'black'}} label="¿El Certificado/Curso es completamente gratuito? ">
                      <TreeItem nodeId='472' style={{ color:'black' }} />
                      <p>
                      <ul>
                        <li>Los alumnos a los cuales se otorga una beca no tienen que pagar nada para disfrutar del Certificado/Curso que seleccionaron. Es financiado por Google en su totalidad. Tu compromiso es organizar tu tiempo para estudiar regularmente y concluir por lo menos uno de los seis Certificados al 100% (todos los cursos) antes del 30 de abril de 2025.</li>
                        <li>Si al acceder a tu Certificado se te solicita un pago o ingresar datos bancarios, verifica que la cuenta de Coursera en la que tienes iniciada sesión sea la del correo beneficiado o no haya sido dado de baja por inactividad.</li>
                      </ul>
                      </p>
                    </TreeItem>


                    <TreeItem nodeId='481' style={{ color:'black'}} label="¿Qué otro beneficio me otorga la beca?">
                      <TreeItem nodeId='482' style={{ color:'black' }} />
                      <p>

                      <ul>
                        <li>Una vez que hayas concluido el Certificado de Carrera de Google que seleccionaste inicialmente, podrás empezar otro Certificado, recordando que tienes hasta el 30 de abril de 2025 para acabarlo.</li>
                        <li>Si tu perfil es afín al que INROADS, como organización sin fines de lucro, busca, podrás participar en su proceso de selección para formar parte de la comunidad de Inroaders y acceder a los beneficios que ésta ofrece. Si quieres saber más, visita nuestra página (https://inroads.org.mx/).</li>
                      </ul>

                      </p>
                    </TreeItem>
                    <TreeItem nodeId='491' style={{ color:'black'}} label="¿Puedo aplicar si soy de otro país distinto a México?">
                      <TreeItem nodeId='492' style={{ color:'black' }} />
                      <p>
                        Este programa es exclusivo para ciudadanos mexicanos o residentes de México.
                      </p>
                    </TreeItem>

                  </TreeItem>

                  <TreeItem nodeId="50" style={{color:'#4285F4'}} label="Registro y Solicitud de Beca en la plataforma INROADS">
                    <TreeItem nodeId='501' style={{ color:'black'}} label="¿Por qué necesito registrarme en INROADS para solicitar una beca?">
                      <TreeItem nodeId='502' style={{ color:'black' }} />
                      <p>
                          INROADS formó alianza con Google desde 2019, y para este proyecto (Certificados de Carrera de Google) colaboraremos con ellos en la gestión de becas, lo que incluye otorgar y dar seguimiento a las mismas.
                      </p>
                    </TreeItem>

                    <TreeItem nodeId='5010' style={{ color:'black'}} label="¿Qué es INROADS?">
                      <TreeItem nodeId='5020' style={{ color:'black' }} />
                      <p>
                      INROADS es una organización sin fines de lucro. Promovemos la movilidad social impulsando el talento de los jóvenes brindándoles las habilidades y competencias necesarias para acceder a más y mejores empleos.
                      </p>
                    </TreeItem>

                    <TreeItem nodeId='5011' style={{ color:'black'}} label="¿Qué hago si no me ha llegado el correo de validación de usuario?">
                      <TreeItem nodeId='5021' style={{ color:'black' }} />
                      <p>
                        Busca en todas las bandejas de tu correo (incluido SPAM y correo no deseado) un mensaje de sistemas@inroads.org.mx.
                      </p>
                    </TreeItem>

                    <TreeItem nodeId='5012' style={{ color:'black'}} label="¿Qué sigue después de registrarte en el portal de INROADS?">
                      <TreeItem nodeId='5022' style={{ color:'black' }} />
                      <p>
                      Completa el formulario y selecciona el Certificado de tu interés. 
                      </p>
                    </TreeItem>

                    <TreeItem nodeId='5013' style={{ color:'black'}} label="Ya completé y envié mi formulario. ¿Puedo editar mi solicitud una vez enviada? ">
                      <TreeItem nodeId='5023' style={{ color:'black' }} />
                      <p>
                        No se puede editar la información una vez que el formulario de solicitud de beca sea guardado y enviado. 
                      </p>
                    </TreeItem>

                    <TreeItem nodeId='5014' style={{ color:'black'}} label="¿Cuándo sabré si me otorgaron la beca?">
                      <TreeItem nodeId='5024' style={{ color:'black' }} />
                      <p>
                      En el lapso de una a dos semanas después de haber hecho tu solicitud te llegará respuesta de INROADS por correo (al que registraste inicialmente). 
                      </p>
                    </TreeItem>
                    <TreeItem nodeId='5014' style={{ color:'black'}} label="¿Qué hago si no me ha llegado el correo de confirmación de la beca? ">
                      <TreeItem nodeId='5024' style={{ color:'black' }} />
                      <p>
                      Revisa el estatus de tu solicitud en el portal de INROADS <a href='https://becas.inroads.org.mx' target="_blank" rel="noopener noreferrer">https://becas.inroads.org.mx</a> Ingresa con tu usuario y contraseña, en un lapso de una a dos semanas después de haber hecho tu solicitud.
                      </p>
                    </TreeItem>

                    <TreeItem nodeId='5015' style={{ color:'black'}} label="¿Qué hago tras enterarme que se me ha concedido una beca? ">
                      <TreeItem nodeId='5025' style={{ color:'black' }} />
                      <p>
                        <ul>
                        <li> 
                        Recibirás por correo electrónico una invitación de parte de INROADS + Coursera para activar tu beca en la plataforma de Coursera. Si ya tienes una cuenta Coursera (con el correo beneficiado), puedes utilizarla, si no, debes darte de alta en Coursera con tu correo beneficiado. Es importante que actives tu beca dentro de las 2 siguientes semanas. De lo contrario, la beca será asignada a otra persona. 
                        </li>

                        <li> 
                        Únete a tu primer curso y comienza a estudiar <a href='https://drive.google.com/file/d/1ipCpzl1KvMfeqCkQIC-c7MPLC0J8UWGN/view?usp=sharing' target="_blank" rel="noopener noreferrer">https://drive.google.com/file/d/1ipCpzl1KvMfeqCkQIC-c7MPLC0J8UWGN/view?usp=sharing</a> 
                        </li>

                        </ul>
                      </p>
                    </TreeItem>

                    <TreeItem nodeId='5016' style={{ color:'black'}} label="No recuerdo el email con el cual me registre inicialmente ">
                      <TreeItem nodeId='5026' style={{ color:'black' }} />
                      <p>
                      Anota y guarda el correo electrónico registrado en tu solicitud de beca ya que será el medio en el que nos pondremos en contacto contigo durante todo el proceso y no habrá ningún cambio. ¡Guárdalo! 
                      </p>
                    </TreeItem>

                    <TreeItem nodeId='5017' style={{ color:'black'}} label="¿Por qué fui rechazado para obtener la beca? ">
                      <TreeItem nodeId='5027' style={{ color:'black' }} />
                      <p>
                        Desde INROADS, trabajamos para ofrecer el máximo número de becas. Desafortunadamente tenemos un límite y no podemos premiar a todos, pero nuestro objetivo es ofrecer cada vez más oportunidades para aquellas personas que buscan mejorar su perfil profesional. Te invitamos a seguirnos en nuestras redes sociales para conocer los demás beneficios que INROADS ofrece. 
                      </p>
                    </TreeItem>

                    <TreeItem nodeId='5018' style={{ color:'black'}} label="Ya no me interesa o no podré disfrutar de la beca a la que apliqué, ¿tengo que hacer algo?">
                      <TreeItem nodeId='5028' style={{ color:'black' }} />
                      <p>
                        No te preocupes, por favor notificarnos al correo becas.certificado.google@inroads.org.mx para que podamos brindar esta beca a otra persona que se encuentre en lista de espera. ¡Gracias!
                      </p>
                    </TreeItem>


                  </TreeItem>

                  <TreeItem nodeId="60" style={{color:'#4285F4'}} label="Acceso a los Fundamentos y Certificados de Carrera de Google">
                    
                    
                    <TreeItem nodeId='601' style={{ color:'black'}} label="¿Cuáles son los requisitos técnicos para acceder al curso en plataforma?">
                      <TreeItem nodeId='602' style={{ color:'black' }} />
                      <p>
                        <ul>
                        <li>
                          Aunque puedas acceder al curso en una tableta o teléfono móvil, la recomendación es acceder a través de una computadora/ordenador. Necesitarás una conexión a internet para acceder al contenido.  
                        </li>
                        <li>
                          Podrás descargar el contenido de los cursos para estudiar offline, pero necesitarás estar online para llevar a cabo las evaluaciones. 
                        </li>
                        </ul>
                      </p>
                    </TreeItem>
                  
                  <TreeItem nodeId='611' style={{ color:'black'}} label="¿Cómo acceder a la plataforma de cursos?">
                      <TreeItem nodeId='612' style={{ color:'black' }} />
                      <p>
                      <ul>
                        <li>Una vez activada tu beca dentro de la plataforma de Coursera (gracias a la invitación única y personal que recibiste de parte de INROADS + Coursera), podrás acceder a tu Certificado desde la plataforma de INROADS (<a href='https://becas.inroads.org.mx' target="_blank" rel="noopener noreferrer">https://becas.inroads.org.mx</a>).</li>
                        <li>Si tienes alguna duda revisa la siguiente infografía donde aparecen los pasos a seguir <a href='https://drive.google.com/file/d/1ipCpzl1KvMfeqCkQIC-c7MPLC0J8UWGN/view?usp=sharing' target="_blank" rel="noopener noreferrer">Proceso Inscripción a Coursera.pdf</a> </li>
                        <li>Es importante que actives tu Certificado dentro de las dos primeras semanas de haber recibido la aceptación de tu beca, de lo contrario ésta podría ser asignada a alguien más que esté en lista de espera.</li>
                      </ul>
                      </p>
                    </TreeItem>
                  
                  <TreeItem nodeId='621' style={{ color:'black'}} label="¿Con quién me puedo comunicar si tengo problemas de acceso a la plataforma de cursos o cualquier pregunta relacionada?">
                      <TreeItem nodeId='622' style={{ color:'black' }} />
                      <p>
                      <ul>
                        <li>Una vez dado de alta en la plataforma Coursera, cualquier problema relacionado con la realización de tu Certificado dentro de la misma, (contenido, ejercicios prácticos, actividades, calificaciones, etc)  favor de notificarlo a través del Centro de ayuda de Coursera <a href='https://www.coursera.support' target="_blank" rel="noopener noreferrer">https://www.coursera.support</a></li>
                        <li>Para cualquier otro problema no relacionado directamente con el contenido del Certificado (ejemplo: vigencia de la beca, u otro) ponte en contacto con becas.certificado.google@inroads.org.mx. El tiempo de respuesta es de 3 días hábiles.</li>
                      </ul>
                      </p>
                    </TreeItem>
                  </TreeItem>
                  
                  <TreeItem nodeId="70" style={{color:'#4285F4'}} label="Coursera">
                
                    <TreeItem nodeId='701' style={{ color:'black'}} label="Cuando intento ingresar a mi Certificado en Coursera, me aparece que debo inscribirme, pero ya lo hice antes. ¿Qué debo hacer?">
                      <TreeItem nodeId='702' style={{ color:'black' }} />
                      <p>
                      <ul>
                        <li>Probablemente estás ingresando desde una cuenta de correo distinta al correo beneficiado.</li>
                        <li>Te sugerimos cerrar sesión e ingresar nuevamente con el mismo correo con el que te diste de alta en INROADS.</li>
                      </ul>
                      </p>
                    </TreeItem>
                    
                    <TreeItem nodeId='703' style={{ color:'black'}} label="Los vídeos aparecen en inglés. ¿Cómo hago para que me aparezcan en español?">
                    <p>
                      El audio de los cursos está en inglés, sin embargo, puedes activar los subtítulos en español, en la parte inferior derecha de la pantalla del vídeo debes seleccionar el apartado de subtítulos y dar clic en “español” (Spanish). De esta manera podrás visualizar el texto en español dentro del vídeo.
                    </p>
                    </TreeItem>
                
                    <TreeItem nodeId='704' style={{ color:'black'}} label="¿Puedo cambiar mi Certificado de Carrera una vez que me lo hayan otorgado? ">
                      <p>
                      Si por error seleccionaste un Certificado que no querías, y deseas cambiarlo, debes enviar un mensaje explicando tus motivos a nuestro correo de soporte: becas.certificado.google@inroads.org.mx, mencionando el nombre del Certificado que elegiste por error y el nombre de aquél que deseas cursar, así mismo tu nombre y correo electrónico (con el cual solicitaste la beca). El cambio se te notificará a ese mismo correo.
                      </p>
                    </TreeItem>
                    
                    <TreeItem nodeId='705' style={{ color:'black'}} label="No puedo ingresar a Coursera vía la plataforma de INROADS ¿Qué hago? ">
                      <p>
                        Te sugerimos cerrar sesión e ingresar nuevamente con el mismo correo con el que solicitaste la beca la beca; asegúrate de que estés ingresando con ese mismo correo electrónico. Si esto no te funciona, puedes solicitar la recuperación de tu contraseña, dando clic en “Olvidé mi contraseña” y seguir los pasos indicados.
                        También puedes ingresar directamente a la plataforma de Coursera con tu correo becado, y reportar con el equipo de Soporte Becas CCG tal situación.
                      </p>
                    </TreeItem>

                    <TreeItem nodeId='706' style={{ color:'black'}} label="¿El Certificado tiene algún costo? ">
                      <p>
                      Una vez que hayas obtenido la beca, el Certificado de Carrera no tiene costo. Sólo verifica que siempre ingreses a tu cuenta de Coursera con el mismo correo con el que solicitaste la beca, o mejor aún, siempre ingresa desde la plataforma de INROADS <a href='https://becas.inroads.org.mx' target="_blank" rel="noopener noreferrer">https://becas.inroads.org.mx</a>. Ahí encontrarás un botón con el nombre del curso y mediante éste puedes acceder a Coursera.
                      </p>
                    </TreeItem>

                    <TreeItem nodeId='707' style={{ color:'black'}} label="¿Por qué, al querer realizar mi Certificado me aparece que tiene un costo, si es que ya me dieron la beca?">
                      <p>
                        <ul>
                          <li>Cuando ingreses a Coursera, debes de seleccionar la opción que tiene el ícono de INROADS, para asegurarte que estás realizando el Certificado a través de la beca que solicitaste.</li>
                          <li>También puedes cerrar sesión en Coursera e iniciarla nuevamente, asegurándote de colocar el correo beneficiado y tu contraseña (en ocasiones esto soluciona la situación). </li>
                        </ul>
                      </p>
                    </TreeItem>

                    <TreeItem nodeId='708' style={{ color:'black'}} label="¿Debo concluir cada curso en las fechas que se indican en la plataforma de Coursera?">
                      <p>
                      Las fechas marcadas para finalizar las actividades de cada curso son propuestas por Coursera para que los estudiantes puedan terminar su Certificado en el tiempo recomendado. Sin embargo, puedes avanzar a tu propio ritmo, y extender las fechas en caso de que lo consideres necesario. Siempre y cuando termines tu Certificado a más tardar el 30 de abril del 2025. Te recomendamos que administres tu tiempo para que lo logres de manera exitosa.
                      </p>
                    </TreeItem>
                    

                    <TreeItem nodeId='709' style={{ color:'black'}} label="¿Cuál es la diferencia entre Curso y Certificado?">
                      <p>
                      Toma en cuenta que tu <b>Certificado final se compone de varios cursos (de 5 a 8 aproximadamente)</b> que podrás desbloquear de manera consecutiva, en la medida que vayas completando cada curso con sus respectivas actividades. 
                      Cada curso dura 4 semanas aproximadamente, y contiene vídeos, lecturas, ejercicios de práctica, cuestionarios, así como foros de debate que deberás realizar. Recibirás un <b>“Certificado parcial”</b> para cada curso terminado, y un <b>“Certificado final”</b> cuando acabes el programa entero. 
                      Como recomendación, evita dejar alguna actividad pendiente; completa cada una antes de pasar a la siguiente. Cuando concluyas un curso podrás inscribirte al siguiente, de manera consecutiva hasta completar la cantidad total de los cursos que conforman tu Certificado final.
                      </p>
                    </TreeItem>
                    

                    <TreeItem nodeId='710' style={{ color:'black'}} label="¿Qué puedo hacer si alguna de las actividades me aparece como bloqueadas? ">
                      <p>
                        Asegúrate que hayas completado la actividad y que te aparezca una palomita en color verde. Si continúas con el problema, puedes crear un ticket de soporte en Coursera, explicando tu situación en el siguiente enlace: <a href='https://www.coursera.support' target="_blank" rel="noopener noreferrer">https://www.coursera.support</a>
                      </p>
                    </TreeItem>
                    
                    <TreeItem nodeId='711' style={{ color:'black'}} label="¿A qué se refiere la leyenda que aparece “Un curso en línea sin crédito autorizado por Google y ofrecido a través de Coursera”? ">
                      <p>
                        Un Certificado de curso es una credencial oficial de Coursera que confirma que has completado correctamente un curso en Coursera, pero no proporcionan créditos profesionales o académicos que sirvan para sustituir materias de alguna Universidad. Les compartimos el link, donde viene más información al respecto: <a href='https://www.coursera.support'>https://www.coursera.support</a>
                      </p>
                    </TreeItem>

                    <TreeItem nodeId='712' style={{ color:'black'}} label="Terminé mi primer Certificado de Carrera. ¿Qué sigue?">
                      <p>
                        <ul>
                        <li>¡Felicidades! Comparte esta excelente noticia en tus redes sociales (particularmente en LinkedIn) e invita a tus amigos a solicitar una beca también. No olvides etiquetarnos cuando compartas tu publicación.</li>
                        <li>Recuerda que tu beca te permite cursar los 6 Certificados: en un lapso de 5 días hábiles después de haber concluido tu primer Certificado al 100% (todos los cursos que lo componen) podrás solicitar otro desde el portal de INROADS.</li>
                        <li>Espera una nueva invitación por parte de <b>INROADS + Coursera</b> (vía correo electrónico) para iniciar tu siguiente Certificado. </li>
                        </ul>
                      </p>
                    </TreeItem>

                    <TreeItem nodeId='717' style={{ color:'black'}} label="¿Puedo cambiar cómo aparece mi nombre en los Certificados que me proporciona Coursera?">
                      <p>
                        Sí. Puedes cambiar tu nombre de la siguiente manera:
                        
                      <ol>
                        <li>Abre tu sesión en Coursera.</li>
                        <li>Da clic en el ícono de <b>perfil</b>, y se te desplegará una lista con opciones.</li>
                        <li>Elige: <b>“Ajustes”</b>. </li>
                        <li>Busca la opción <b>Verificación de nombre</b> y da clic en el botón <b>“Verificar mi nombre”</b>.</li>
                        <li><b>Coloca el nombre</b> que quieras que aparezca en tu Certificado, así como tu apellido, y <b>seleccionas la casilla</b> que se te solicita.
                        </li>
                        <li>Da clic en <b>“Submit”</b> (sólo se habilita este botón cuando colocas tus datos y seleccionas la casilla).</li>
                      </ol>
                      <br/>
                      <b>Nota:</b> No debes cambiar nada de tu información en el apartado de “Cuenta” (nombre, correo electrónico) ya que estos datos están ligados a tu registro de la beca. La modificación de tu nombre sólo debes hacerla conforme a los pasos indicados anteriormente.
                      </p>
                    </TreeItem>
                  </TreeItem>

                </TreeView>
              </Typography>
            </AccordionDetails>
          </Accordion>
          { !soloPreguntasFrecuentes&&
          <Accordion className='acordion-container'>
            <AccordionSummary
              className='acordion-item-ask'
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
                <Typography className={classes.heading}>
                   Soporte y Ayuda
                </Typography>
            </AccordionSummary>
            <AccordionDetails className='acordion-item-detail'>
              <Typography>
                <TextField
                  helperText={mensajeDatos.nombreCompleto}
                  error={mensajeDatos.nombreCompleto}
                  required
                  autoFocus
                  margin="dense"
                  id="usuario"
                  label="Nombre completo"
                  type="text"
                  value={datos.nombreCompleto}
                  fullWidth variant="standard"
                  onChange={(event)=>{handleChange(event.target.value,"nombreCompleto")}} 
                  />

                <TextField
                  helperText={mensajeDatos.correo}
                  error={mensajeDatos.correo}
                  required
                  margin="dense"
                  id="email"
                  label="Email"
                  type="email"
                  value={datos.correo}
                  fullWidth variant="standard" 
                  onChange={(event)=>{handleChange(event.target.value,"correo")}}
                  />

                <TextField
                  helperText={mensajeDatos.mensaje}
                  error={mensajeDatos.mensaje}
                  multiline
                  required 
                  margin="dense"
                  id="mensaje"
                  label="Mensaje"
                  fullWidth
                  variant="standard"
                  value={datos.mensaje}
                  rows={4}
                  onChange={(event)=>{handleChange(event.target.value,"mensaje")}}
                  />
                <FormGroup className='form-cheks'>
                  <FormControlLabel control={<Checkbox />} 
                    className={"check-acuerdo"}
                    checked={mensajePrivacidad} 
                    onChange={()=>{setMensajePrivacidad(!mensajePrivacidad)}} 
                    label={<span>Marcando esta casilla, confirmo que he leído, entendido y aceptado el <a href='https://inroads.org.mx/aviso-de-privacidad/' target="_blank" rel="noopener noreferrer">aviso de privacidad</a></span>}/>
                </FormGroup>
                { envioExitoso === 1 && <Alert className='alert-acordion' severity="info">Se envio tu correo exitosamente</Alert>}
                { envioExitoso > 1 && <Alert className='alert-acordion' severity="error">Favor de intentar más tarde</Alert>}
                {mensajePrivacidad &&
                <Button 
                  variant="contained" 
                  onClick={enviaFormulario} 
                  className="button_ovalado"
                  style={{width:'20%', textTransform: 'None',margin: "15px 0px"}}>
                  enviar
                </Button>
                }
              </Typography>
            </AccordionDetails>
          </Accordion>
          }
        </div>
      );
}