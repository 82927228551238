import React from 'react';

export default function ArrowCert({fill}) {
    return (
        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 53.09 53.22" space="preserve">
        <circle fill={fill} cx="26.55" cy="26.61" r="26"/>
        <path fill='#F9F8F7' d="M22.92,36.36c-0.5,0-1.01-0.19-1.38-0.57c-0.77-0.77-0.77-2.01,0-2.78l6.48-6.48l-6.3-6.31
            c-0.77-0.77-0.77-2.01,0-2.78s2.01-0.77,2.78,0l6.62,6.62c0.65,0.66,1.01,1.53,1.01,2.46s-0.36,1.79-1.02,2.46l-6.8,6.8
            C23.93,36.16,23.43,36.36,22.92,36.36z M28.34,26.83L28.34,26.83L28.34,26.83z"/>
        </svg>
    )
}