import React, {useState,useEffect} from 'react';
import axios from 'axios';
import AppBar from './AppBarHome';
import StickyFooter from "./StickyFooter";
import { Modal } from 'react-responsive-modal';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import "./css/adminStyle.css";
import Checkbox from '@mui/material/Checkbox';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { DataGrid } from "@mui/x-data-grid";

const DocumentoDescarga = ({link}) => (
    <a href={link}>
        <ArticleOutlinedIcon  sx={{ color: 'black' }} fontSize="large"/>
    </a>
);

export default function AlumnosExportarCoursera() {

    const [periodo, setPeriodo] = useState(0);
    const [listPeriodo, setListPeriodo] = useState([]);

    const [historial, setHistorial] = useState([]);
    const [modal, setModal] = useState(false);
    const [idDocumento, setIdDocumento] = useState(null);
    const [check, setCheck] = useState(false);
    const [activaBoton, setActivaBoton] = useState(false);
    const [refresca, setRefresca] = useState(0);

    useEffect(() => {

        axios.get('https://prod-backend-dot-driven-presence-373215.uc.r.appspot.com/api/v1/admin/periodo_becas_read/',
        { headers: {"Authorization" : `Bearer ${sessionStorage.getItem('token')}`}})
        .then((response) => {
            setListPeriodo(response.data.periodos_becas);
        })
        .catch((error) => {
            console.error(error);
        });

    }, [refresca]);

    const handleAbreModal = (idDocumento) => {
        setCheck(true);
        setIdDocumento(idDocumento);
        setModal(true);
    };

    const handleLimpiaDatosModal = () => {
        setModal(null);
        setIdDocumento(null);
        setCheck(false);
    };
    

    const handleEnviarInformacion = () => {

        axios.post('https://prod-backend-dot-driven-presence-373215.uc.r.appspot.com/api/v1/admin/becas/historial_confirmacion/',{idDocumento},
        { headers: {"Authorization" : `Bearer ${sessionStorage.getItem('token')}`}}
        )
        .then((response) => {
            setIdDocumento(null);
            setCheck(false);
            setModal(false);
            setActivaBoton(false);
            setHistorial([]);
            setPeriodo(null);
            setListPeriodo([]);
            setRefresca((refresca) => refresca ? 0:1);
        })
        .catch((error) => {
            console.error(error);
        });

    };

    const obtenerHistorial = (idPeriodo) => {
        setPeriodo(idPeriodo);

        const formData = new FormData();
        formData.append('id_periodo_becas',idPeriodo);

        axios.post('https://prod-backend-dot-driven-presence-373215.uc.r.appspot.com/api/v1/admin/archivos_exportar/',formData,
        { headers: {"Authorization" : `Bearer ${sessionStorage.getItem('token')}`}})
        .then((response) => {
            const historialAux = response.data.historial && response.data.historial.map(docu => ({...docu,id:docu.idDocumento}));
            setHistorial(historialAux);
        })
        .catch((error) => {
            console.error(error);
        });
    };

    const data = {
        columns:[
            {
                field: "id",
                hide: true,
                editable: false,
            }, 
            {
                field: "linkDocumento",
                headerName: "",
                hide: false,
                editable: false,
                cellClassName:"cell-center",
                renderCell:(data)=>(<DocumentoDescarga link={data.value}/>),
                width: 250,
            },
            {
                field: "numPersonas",
                headerName: "Número de personas",
                hide: false,
                editable: false,
                cellClassName:"cell-center",
                width: 300,
            }, 
            {
                field: "fechaCreacion",
                headerName: "Fecha de creación",
                hide: false,
                editable: false,
                cellClassName:"cell-center",
                width: 300,
            }, 
            {
                field: "fechaCarga",
                headerName: "Fecha de carga",
                hide: false,
                editable: false,
                renderCell:(data)=>(data.value ? data.value : <div>
                    <Checkbox checked={check}
                     onChange={()=>handleAbreModal(data.id)}
                     />Confirmación
                </div>),
                cellClassName:"cell-center",
                width: 300,
            },
        ],
        rows:historial
    }

    const TablaExportar = () => (
        <div className='hader-purple' style={{ height: 500, width: "100%" }}>
            <DataGrid
                {...data}
            />
        </div>
    );
    
    const ModalConfirmacion = () => (
        <Modal open={modal} onClose={handleLimpiaDatosModal} >
            <DialogContent>
                <DialogContentText >
                    <div className='dialog'>
                        <h2>¡Alerta!</h2>
                        <div>
                            <p>Al verificar esta casilla, confirmas su carga a Coursera <br />
                            y dicha acción no es reversible.</p>
                        </div>
                        <div>
                            <div className='dialog-button form-button-background-morado'>
                                <Button 
                                    variant="contained" 
                                    size="large"
                                    onClick={handleEnviarInformacion}
                                >Aceptar</Button>
                            </div>
                        </div>
                    </div>
                </DialogContentText>
            </DialogContent>
        </Modal>
    );

    return (
        <>
            <AppBar/>
            <ModalConfirmacion />
            <div className='card-main'>
                <h1>Alumnos para exportar a <br />Coursera</h1>
                <hr />
                <div className="form-table">
                    <div className='form-table-period'>
                        <span className='nota_asterisco'>*</span><span className='nota'> Selecciona periodo:</span>
                        <br />
                        <Select
                            className='select_admin'
                            id="idPeriodo"
                            onChange={(event)=>{obtenerHistorial(event.target.value)}}
                            value={periodo}
                        >
                            {
                                listPeriodo && 
                                listPeriodo.map( periodo => (
                                    <MenuItem key={periodo.id_periodo_becas} value={periodo.id_periodo_becas}>{periodo.nombre_periodo}</MenuItem>
                                ))
                            }
                        </Select>
                    </div>
                    <TablaExportar  colorHead={'#4e2378'} data={historial}/>
                </div>
            </div>
            <StickyFooter />
        </>
    );
}