import React from 'react';
import logoAux from '../curso_TI.jpg';
import ArrowCert from './ArrowCert';
import useMediaQuery from '../hooks/useMediaQuery';
import CheckIcon from '@material-ui/icons/Check';

export default function CardCert({card,seleccionaNuevoCertificado}) { 
    const backgroundColorTitle = card.colorCert ? card.colorCert:'#4285f4';
    const imgCert = card.imgCert ? typeof card.imgCert === 'object'? URL.createObjectURL(card.imgCert) : card.imgCert : null;
    const cardMovil = useMediaQuery('only screen and (min-width: 860px)');
    
    return (
        <>
        {
            cardMovil ?
            <div className='card_main_n' style={{borderColor:backgroundColorTitle}}>
                <div className='card_box_title-img'>
                    
                    <div className='card_title'>
                        { !card.tituloCert ?
                            <p>Titulo Certificado</p>  :
                            <p>{card.tituloCert}</p> 
                        }
                    </div>
                    <div className='card_img'>
                        {imgCert ? <img src={imgCert} alt="imgCert" width={'120px'} height={'120px'}/>
                        :<img src={logoAux} alt="imgCert" width={'120px'} height={'120px'}/>}
                    </div>
                </div>
                <div className='card-box_text'>
                    <div>
                        {!card.textoCert ?
                            <p>
                                Texto certificado: Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt corrupti error sint reiciendis eligendi nihil atque autem perferendis perspiciatis soluta vero incidunt molestias ea dolorum asperiores quam ab, labore natus.
                                lore
                            </p> :
                            <p>
                                {card.textoCert}
                            </p>
                        }
                    </div>
                    { card.estatusCertificado === 1 && 
                        <>
                        <div className='card_text_finalizado'>
                            <p>Curso Finalizado <span className='card_check'>
                                <CheckIcon fontSize="large"/>
                            </span></p>
                        </div>
                        <div>
                            
                        </div>
                        </>
                    }
                    { card.estatusCertificado === 2 && 
                            <div className='boton-cursera'>
                                <div className='boton-cursera-text'>
                                    <span style={{color:backgroundColorTitle}}>Seleccionar Certificado</span> 
                                </div>
                                <div className='boton-cursera-boton'>
                                    <a onClick={()=>{seleccionaNuevoCertificado(card.id_certificado);}}><ArrowCert fill={backgroundColorTitle}/></a>
                                </div>
                            </div>
                    }
                    { card.estatusCertificado === 3 && 
                        <div className='boton-cursera'>
                            <div className='boton-cursera-text'>
                                <span style={{color:backgroundColorTitle}}>Seleccionado</span> 
                            </div>
                        </div>
                    }
                    { card.estatusCertificado === 4 &&  
                        <div className='boton-cursera'>
                            <div className='boton-cursera-text'>
                                <span style={{color:backgroundColorTitle}}>Acceso a Coursera</span> 
                            </div>
                            <div className='boton-cursera-boton'>
                                <a href="https://www.coursera.org" target="_blank" rel="noopener noreferrer"><ArrowCert fill={backgroundColorTitle}/></a>
                            </div>
                        </div>
                    }
                    { card.url_ver_mas &&
                        <div className='card_ver-mas'>
                            <div className='card_ver-mas_text'>
                                <span>
                                    Aprovecha esta gran oportunidad. <br />
                                    ¡Solicita tu beca hoy mismo!
                                </span>
                            </div>
                            <div className='card_ver-mas_button'>
                                <a href={card.url_ver_mas} target="_blank" rel="noopener noreferrer" style={{backgroundColor:backgroundColorTitle}}>Ver más</a>
                            </div>
                        </div>
                    }
                </div>
            </div> :
            <div className='card-main-n_movil' style={{borderColor:backgroundColorTitle}}>
                <div className='card-main-n_movil_head'>
                    <div className='card-main-n_movil_title'>
                        { !card.tituloCert ?
                            <p>Titulo Certificado</p>  :
                            <p>{card.tituloCert}</p> 
                        }
                    </div>
                    <div className='card-main-n_movil_img'>
                        {imgCert ? <img src={imgCert} alt="imgCert" width={'120px'} height={'120px'}/>
                        :<img src={logoAux} alt="imgCert" width={'120px'} height={'120px'}/>}
                    </div>
                </div>
                <div  className='card-main-n_movil_text'>
                    {!card.textoCert ?
                        <p>
                            Texto certificado: Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt corrupti error sint reiciendis eligendi nihil atque autem perferendis perspiciatis soluta vero incidunt molestias ea dolorum asperiores quam ab, labore natus.
                            lore
                        </p> :
                        <p>
                            {card.textoCert}
                        </p>
                    }
                    { card.estatusCertificado === 1 && 
                        <div className='card_text_finalizado'>
                            <p>Curso Finalizado</p>
                        </div>
                    }
                    { card.estatusCertificado === 2 && 
                        <div className='boton-cursera'>
                            <div className='boton-cursera-text'>
                                <span style={{color:backgroundColorTitle}}>Seleccionar Certificado</span> 
                            </div>
                            <div className='boton-cursera-boton'>
                                <a onClick={()=>{seleccionaNuevoCertificado(card.id_certificado);}} style={{color:backgroundColorTitle}}><ArrowCert fill={backgroundColorTitle}/></a>
                            </div>
                        </div>
                    }
                    { card.estatusCertificado === 3 && 
                        <div className='boton-cursera'>
                            <div className='boton-cursera-text'>
                                <span style={{color:backgroundColorTitle}}>Seleccionado</span> 
                            </div>
                        </div>
                    }
                    { card.estatusCertificado === 4 &&  
                        <div className='boton-cursera'>
                            <div className='boton-cursera-text'>
                                <span style={{color:backgroundColorTitle}}>Acceso a Coursera</span> 
                            </div>
                            <div className='boton-cursera-boton'>
                                <a href="https://www.coursera.org" target="_blank" rel="noopener noreferrer"><ArrowCert fill={backgroundColorTitle}/></a>
                            </div>
                        </div>
                    }
                    { card.url_ver_mas &&
                        <div className='card_ver-mas'>
                            <div className='card_ver-mas_text'>
                                <span>
                                    Aprovecha esta gran oportunidad. <br />
                                    ¡Solicita tu beca hoy mismo!
                                </span>
                            </div>
                            <div className='card_ver-mas_button'>
                                <a href={card.url_ver_mas} target="_blank" rel="noopener noreferrer" style={{backgroundColor:backgroundColorTitle}}>Ver más</a>
                            </div>
                        </div>
                    }
                </div>
            </div>
        }
        </>
    )
}