import React, { useState, useEffect } from 'react';
import SessionContex from './provider/SessionContex';

import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route
} from "react-router-dom";

import Home from './Home'
import Stuff from './Stuff'
import Admin from './Admin'
import AsignacionBecas from './AsignacionBecas';
import OrganizacionesClavesPR from './OrganizacionesClavesPR';
import HistorialAprobacion from './SeccionHistoryAssign'
import AlumnosExportarCoursera from './AlumnosExportarCoursera'
import AdminAlumCert from './AdminAlumCert';
import CambioContrasenia from './CambioContrasenia';
import AdminAlumDismiss from './AdminAlumDismiss';
import RegPeriodCerts from './RegPeriodCerts';
import AssignBeca from './AssignBeca';

export default function Main() {
  const [admin, setAdmin] = useState(false);

  useEffect(() => {
    setAdmin(sessionStorage.getItem('Admin'))
  },[]);

  const updateValueAdmin = (value) => {
    setAdmin(value);
  }

  function PrivateRoute({ children, adminOnly, ...rest }) {
  
    const isSigned = sessionStorage.getItem('isSigned')
    
    let adminSession = sessionStorage.getItem('Admin');

    console.log("path: " + rest.path);
    console.log("adminOnly: " + adminOnly);
    console.log("isSigned: " + isSigned);
    console.log("admin: " + adminSession);

    console.log("evaluation =>" + ((isSigned && adminOnly && adminSession) || (isSigned && !adminOnly)));


    return (
      
      <Route
        {...rest}
        render={({ location }) =>
        ((isSigned && adminOnly && adminSession) || (isSigned && !adminOnly)) ? 
        (children) : (
            <Redirect
              to={{
                pathname: "/",
                state: { from: location }
              }}
            />
          )
        }
      />
    );
  }

  return (
   <SessionContex.Provider value={{admin,updateValueAdmin}}>
     {
      
      <Router>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <PrivateRoute exact path="/adminDismiss" adminOnly>
            <AdminAlumDismiss />
          </PrivateRoute>
          <PrivateRoute path="/admin" adminOnly>
            <Admin />
          </PrivateRoute>
          <PrivateRoute exact path="/asignacion" adminOnly>
            <AssignBeca />
          </PrivateRoute>
          <PrivateRoute exact path="/org-claves-pr" adminOnly>
            <OrganizacionesClavesPR />
          </PrivateRoute>
          <PrivateRoute exact path="/AlumnosExportarCoursera" adminOnly>
            <AlumnosExportarCoursera />
          </PrivateRoute>
          <PrivateRoute exact path="/certificados" adminOnly>
            <AdminAlumCert />
          </PrivateRoute>
          <Route path="/cambioContra">
            <CambioContrasenia />
          </Route>
          <PrivateRoute exact path="/registroPeriodoCursos" adminOnly>
            <RegPeriodCerts />
          </PrivateRoute>
          <Route exact path="/historial">
            <HistorialAprobacion />
          </Route>
          <PrivateRoute path="/stuff">
            <Stuff />
          </PrivateRoute>
        </Switch>
      </Router>
      }
    </SessionContex.Provider>
  );
}