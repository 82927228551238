import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(22),
      fontWeight: theme.typography.fontWeightMedium,
    },
}));

export default function SeccionAskHome() {
  const classes = useStyles();

  return(
    <div id='req' className='container-cards'>
      <div className='container_carac-title'>
        Características y Requisitos de los Fundamentos y Certificados de Carrera de Google
      </div>
      <div className='container_caracteristicas-requerisitos'>
        <div className='container_caracteristicas-requerisitos_req'>
          <div className='container_caracteristicas-requerisitos_req-title'>
            <p>Requisitos para obtener una beca</p>
          </div>
          
          <div>
            ● Tener un alto nivel de compromiso, con ganas de crecer e impulsar tu carrera profesional. <br />
            ● Mantener constancia para concluir al menos uno de los 6 CCG, + Nuevo curso, antes del 30 de abril de 2025. <br />
            ● Disponibilidad de tiempo y capacidad de autogestión. <br />
            ● Acceso a computadora, tablet o dispositivo electrónico. <br />
            ● Acceso a Internet. <br />
            ● Tener nacionalidad mexicana o ser residente en México. <br />
          </div>
        </div>
        <div className='container_caracteristicas-requerisitos_caract'>
          <Accordion className='acordion-container acordion-container_home'>
            <AccordionSummary
              className='acordion-item-ask acordion-item-ask_home'
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
                <Typography className={classes.heading}>
                  ● Características
                </Typography>
            </AccordionSummary>
            <AccordionDetails className='acordion-item-detail acordion-item-detail_home'>
              <Typography>
                ● Tienen un enfoque flexible y práctico. <br />
                ● Son 100% online, así que puedes tomarlos donde quieras y en el horario que más te acomode. <br />
                ● No requieren que tengas formación o experiencia previa. <br />
                ● Fueron desarrollados por expertos de Google. <br />
                ● Quienes lo completen recibirán un Certificado de Google que avale su conocimiento. <br />
                ● Puedes completarlos entre 3 y 6 meses, si dedicas de 5 a 10 horas semanales (el tiempo puede variar en función de la dedicación y los conocimientos previos de quien lo cursa). Para el curso Fundamentos de IA, el tiempo estimado es de entre 10 y 15 horas.<br />
                ● Estarán disponibles en la plataforma de Coursera, hasta el 30 de abril de 2025 (para quienes obtengan una beca). <br />
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion className='acordion-container acordion-container_home'>
            <AccordionSummary
              className='acordion-item-ask acordion-item-ask_home'
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
                <Typography className={classes.heading}>
                  ● Beneficios adicionales que te ofrece
                    INROADS al obtener una beca.
                </Typography>
            </AccordionSummary>
            <AccordionDetails className='acordion-item-detail acordion-item-detail_home'>
              <Typography>
                  ● Podrás realizar los 6 Certificados y el curso, de manera gratuita (siempre y cuando concluyas uno tras otro). <br />
                  ● Recibirás un Certificado final de Google que avale tu conocimiento, al completar todos los cursos del mismo, y un reconocimiento en el caso del curso de Fundamentos de IA<br />
                  ● Acceso a nuestro equipo de Soporte y Ayuda. <br />
                  ● Masterclasses con expertos de Google y Coursera. <br />
                  ● Acceso a nuestra comunidad de Facebook de becarixs para recibir comunicados de INROADS, compartir ideas, experiencias, y resolver dudas. <br />
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion className='acordion-container acordion-container_home'>
            <AccordionSummary
                className='acordion-item-ask acordion-item-ask_home'
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                  <Typography className={classes.heading}>
                    ● Condiciones de uso de la beca
                  </Typography>
              </AccordionSummary>
              <AccordionDetails className='acordion-item-detail acordion-item-detail_home'>
                <Typography>
                  ● Deberás seleccionar uno de los 6 Certificados o curso para poder solicitar la beca.​ <br />
                  ● Si eres seleccionada(o), recibirás un correo electrónico de Coursera en un plazo de 1 a 2 semanas, mediante el cual podrás inscribirte al Certificado/Curso que elegiste (dentro de las dos primeras semanas, de lo contrario, la beca será asignada a otra persona).​ <br />
                  ● Para comenzarlo debes entrar a tu cuenta de Coursera (si no tienes una, date de alta).​ <br />
                  ● Importante: el correo que registres inicialmente será con el que debes continuar durante todo tu proceso y no habrá ningún cambio.  <br />
                  ● En caso de que quieras realizar más Certificados, deberás concluir primero aquél en el que estés inscrita(o); posteriormente podrás seleccionar otro directamente en nuestro portal. <br />
                  ● Recuerda que tu beca expira el 30 de abril de 2025. Recibirás un Certificado final al momento de concluir todos los módulos que lo componen. <br />
                  ● Te adelantamos que, para no perder tu beca en el camino, deberás tener avance continuo en el Certificado, si presentas inactividad en un plazo mayor a dos semanas se te dará de baja del Programa. <br />
                </Typography>
              </AccordionDetails>
            </Accordion>
        </div>
      </div>
    </div>
  )
};