import React from 'react';

export default function CardPersonExp({imagen,nombre,texto, positionImg}) {
    const imgCss = {
        backgroundImage: `url(${imagen})`,
        backgroundPosition: positionImg ? positionImg:"50% 50%",
    }

    return(
        <div className='container-card-person-exp'>
            <div className='container-card-person-exp_img' style={{...imgCss}}>
                
            </div>
            <div className='container-card-person-exp_nombre'>
                <p><b>{nombre}</b></p>
            </div>
            <div className='container-card-person-exp_text'>
                <p>
                    "{texto}"
                </p>
            </div>
        </div>
    );
};
