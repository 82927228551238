import React from 'react';

export default function SeccionDismiss() {
    return(
        <div className='container-dismiss'>
            <p className='container-dismiss-text_black'>
            Sentimos comunicarte que en esta ocasión no fue posible proporcionarte la beca que solicitaste, sin embargo, ¡No desistas!
            </p>
            <p>
            Puedes estar pendiente de otras convocatorias en nuestra página <a href="https://inroads.org.mx" target="_blank" rel="noopener noreferrer">https://inroads.org.mx</a> y seguirnos en nuestras redes
            sociales para enterarte de todos los beneficios que aún podemos ofrecerte.
            </p>
            <p>
            Gracias por tu tiempo y por pariticipar en nuestro proceso.</p>
        </div>
    )
};
