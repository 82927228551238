import React, {useState,useEffect,useRef} from 'react';
import AppBar from './AppBarHome';
import axios from 'axios';
import MUIDataTable from "mui-datatables";
import { debounceSearchRender }  from "mui-datatables";
import { CircularProgress,Typography } from '@mui/material';
import StickyFooter from "./StickyFooter";
import TextField from "@mui/material/TextField";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import ClearIcon from '@material-ui/icons/Clear';
import { ConstructionOutlined } from '@mui/icons-material';

export default function SearchPeople() {
  
  const [peopleData, setPeopleData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [optionsTable, SetOptionsTable] = useState({filter: true,
                                                    filterType: 'dropdown',
                                                    responsive: 'vertical',
                                                    selectableRows: 'none',
                                                    downloadOptions: {
                                                                      filename: 'INROADS.csv',
                                                                      filterOptions: {
                                                                        useDisplayedColumnsOnly: true,
                                                                        useDisplayedRowsOnly: true
                                                                      }},
                                                    onDownload: (buildHead, buildBody, columns, data) => {
                                                       let new_data = donwload_full(data);
                                                       return "\uFEFF" + buildHead(columns) + buildBody(new_data); 
                                                        
                                                    },
                                                    serverSide: true,
                                                    download: true,
                                                    customSearchRender: debounceSearchRender(1000),
                                                    print: false,
                                                    debounceInterval: 50000,
                                                    searchPlaceholder: "Nombre / Email",
                                                    rowsPerPage: 10,
                                                    rowsPerPageOptions: [10, 15, 20, 100],
                                                    onTableChange: (action, tableState) => {
                                                      switch (action) {
                                                        case 'changePage':
                                                          changePage(tableState.page, tableState.rowsPerPage);
                                                          break;
                                                        case 'sort':
                                                          sort(tableState.page, tableState.rowsPerPage, tableState.sortOrder);
                                                          break;
                                                        case 'changeRowsPerPage':
                                                          changeRowsPerPage(tableState.page, tableState.rowsPerPage);
                                                          break;
                                                        case 'search':
                                                          search(tableState.page, tableState.rowsPerPage, tableState.searchText);
                                                          break;
                                                        
                                                        case 'filterChange':
                                                          filterChange(tableState.page, tableState.rowsPerPage,tableState.filterList);
                                                          break;
                                                        case 'resetFilters':
                                                          filterChange(tableState.page, tableState.rowsPerPage,tableState.filterList);
                                                          break;
                                                        default:
                                                          console.log('action not handled. ->',action, '<-');
                                                      }
                                                    },
                                                  });

  const paramsPaginate = "?pagina=${page}&rows_per_page=${rows_per_page}";
  const request_backend = 'https://prod-backend-dot-driven-presence-373215.uc.r.appspot.com/api/v1/admin/search/' + paramsPaginate;
  const request_backendChangue = 'https://prod-backend-dot-driven-presence-373215.uc.r.appspot.com/api/v1/admin/change_certificate/';
  const request_backendReset = 'https://prod-backend-dot-driven-presence-373215.uc.r.appspot.com/api/v1/admin/reset/'
  const request_backendClavePrereg = 'https://prod-backend-dot-driven-presence-373215.uc.r.appspot.com/api/v1/admin/update_clave_prereg/'
  const request_backendChangueUserStatus = 'https://prod-backend-dot-driven-presence-373215.uc.r.appspot.com/api/v1/admin/rollback_baja/'

  const [columnsTable, setColumnsTable] = useState([]);
  const [listPeriodo, setListPeriodo] = useState([]);
  const [listJustif, setListJustif] = useState([]);
  const listJustifRef = useRef([]);
  const [periodo, setPeriodo] = useState({});
  const [idPeriodo, setIdPeriodo] = useState(0);
  const idPeriodoRef = useRef(0);
  const idPeriodoCerts = useRef({});

  // Para control de mensajes de exito y error
  const [mensaje, setMensaje] = useState({
    mostrarMensaje: false,
    textoMensaje:"Se actualizo de manera correcta",
    tipoMensaje:"success"
});

  var pageAux;
  var rowsPerPageAux;
  var jsonRules = {};
  var peopleDataAux;
  

  const updateBodyTable = (page=0,rowsPerPage=10,jsonOptionsRules=jsonRules) => {
    console.log("optionsTable.periodo");
    console.log(idPeriodoRef);
    console.log(idPeriodoRef.current);

    if(idPeriodoRef.current  && !jsonRules["id_periodo"]){
      jsonRules["id_periodo"] = idPeriodoRef.current;
    }else{
      if(idPeriodoRef.current  != jsonRules["id_periodo"]){
        jsonRules["id_periodo"] = idPeriodoRef.current;
      }
    }

    if(!idPeriodoRef.current  && !jsonRules["id_periodo"]){
      console.log("returning");
      return;
    }



    setIsLoading(true);
    let http_request = request_backend.replace('${page}',page).replace('${rows_per_page}',rowsPerPage);
    
    axios.post(http_request, jsonOptionsRules ,
          {
            headers: {
                "Authorization" : `Bearer ${sessionStorage.getItem('token')}`
            }
          })
      .then((response) => {
        //Obtiene los datos del API
        console.log(response);
        //Datos de las personas.
        console.log("informacion para la tabla ",response.data.lista_personas);
        const newData = tranformData(response.data.lista_personas)
        peopleDataAux = newData
        setPeopleData(newData);

        if (page == "0"){
          //Actualiza el conteo de datos totales
          SetOptionsTable( { ...optionsTable,  count: response.data.count_records});
          //Actualiza header
          const columns_table = 
          [
            { name: 'id_usuario', label: 'Identificador usuario', options: {"display":false,"filter":false, "viewColumns":false}, },
            { name: 'nombre', label: 'Nombre', options: {"filter":false}, },
            { name: 'email', label: 'Email', options: {"filter":false}, },
            { name: 'etapa', label: 'Etapa', options: {"filter":true, filterOptions: { names: response.data.etapa },}, },
            { name: 'estatus_beca', label: 'Estatus de beca', options: {filterOptions: { names: response.data.estatus_beca }}, },
            { name: 'nombre_certificado', label: 'Certificado', options: {filterOptions: { names: response.data.nombre_certificado }}, },
            { name: 'estatus_certificado', label: 'Estatus certificado', options: {filterOptions: { names: response.data.estatus_certificado }} },
            { name: 'estatus_usuario', label: 'Estatus usuario', options: {filterOptions: { names: response.data.estatus_usuario }}, },
            { name: 'organizacion', label: 'Organizacion', options: {filterOptions: { names: response.data.organizacion }}, },
            { name: 'universidad', label: 'Universidad', options: {filterOptions: { names: response.data.universidad }}, },
            { name: 'clave_prereg_c', label: 'Clave Pre-Reg', options: {filterOptions: { names: response.data.clave_prereg }}, },
            { name: 'id_certificado', label: 'Identificador del certificado', options:{ "filter":false, "sort":false, "display":false, "viewColumns":false } },
            { name: 'lock_change_estatus', label: 'Lock change status', options:{ "filter":false, "sort":false, "display":false, "viewColumns":false } },
            { name: 'lock_change_certificate', label: 'Lock change certificate', options:{ "filter":false, "sort":false, "display":false, "viewColumns":false } }
          ]
          setColumnsTable(columns_table);
        }
        //Actualiza los registros por pagina
        SetOptionsTable({ ...optionsTable, rowsPerPage: rowsPerPage, });
        setIsLoading(false);

        })

      .catch((error) => {
          console.log(error);
      });
  }

  const tranformData = (listData) => {
    return listData&&listData.map(data => ({...data, diff_cert:false, diff_whiteList:false,
      nombre_certificado:
      <div className='edit-data'>
        <p>{data.nombre_certificado}</p>
        <button disabled={data.lock_change_certificate === 1} onClick={()=>{handleChangueCert(data);}}><EditIcon/></button>
      </div>,
      estatus_beca: 
      <div className='edit-data'>
        <p>{data.estatus_beca}</p>
        <button disabled={data.estatus_beca != "Rechazado"} onClick={()=>{handleChangeStatusBeca(data)}}><EditIcon/></button>
      </div>,
      estatus_usuario: 
      <div className='edit-data'>
        <p>{data.estatus_usuario}</p>
        <button disabled={data.lock_change_estatus === 1} onClick={()=>{handleChangeUserActive(data)}}><EditIcon/></button>
      </div>,
      clave_prereg_c: 
      <div className='edit-data'>
        <p>{data.clave_prereg}</p>
        <button disabled={data.clave_prereg} onClick={()=>{handleChangeClavePrereg(data)}}><EditIcon/></button>
      </div>,
    }));
  }

  const tranformDataEdit = (listData, idUserCert, attributeChangue) => {
    return listData&&listData.map(data => {
      const idUserCertAux = createId(data);
      if(idUserCertAux === idUserCert ) {
        switch(attributeChangue) {
          case 'id_certificado': 
            return {...data, 
              nombre_certificado:
              <>
                <div className='edit-data'>
                  <Select id="chooseCert" value={data.id_certificado_new?data.id_certificado_new:data.id_certificado} onChange={(idCert)=>{updateDataEdit(listData,data,idCert.target.value,attributeChangue);}}>
                  {
                      idPeriodoCerts.current.certificados && 
                      idPeriodoCerts.current.certificados.map(cert => (
                          <MenuItem key={cert.id_certificado} value={cert.id_certificado}>{cert.titulo_certificado}</MenuItem>
                      ))
                  }
                  </Select>
                  <button className='acept-data' disabled={data.diff_cert === false} onClick={()=>{handleSelectNewCert(data, data.id_certificado_new);}}><DoneIcon/></button>
                  <button className='cancel-data' onClick={()=>{setPeopleData(peopleDataAux)}}><ClearIcon/></button>
                </div>
              </>
            };
          case 'estatus_beca':
            return {...data, 
              estatus_beca:
              <>
                <div className='edit-data'>
                  <Select id="chooseWhiteList" value={data.id_reason_reset_beca_new?data.id_reason_reset_beca_new:data.id_reason_reset_beca} 
                          onChange={(reason)=>{updateDataEdit(listData,data,reason.target.value,attributeChangue);}}>
                    {
                      listJustifRef.current &&
                      listJustifRef.current.map(just => (
                        <MenuItem key={just.id_justif_reset} value={just.id_justif_reset}>{just.texto_justificacion}</MenuItem>  
                      ))
                    }
                  </Select>
                  <button className='acept-data' disabled={data.diff_whiteList === false} onClick={()=>{handleResetBeca(data, data.id_reason_reset_beca_new);}}><DoneIcon/></button>
                  <button className='cancel-data' onClick={()=>{setPeopleData(peopleDataAux)}}><ClearIcon/></button>
                </div>
              </>
            };
          
          case 'estatus_usuario':
            const valueEstatusUsu = data.estatus_usuario_aux === 'Activo' ? 1 : 0;
            return {...data, 
              estatus_usuario:
              <>
                <div className='edit-data'>
                  <Select id="chooseWhiteList" value={valueEstatusUsu} onChange={(newUserStatus)=>{updateDataEdit(listData,data,newUserStatus.target.value,attributeChangue);}}>
                    <MenuItem value={0}>Baja</MenuItem>
                    <MenuItem value={1}>Activo</MenuItem>
                  </Select>
                  <button className='acept-data' disabled={valueEstatusUsu === 0} onClick={()=>{handleSelectUserStatus(data);}}><DoneIcon/></button>
                  <button className='cancel-data' onClick={()=>{setPeopleData(peopleDataAux)}}><ClearIcon/></button>
                </div>
              </>
            };
          case 'clave_prereg_c':
            return {...data, 
              clave_prereg_c:
              <>
                <div className='edit-data'>
                  <TextField id="new-clave-pre-reg" value={data.id_clave_pre_reg_new?data.id_clave_pre_reg_new:data.clave_prereg? data.clave_prreg:""} 
                          onChange={(cve_p)=>{updateDataEdit(listData,data,cve_p.target.value,attributeChangue);}}>
                  </TextField>
                  <button className='acept-data' disabled={data.diff_clave_prereg === false} onClick={()=>{handleSaveClavePrereg(data, data.id_clave_pre_reg_new);}}><DoneIcon/></button>
                  <button className='cancel-data' onClick={()=>{setPeopleData(peopleDataAux)}}><ClearIcon/></button>
                </div>
              </>
            };
          default: return data;
        }
       } else {
        return data;
       }
      });
  }

  // Funcion que actualiza la seleccion de un idCertificado o un idWhiteList
  const updateDataEdit = (listData,dataOrigin,newId,attributeChangue) => {

    const idUserCertSelectAux = createId(dataOrigin);

    const listDataUpdated = listData.map(data => {
      const idUserCertAux = createId(data);
      if(idUserCertAux === idUserCertSelectAux) {
        switch(attributeChangue) {
          case 'id_certificado': return {...data, id_certificado_new:newId, diff_cert: dataOrigin.id_certificado !== newId };
          case 'estatus_beca': return {...data, id_reason_reset_beca_new:newId, diff_whiteList:dataOrigin.id_reason_reset_beca !== newId};
          case 'estatus_usuario': return {...data, estatus_usuario:newId?'Activo':'Baja', estatus_usuario_aux:newId?'Activo':'Baja'};
          case 'clave_prereg_c': return {...data, id_clave_pre_reg_new:newId, diff_clave_prereg:dataOrigin.id_reason_reset_beca !== newId};
          default: return data;
        }
      } else {
        return data;
      }
    });

    const listDataUdateTranform = tranformDataEdit(listDataUpdated,idUserCertSelectAux,attributeChangue);
    setPeopleData(listDataUdateTranform);
  }

  const createId = (data) => {
    const idCert = data.id_certificado ? data.id_certificado : 0;
    return data.id_usuario.toString()+"_"+idCert.toString();
  }

  const handleChangueCert = (data) => {
    const idUsuCert = createId(data);
    const listPeopleEdit = tranformDataEdit(peopleDataAux,idUsuCert,'id_certificado');
    setPeopleData(listPeopleEdit);
  }

  const handleChangeStatusBeca = (data) => {
    const idUsuCert = createId(data);
    const listPeopleEdit = tranformDataEdit(peopleDataAux,idUsuCert,'estatus_beca');
    setPeopleData(listPeopleEdit);
  }

  const handleChangeUserActive = (data) => {
    const idUsuCert = createId(data);
    const listPeopleEdit = tranformDataEdit(peopleDataAux,idUsuCert,'estatus_usuario');
    setPeopleData(listPeopleEdit);
  }

  const handleChangeClavePrereg = (data) => {
    const idUsuCert = createId(data);
    const listPeopleEdit = tranformDataEdit(peopleDataAux,idUsuCert,'clave_prereg_c');
    setPeopleData(listPeopleEdit);
  }

  const handleSelectNewCert = (data, idCert) => {
    const params = {
      id_user:data.id_usuario,
      id_certificado_actual:data.id_certificado,
      id_certificado_nuevo:idCert,
      id_periodo_becas: data.id_periodo_becas
    };
    setIsLoading(true);
    axios.post(request_backendChangue, params,
      {
        headers: {
            "Authorization" : `Bearer ${sessionStorage.getItem('token')}`
        }
      })
    .then((response) => {
      updateBodyTable(pageAux,rowsPerPageAux,jsonRules);
      setIsLoading(false);
    })
    .catch((error) => {
      setIsLoading(false);
      console.error(error);
    });
  };

  const handleResetBeca = (data, reason) => {
    const params = {
      id_usuario:data.id_usuario,
      id_periodo_becas: data.id_periodo_becas,
      razon: reason
    };
    
    setIsLoading(true);
    axios.post(request_backendReset, params,
      {
        headers: {
            "Authorization" : `Bearer ${sessionStorage.getItem('token')}`
        }
      })
    .then((response) => {
      updateBodyTable(pageAux,rowsPerPageAux,jsonRules);
      setIsLoading(false);
    })
    .catch((error) => {
      setIsLoading(false);
      console.error(error);
    });
  }

  const handleSaveClavePrereg = (data, clavePrereg) => {
    const params = {
      id_usuario:data.id_usuario,
      id_periodo_becas: data.id_periodo_becas,
      clave_prereg: clavePrereg
    };
    
    setIsLoading(true);
    axios.post(request_backendClavePrereg, params,
      {
        headers: {
            "Authorization" : `Bearer ${sessionStorage.getItem('token')}`
        }
      })
    .then((response) => {
      if(response.data.status == 400 && response.data.message == "clave not found"){
        setMensaje({
          mostrarMensaje:true,
          textoMensaje:"Clave de pre registro no válida",
          tipoMensaje:"error"
        });
        setIsLoading(false);
        return;
      }
      
      if(response.data.status == 400 && response.data.message == "clave already used"){
        setMensaje({
          mostrarMensaje:true,
          textoMensaje:"Clave de pre registro ya usada",
          tipoMensaje:"error"
        });
        setIsLoading(false);
        return;
      }
      updateBodyTable(pageAux,rowsPerPageAux,jsonRules);
      setIsLoading(false);
    })
    .catch((error) => {
      setIsLoading(false);
      console.error(error);
    });
  };

  const handleSelectUserStatus = (data) => {

    const params = {
      id_user:data.id_usuario,
      id_periodo_becas: idPeriodoRef.current
    };

    setMensaje({...mensaje,mostrarMensaje:false});
    setIsLoading(true);
    
    axios.post(request_backendChangueUserStatus, params,
      {
        headers: {
            "Authorization" : `Bearer ${sessionStorage.getItem('token')}`
        }
      })
    .then((response) => {
      if(response.data.status_rollback === 'true') {
        updateBodyTable(pageAux,rowsPerPageAux,jsonRules);
      } else {
        setMensaje({
            mostrarMensaje:true,
            textoMensaje:response.data.message,
            tipoMensaje:"error"
        });
      }
      setIsLoading(false);
    })
    .catch((error) => {
      setIsLoading(false);
      console.error(error);
    });
  };

  const handleSelectPeriodo = (idPeriodoLocal) => {
    if(idPeriodoLocal == 0){
      return;
    }

    for(let pInx in listPeriodo){
      if(listPeriodo[pInx].id_periodos_becas == idPeriodoLocal){
        setPeriodo(listPeriodo[pInx]);
        setIdPeriodo(idPeriodoLocal);
        idPeriodoCerts.current = listPeriodo[pInx];
        idPeriodoRef.current = idPeriodoLocal;
      }
    }
  };

  //Make new request for change page 
  const changePage = (page, rowsPerPage) => {
      pageAux = page;
      rowsPerPageAux = rowsPerPage
      updateBodyTable(page,rowsPerPage);
  };

  const sort = (page, rowsPerPage, sortOrder) => {
    pageAux = page;
    rowsPerPageAux = rowsPerPage;
    jsonRules['sort'] = sortOrder;
    updateBodyTable(page,rowsPerPage,jsonRules);
};

  const changeRowsPerPage = (page, newRowsPerPage) => {
    pageAux = page;
    updateBodyTable(page,newRowsPerPage);
  };

  const search = (page, rowsPerPage, searchText) => { 
    pageAux = page;
    rowsPerPageAux = rowsPerPage;
    jsonRules["searchText"] = searchText;
    updateBodyTable(page,rowsPerPage,jsonRules);
  };

  const filterChange = (page, rowsPerPage, filterList) => {
    console.log("lo que llega al filtrar", filterList);
    pageAux = page;
    rowsPerPageAux = rowsPerPage;

    //Construct json_rule
    //filter list from columns of table
    let json = {
                etapa: filterList[3],
                estatus_beca: filterList[4],
                nombre_certificado: filterList[5],
                estatus_certificado: filterList[6],
                estatus_usuario: filterList[7],
                organizacion: filterList[8],
                universidad: filterList[9],
              };

    jsonRules['filter'] = json;
    updateBodyTable(page,rowsPerPage,jsonRules);

  };

  const donwload_full = (data) => {
    return data;
  }

  useEffect(() => {
    jsonRules["id_periodo"] = periodo.id_periodos_becas;
    updateBodyTable();
  }, [periodo]);


  //Fist load data from backend
  useEffect(() => {
    axios.get('https://prod-backend-dot-driven-presence-373215.uc.r.appspot.com/api/v1/admin/relationship_periodo_certificados_read/',
      { headers: {"Authorization" : `Bearer ${sessionStorage.getItem('token')}`}})
      .then((response) => {

        response.data.lista_periodos = response.data.lista_periodos.filter(p => p.id_certificados.length != 0);

        response.data.lista_periodos.map(p => {
          p.certificados = [];
          for(let certIdIdx in p.id_certificados){
            for(let certIdx in response.data.certificados){
              if(response.data.certificados[certIdx].id_certificado == p.id_certificados[certIdIdx]){
                p.certificados.push(response.data.certificados[certIdx]);
              }
            }
          }
          p.certificados.sort((a, b) => a.id_certificado > b.id_certificado);
        });
        setListPeriodo(response.data.lista_periodos);
        console.log(response.data.lista_periodos);
      })
      .catch((error) => {
          console.error(error);
      });

      axios.get('https://prod-backend-dot-driven-presence-373215.uc.r.appspot.com/api/v1/admin/justif_reset/',
      { headers: {"Authorization" : `Bearer ${sessionStorage.getItem('token')}`}})
      .then((response) => {
        setListJustif(response.data.lista_justif);
        listJustifRef.current = response.data.lista_justif;
        console.log("justificaciones");
        console.log(response.data.lista_justif);
      })
      .catch((error) => {
          console.error(error);
      });
  }, []);

  return (
    <>
        <AppBar/>
        <div className='content'>
          <div className='form-table-period'>
              <span className='nota_asterisco'>*</span><span className='nota'> Selecciona periodo:</span>
              <br />
              <Select
                  className='select_admin'
                  id="idPeriodo"
                  onChange={(event)=>{handleSelectPeriodo(event.target.value)}}
                  value={idPeriodo}
                  
              >
                  <MenuItem key={0} value={0}>Seleccione</MenuItem>
                  {
                      listPeriodo && 
                      listPeriodo.map( p => (
                          <MenuItem key={p.id_periodos_becas} value={p.id_periodos_becas}>{p.nombre_periodo}</MenuItem>
                      ))
                  }
              </Select>
          </div>
          {idPeriodoRef.current != 0 &&
          <MUIDataTable
                  title={
                    <Typography variant="h6">
                      Solicitudes
                      {isLoading && <CircularProgress size={24} style={{ marginLeft: 15, position: 'relative', top: 4 }} />}
                    </Typography>
                  }
                  data={peopleData}
                  columns={columnsTable}
                  options={optionsTable}
            />
          }
        </div>
        <Snackbar
            open={mensaje.mostrarMensaje}
            autoHideDuration={3000}
            onClose={()=>setMensaje({...mensaje,mostrarMensaje:false})}
        >
            <MuiAlert elevation={6} variant="filled" severity={mensaje.tipoMensaje}> {mensaje.textoMensaje} </MuiAlert>
        </Snackbar>
        <div>
          <StickyFooter />
        </div>
    </>
);

}

