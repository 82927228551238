import React from 'react';
import './css/landing_body.css';

export default function CommunityFacebook(){
    return(
        <div className='content-community-face'>
            <h1>¡Únete a nuestra comunidad de Facebook!</h1>
            <span>y te apoyaremos en tu camino al éxito</span> <br />
            <span>Solicita unirte, indicando la clave <b>GBCCG2024</b> y ¡Listo!</span>
            <div className='content-community-face_button'>
                <a href="https://www.facebook.com/groups/1ageneracioncertificadosdecarrera.google.inroads" target="_blank" rel="noopener noreferrer"><button>Ingresa al grupo</button></a>
            </div>
        </div>
    );
};