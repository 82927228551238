import React from 'react';
import ResponsiveDialog from './DailogRegister';
import "./css/appBar.css";
import { useLocation } from 'react-router-dom';

export default function SeccionRegister() {

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const openModal = params.get('openModal') == 'true' ? true : false;

    return (
        <div id="seccion-aplica" className='container-seccion-reg'>
            <div className='container-seccion-reg_titulo'>
                <p>
                    ¡No pierdas más tiempo! <br />
                    Solicita tu Beca ahora
                </p>
            </div>
            <div className='header-boton container-seccion-reg_boton'>
                <ResponsiveDialog textBoton="¡Aplica aquí!" openModal={openModal}/>
            </div>
            <div className='container-seccion-reg_nota'>
                <p>
                    *Cupo limitado, INROADS se reserva el derecho de admisión
                </p>
            </div>
        </div>
    )
};
