import React from 'react';
import CardCertNew from './CardCertNew';

export default function ListCerts({listCertsData, seleccionaNuevoCertificado}) {
    return (
        <div className='container-cards_list'>
            <div className='container-cards_list_item'>
                {
                    listCertsData.map(cert => (
                        <CardCertNew key={cert.tituloCert} card={cert} seleccionaNuevoCertificado={seleccionaNuevoCertificado}/>
                    ))
                }
            </div>
        </div>
    )
};
