import React,  { useEffect, useState } from 'react';
import axios from 'axios';
import { ChromePicker } from 'react-color';
import TextField from '@material-ui/core/TextField';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@material-ui/core/styles';
import CardCertNew from './components/CardCertNew';
import {DropzoneDialog} from 'material-ui-dropzone';
import InputAdornment from '@mui/material/InputAdornment';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

export default function RegCert() {
  const classes = useStyles();

  const [dataCert, setDataCert] = useState({
    id_cert : 0,
    nombreCert:"",
    tituloCert:"",
    textoCert:"",
    imgCert:null,
    colorCert:"#4285f4",
    url_ver_mas:"",
  });

  const [color, setColor] = useState({
    r: '66',
    g: '133',
    b: '244',
    a: '1',
  });

  const [modalImg, setModalImg] = useState(false);
  
  const [formDataCert, setFormDataCert] = useState({
    nombreCert:"",
    tituloCert:"",
    textoCert:"",
    imgCert:"",
    colorCert:"",
    url_ver_mas:"",
  });

  const [refrescar, setRefrescar] = useState(false);

  // Para control de mensajes de exito y error
  const [mensaje, setMensaje] = useState({
    mostrarMensaje: false,
    textoMensaje:"Se actualizo de manera correcta",
    tipoMensaje:"success"
  });
  // Fin control de mensajes

  const [selectCert, setSelectCert] = useState([]);

  const mensajeRequerido = "Campo requerido";
  const mensajeDatoCadena = "La cadena no debe de tener carácteres especiales"

  const regexTexto = /^[0-9a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-_\s*]+$/u;
  const regexURL= /^((?:https?|ftp):\/\/[^\s/$.?#].[^\s]*)$/;

  useEffect(() => {
    
    limpiarDatos();

  limpiarDatos();

  axios.get('https://prod-backend-dot-driven-presence-373215.uc.r.appspot.com/api/v1/admin/certificados_read/',{
      headers: {"Authorization" : `Bearer ${sessionStorage.getItem('token')}`}
  })
    .then((response) => {
        setSelectCert(response.data.certificados);
    })
      .then((response) => {
          setSelectCert(response.data.certificados);
      })
      .catch((error) => {
          console.error(error);
      });
  }, [refrescar]);

  // validaciones 
  // tipoDato:1.-Cadena , 2.-Numero
  const handleChangueForm = (event,nombreCampo,tipoDato) => {
      const value = event.target.value;
      const mensaje = realizaValidaciones(value,tipoDato);

      setFormDataCert({...formDataCert,[nombreCampo]:mensaje})
      setDataCert({...dataCert,[nombreCampo]:value});
  };
  
  const hadleChangeCert = (idCert) => {
    limpiarDatos();
    if(idCert > 0) {
      const certAux = selectCert.filter(cert => cert.id_certificado === idCert);
      setDataCert({
        colorCert: certAux[0].hex_color,
        id_cert: certAux[0].id_certificado,
        nombreCert: certAux[0].nombre_certificado,
        textoCert: certAux[0].texto_certificado,
        tituloCert: certAux[0].titulo_certificado,
        imgCert: certAux[0].url_imagen,
        url_ver_mas: certAux[0].url_ver_mas
      })
    }
  }

  const handleChangeColor = (color) => {
    setColor({...color.rgb});
    setDataCert({...dataCert,colorCert:color.hex})
  }

  const handleSaveImage = (files) => {
    setDataCert({...dataCert, imgCert:files[0]});
    setFormDataCert({...formDataCert, imgCert:""});
    setModalImg(false);
  }

  const realizaValidaciones = (value,tipoDato) => {
      const msjReq = validaRequerido(value);

      if(msjReq) {
          return msjReq;
      }

      switch(tipoDato) {
          case 1: return validaDatoCadena(value);
          case 2: return validaUrlCarena(value); 
          default: return '';
      }
  }

  const validaRequerido = (valorCampo) => {
      return (valorCampo === null || valorCampo === "") ? mensajeRequerido:"";
  }

  const validaDatoCadena = (valorCampo) => {
      return regexTexto.test(valorCampo) ? "":mensajeDatoCadena;
  }

  const validaUrlCarena = (valorCampo) => {
      return regexTexto.test(valorCampo) ? "":mensajeDatoCadena;
  }

  const validaForm = () => {
      for (const nombreCampo in formDataCert) {
          console.log("valor para ",nombreCampo,":",dataCert[nombreCampo]);
          if(!dataCert[nombreCampo]) {
              setFormDataCert({...formDataCert,[nombreCampo]:mensajeRequerido});
              return false;
          }
          if(formDataCert[nombreCampo]){
              return false;
          }
      }
      return true;
  }

  const limpiarDatos = () => {
    setDataCert({
      id_cert : 0,
      nombreCert:"",
      tituloCert:"",
      textoCert:"",
      imgCert:null,
      colorCert:"#4285f4",
    });

    setFormDataCert({
      nombreCert:"",
      tituloCert:"",
      textoCert:"",
      imgCert:"",
      colorCert:""
    });
  }

  const guardarDatos = () => {
    if(validaForm()) {
      const certData = new FormData();

      if(dataCert.id_cert > 0) {
        certData.append("id_certificado",dataCert.id_cert);
      }
      certData.append("nombre_certificado",dataCert.nombreCert);
      certData.append("hex_color",dataCert.colorCert);
      certData.append("file_logo", dataCert.imgCert);
      certData.append("texto_certificado", dataCert.textoCert);
      certData.append("titulo_certificado", dataCert.tituloCert);
      certData.append("url_ver_mas", dataCert.url_ver_mas);
      
      axios.post('https://prod-backend-dot-driven-presence-373215.uc.r.appspot.com/api/v1/admin/certificados_write/',certData,
      { headers: {"Authorization" : `Bearer ${sessionStorage.getItem('token')}`}})
      .then(res => {
        if(res.data.respuesta ==='ok') {
          setRefrescar(!refrescar);
          setMensaje({
              mostrarMensaje:true,
              textoMensaje:"Se guardo correctamente la información.",
              tipoMensaje:"success"
          });
      } else {
          setMensaje({
              mostrarMensaje:true,
              textoMensaje:"Se produjo un error al guardar la información.",
              tipoMensaje:"error"
          });
      }
      })
      .catch(error => {
        setMensaje({
          mostrarMensaje:true,
          textoMensaje:"Se produjo un error al guardar la información.",
          tipoMensaje:"error"
        });
        console.error(error)
      })
    }
  }

  return (
  <>
    <h3>Registro del certificado</h3> 
    <hr />
    <br />
    <Grid className='' container rowSpacing={6} columnSpacing={6}>
      <Grid item xs={12} md={12}>
        <Grid container rowSpacing={12} columnSpacing={12}>
            <Grid item xs={12} md={12}>
                <span className='nota_asterisco'>*</span><span className='nota'> Para modificar un certificado seleccionalo en el combo</span>
                <br />
                <Select
                className='select_admin'
                id="idCert"
                onChange={(event)=>hadleChangeCert(event.target.value)}
                value={dataCert.id_cert}
                >
                    <MenuItem value={0}>Nuevo Certificado</MenuItem>
                    {selectCert&&
                      selectCert.map(cert => (<MenuItem key={cert.id_certificado} value={cert.id_certificado}>{cert.nombre_certificado}</MenuItem>))
                    }
                </Select>
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                id="nombreCertificado"
                label="Nombre del certificado"
                type="text"
                value={dataCert.nombreCert}
                error={formDataCert.nombreCert}
                helperText={formDataCert.nombreCert}
                onChange={(event)=>handleChangueForm(event,'nombreCert',1)}
                className={classes.textField}
                inputProps={{ 
                  maxLength: 40,
                }}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                id="tituloCertificado"
                label="Título del certificado"
                type="text"
                value={dataCert.tituloCert}
                error={formDataCert.tituloCert}
                helperText={formDataCert.tituloCert}
                onChange={(event)=>handleChangueForm(event,'tituloCert',1)}
                className={classes.textField}
                inputProps={{ 
                  maxLength: 40,
                }}
                />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="urlVermas"
                label="URL con más información del certificado"
                type="text"
                value={dataCert.url_ver_mas}
                error={formDataCert.url_ver_mas}
                helperText={formDataCert.url_ver_mas}
                onChange={(event)=>handleChangueForm(event,'url_ver_mas',2)}
                className={classes.textField}
                inputProps={{ 
                  maxLength: 60,
                }} 
                />
            </Grid>
            <Grid item xs={12} md={6}>
              <div className='MuiFormControl-root MuiTextField-root makeStyles-textField-11'>
                <InputLabel htmlFor="standard-adornment-password">Imágen del certificado</InputLabel>
                  <Input disabled
                      id="standard-adornment-password"
                      error={formDataCert.imgCert}
                      helperText={formDataCert.imgCert}
                      endAdornment={
                      <InputAdornment position="end">
                          <IconButton color="primary" aria-label="upload picture" onClick={()=>{setModalImg(true)}}>
                              <FileUploadIcon />
                          </IconButton>
                      </InputAdornment>
                      }
                    />
                { formDataCert.imgCert&&
                  <p className='nota_mensaje_error'>{formDataCert.imgCert}</p>
                }
                </div>
                <DropzoneDialog
                      open={modalImg}
                      acceptedFiles={['image/jpeg', 'image/png']}
                      onSave={handleSaveImage}
                      showPreviews={true}
                      maxFileSize={5000000}
                      onClose={()=>{setModalImg(false)}}
                      dropzoneText="Carga imágen de certificado"
                      filesLimit={1}
                      dialogTitle=""
                      cancelButtonText="Cancelar"
                      submitButtonText="Subir"
                      previewText="Verificar"
                />
            </Grid>
            <Grid item xs={12} md={6}>
                  <TextField
                    id="textoCertificado"
                    label="Texto del certificado"
                    type="text"
                    className={classes.textField}
                    value={dataCert.textoCert}
                    error={formDataCert.textoCert}
                    helperText={formDataCert.textoCert}
                    onChange={(event)=>handleChangueForm(event,'textoCert',1)}
                    multiline
                    rows={6}
                    maxRows={6}
                    />
            </Grid>
            <Grid item xs={12} md={6}>
                <label className='MuiFormLabel-root MuiInputLabel-root'>Selecciona color</label>
                <br />
                <ChromePicker color={ color } onChange={ handleChangeColor } />
            </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={12}>
        <Grid container rowSpacing={6} columnSpacing={6}>
          <Grid item xs={12} md={12}>
            <h4>Visualización de certificado</h4>
            <CardCertNew card={dataCert}/>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    <div className='form-button form-button-background-morado'>
        <Button variant="contained" 
        size="small" 
        onClick={guardarDatos}
        >Guardar</Button>
    </div>
    <Snackbar
        open={mensaje.mostrarMensaje}
        autoHideDuration={3000}
        onClose={()=>setMensaje({...mensaje,mostrarMensaje:false})}
    >
        <MuiAlert elevation={6} variant="filled" severity={mensaje.tipoMensaje}> {mensaje.textoMensaje} </MuiAlert>
    </Snackbar>
  </>   
  );
  }