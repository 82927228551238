import React, { useRef } from 'react';

const SeccionMsgEstBecas = React.forwardRef((props, ref) =>{ 
    return(
        <>
        {props.hasPreRegistrationKey === false &&
        <div className='container-seccion-estatus' ref={ref}>
            <div className='container-seccion-estatus_titulo'>
                <p>
                    ¡Gracias por solicitar una Beca con INROADS para los Certificados de Carrera <br />
                    de Google en español! <br /><br />
                    Tu solicitud se encuentra en revisión.
                </p>
            </div>
            <div className='container-seccion-estatus_texto'>
                <p>
                    Pronto te informaremos sobre tu estatus al correo que registraste: <b>revisa tus bandejas,</b> <br/>
                    incluída SPAM y ubica el remitente: Becas Certificados de Carrera de Google: sistemas@inroads.org.mx <br/>
                    o <b>inicia sesión</b> aquí en nuestro portal.<br/>
                </p>
                <p><b>Agradecemos tu tiempo y participación en este proceso.</b></p>
            </div>
        </div>
        }
        {props.hasPreRegistrationKey === true &&
        <div className='container-seccion-estatus' ref={ref}>
            <div className='container-seccion-estatus_titulo'>
                <p>
                    ¡Gracias por completar tu solicitud!<br /><br />
                </p>
            </div>
            <div className='container-seccion-estatus_texto'>
                <p>
                    Indicaste una clave de pre-registro: <br/> <b>¡Ya tienes una beca asegurada para cursar los Certificados de Carrera de Google en español!</b> <br/><br/>
                    Recibirás una invitación en el transcurso de la semana al correo que registraste, del remitente: <br/>sistemas@inroads.org.mx para inscribirte a tu Certificado.<br/>
                    <b>Revisa tus bandejas </b>(incluida SPAM) o, inicia sesión aquí en nuestro portal.<br/><br/>
                    <b>¡Felicidades por dar este primer paso hacia el cumplimiento de tu metas!<br/><br/></b>
                    Hasta pronto.
                </p>
            </div>
        </div>
        }
        </>
    )
});

export default SeccionMsgEstBecas;