import React, { useState, useEffect, useRef } from 'react';
import AppBar from './AppBarHome';
import './index.css'
import ParallaxImg from './components/Parallax';
import Form from './Form';
import TimeLineCard from './TimeLineCard';
import FooterNew from './FooterNew';
import SeccionAsk from './SeccionAsk';
import ComparteRedes from './components/ComparteRedes';
import SeccionContact from './SeccionContact';
import SeccionMsgEstBecas from './SeccionMsgEstBecas';
import SeccionBecaOrtogada from './SeccionBecaOrtogada';
import CommunityFacebook from './CommunityFacebook';
import Collaborators from './Collaborators';
import SeccionDismiss from './SeccionDismiss';
import SeccionBecaFinalizada from './SeccionBecaFinalizada';
import SeccionJoinAllies from './SeccionJoinAllies';

export default function Stuff() {
    const [estatusBeca, setEstatusBeca] = useState(parseInt(sessionStorage.getItem("estatusBeca")));
    //const [estatusBeca, setEstatusBeca] = useState(2);
    const [hasPreRegistrationKey, setHasPreRegistrationKey] = useState(sessionStorage.getItem("hasPreRegistrationKey") === 'true');

    const updateEstatusBeca = (estatus) => {
        setEstatusBeca(estatus);
        setHasPreRegistrationKey(sessionStorage.getItem("hasPreRegistrationKey") === 'true');
    }

    const seccionMsgEstBecasRef = useRef(null);

    useEffect(() => {
        if (estatusBeca === 2 && seccionMsgEstBecasRef.current) {
          seccionMsgEstBecasRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      }, [estatusBeca]);

    return(
        <div>
            <AppBar />
            { estatusBeca === 1 &&
            <>
                <TimeLineCard estatusBeca={estatusBeca}/>
                <Form updateEstatusBeca={updateEstatusBeca}/>
                <SeccionAsk/>
            </> 
            }
            { estatusBeca === 2 &&
            <>
                <ParallaxImg estatusBeca={estatusBeca} despliegue={"login"}/>
                <TimeLineCard estatusBeca={estatusBeca} />
                <SeccionMsgEstBecas estatusBeca={estatusBeca} hasPreRegistrationKey={hasPreRegistrationKey} ref={seccionMsgEstBecasRef}/>
                <ComparteRedes  estatusBeca={estatusBeca} />
                <SeccionContact />
                <SeccionAsk/>
            </>
            }
             { estatusBeca === 3 &&
            <>
                <ParallaxImg estatusBeca={estatusBeca} despliegue={"login"}/>
                <SeccionDismiss/>
                {/*<SeccionAsk/>*/}
            </>
            }
            { estatusBeca === 4 &&
            <>
                <ParallaxImg estatusBeca={estatusBeca} despliegue={"login"}/>
                <TimeLineCard estatusBeca={estatusBeca} pagePreview={2}/>
                <SeccionBecaOrtogada/>
                <SeccionContact />
                <SeccionAsk/>
                <ComparteRedes  estatusBeca={estatusBeca} />
                <CommunityFacebook/>
                {/*<Collaborators/>*/}
            </>
            }
            { estatusBeca === 5 &&
            <>
                <ParallaxImg estatusBeca={estatusBeca} despliegue={"termino_beca"}/>
                <TimeLineCard estatusBeca={estatusBeca} pagePreview={2}/>
                <SeccionBecaFinalizada/>
                {/*<SeccionJoinAllies/>*/}
                <ComparteRedes  estatusBeca={estatusBeca} />
                <SeccionContact />
                {/*<SeccionAsk/>*/}
                <CommunityFacebook/>
                {/*<Collaborators/>*/}
            </>
            }
            <FooterNew />
        </div>
    );
};
