import React,{useState, useEffect} from 'react';
import axios from 'axios';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';


export default function SeccionCalificarForm() {

    const [periodo, setPeriodo] = useState(0);

    const [listPeriodo, setListPeriodo] = useState([
        {
            id_periodo_becas:1,
            nombre_periodo:"Periodo en duro"
        }
    ]);

    useEffect(() => {
        axios.get('https://prod-backend-dot-driven-presence-373215.uc.r.appspot.com/api/v1/admin/periodo_becas_read/',
        { headers: {"Authorization" : `Bearer ${sessionStorage.getItem('token')}`}})
        .then((response) => {
            setListPeriodo(response.data.periodos_becas);
        })
        .catch((error) => {
            console.error(error);
        });
    }, []);

    // Para control de mensajes de exito y error
    const [mensaje, setMensaje] = useState({
        mostrarMensaje: false,
        textoMensaje:"Se actualizo de manera correcta",
        tipoMensaje:"success"
    });

    const calificarForm = () => {
        axios.post('https://prod-backend-dot-driven-presence-373215.uc.r.appspot.com/api/v1/admin/calificar/',{id_periodo_becas: periodo},
        {
            headers: {"Authorization" : `Bearer ${sessionStorage.getItem('token')}`}
        })
        .then(res => {
            setMensaje({
                mostrarMensaje:true,
                tipoMensaje:"success",
                textoMensaje:"Calficación exitosa"
            });
        })
        .catch(error => {
            setMensaje({
                mostrarMensaje:true,
                tipoMensaje:"error",
                textoMensaje:"Error al calificar"
            });
            console.error(error)
        });
    }

    return(
        <>
            <h2>Ejecutar calificaciones de formularios</h2>
            <br />
            <Grid container rowSpacing={4} columnSpacing={8}>
                <Grid item xs={12} md={12}>
                    <span className='nota_asterisco'>*</span><span className='nota'> Selecciona periodo:</span>
                    <br />
                    <Select
                        className='select_admin'
                        id="idPeriodo"
                        onChange={(event)=>{setPeriodo(event.target.value)}}
                        value={periodo}
                    >
                        {
                            listPeriodo && 
                            listPeriodo.map( periodo => (
                                <MenuItem key={periodo.id_periodo_becas} value={periodo.id_periodo_becas}>{periodo.nombre_periodo}</MenuItem>
                            ))
                        }
                    </Select>
                </Grid>
            </Grid>
            <div className='form-button form-button-background-azul'>
                <Button variant="contained" 
                disabled={!periodo}
                size="small" 
                onClick={calificarForm}
                >Calificar</Button>
            </div>
            <Snackbar
                open={mensaje.mostrarMensaje}
                autoHideDuration={3000}
                onClose={()=>setMensaje({...mensaje,mostrarMensaje:false})}
            >
                <MuiAlert elevation={6} variant="filled" severity={mensaje.tipoMensaje}> {mensaje.textoMensaje} </MuiAlert>
            </Snackbar>
        </>
    )    
};
