import React,{useEffect, useState} from 'react';
import axios from 'axios';
import cursoTI from './img/certs/curso_TI.png';
import cursoGestion from './img/certs/curso_gestionproyectos.png';
import cursoDatos from './img/certs/curso_datos.png';
import cursoUX from './img/certs/curso_UX.png';
import cursoMarketing from './img/certs/curso_marketing.jpeg';
import ListCerts from './components/ListCerts';

export default function SeccionListCerts({ updatePeriodIsActive }) {

    const [listCertsData, setListCertsData] = useState([]);

    useEffect(() => {
        axios.get('https://prod-backend-dot-driven-presence-373215.uc.r.appspot.com/api/v1/admin/certificados_public_read/')
        .then((response) => {
            console.log("lo que llega", response);
            const infoCertAux = response.data.certificados&&response.data.certificados.map(cert => ( {
                tituloCert:cert.titulo_certificado,
                textoCert:cert.texto_certificado,
                imgCert:cert.url_imagen,
                colorCert:cert.hex_color,
                url_ver_mas:cert.url_ver_mas
            }))
            setListCertsData(infoCertAux);
            updatePeriodIsActive(response.data.isActive);
            window.dispatchEvent(new Event("updatedPeriodIsActive"));
        })
        .catch((error) => {
            console.error(error);
        });
    }, []);

    const listCertsDataA = [
        {
            tituloCert:"Certificado en Soporte TI de Google",
            textoCert:`Si te gusta resolver problemas y ayudar a otros, ¡el Certificado en Soporte de Tecnologías de Información es para ti! Los especialista en Soporte de TI solucionan problemas para que los dispositivos y las redes funcionen correctamente.`,
            imgCert:cursoTI,
            colorCert:"#4285f4",
        },
        {
            tituloCert:"Certificado en Análisis de Datos",
            textoCert:`El análisis de datos es cada vez más relevante para la toma de decisiones de las empresas. Si te gusta descubrir patrones, tendencias, ordenar datos y realizar visualizaciones ¡Éste Certificado es para ti!.`,
            imgCert:cursoDatos,
            colorCert:"#ea4335",
        },
        {
            tituloCert:"Certificado en Gestión de Proyectos",
            textoCert:`Si te gusta planear, solucionar problemas, organizar, trabajar con personas y asegurarte de que todos se administre y se lleve a cabo en tiempo y forma, ¡Éste Certificado es para ti! La Gestión de proyectos es aplicable en cualquier campo: laboral, de emprendimiento o personal.`,
            imgCert:cursoGestion,
            colorCert:"#fbbc05",
        },
        {
            tituloCert:"Certificado en Diseño de Experiencia de Usuario",
            textoCert:`Si te gusta el arte, el diseño de información, la investigación y crear experiencias agradables, útiles y accesibles, ¡El Certificado de Diseño de Experiencia de Usuario es para ti! Este programa te permite desarrollar habilidades centradas en las necesidades de las personas que utilizan productos o servicios físicos o digitales.`,
            imgCert:cursoUX,
            colorCert:"#34a843",
        },
        {
            tituloCert:"Certificado en Marketing Digital e E-commerce",
            textoCert:`Adquiere habilidades sólidas en marketing en línea, SEO, publicidad digital, gestión de campañas en redes sociales y mucho más. Obtén las herramientas y conocimientos necesarios para impulsar el crecimiento de empresas y negocios en el entorno digital actual.`,
            imgCert:cursoMarketing,
            colorCert:"#561dab",
        },
    ]

    return(
        <div id="list=certs" className='container-cards'>
            <ListCerts listCertsData={listCertsData}/>
        </div>
    )
}