import React, { Component } from 'react';
import axios from 'axios';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from "@material-ui/core/TextField";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { withRouter } from 'react-router';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import Typography from "@material-ui/core/Typography";
import iconoConfirmacion from './img/icono-conformacion.svg';
import "./css/landing_body.css";
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormHelperText from '@mui/material/FormHelperText';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import LoadingButton from '@mui/lab/LoadingButton';

class SignUp extends Component {
  
  state = {
      user: '',
      checkUser: '',
      pass: '',
      checkPass: '',
      phoneNumber: '',
      isNewsChecked: false,
      isSignedup: false,
      setOpen:false,
      open:false,
      openModal: false,
      alert: false,
      alertContent: '',
      validateEmail: false,
      validatePass: false,
      uriel: false,
      openModalNested: false,
      correo: 0,
      contraseña:0,
      datosGeneral:0,
      showPassword: false,
      acepto_aviso: false,
      existencia:0,
      validate: {
        status_user: 0,
        message_user: "",
        status_check_user: 0,
        message_check_user: "",
        message_check_phone_number: "",
        status_phone_number:0,
        status_pass: 0,
        status_check_pass: 0,
        message_check_pass: "",
        loading: false
      }

  }
  handleClickShowPassword = event => {
    this.setState({ showPassword: !this.state.showPassword })
  };

  handleMouseDownPassword = event => {
    event.preventDefault();
  }

  handleClickOpen = event => {
      event.preventDefault()
      this.setState({ setOpen:true})
  }

  handleClose = event => {
      this.setState({ open:false})
  }

  handleUser = event => {
    // eslint-disable-next-line
    if (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(event.target.value) || event.target.value === "")
      this.setState({ user: event.target.value, validate: { ...this.state.validate, message_user: "",status_user: 0 } })
    else{
      this.setState({validate: { ...this.state.validate, message_user: "Por favor ingresa un correo valido",status_user: 1 }});
      this.setState({ user: "" })
    }
  }

  handleUserCheck = event => {
      if(event.target.value !== "" && event.target.value === this.state.user)
        this.setState({checkUser: event.target.value,
          validate: { ...this.state.validate, message_check_user: "",status_check_user: 0 } })
      else
        this.setState({validate: { ...this.state.validate, message_check_user: "El correo no coincide",status_check_user: 1 }});

  }

  handlePhoneCheck = event => {
    // No permitir ingresas letras
    let inputValue = event.target.value;

    inputValue = inputValue.replace(/\D/g, '');

    if (inputValue.length > 10) {
      inputValue = inputValue.slice(0, 10);
    }

    event.target.value = inputValue;

    if (/^\d{10}$/.test(event.target.value) || event.target.value === "")
      this.setState({phoneNumber: event.target.value,
        validate: { ...this.state.validate, message_check_phone_number: "",status_phone_number: 0 } })
    else
      this.setState({validate: { ...this.state.validate, message_check_phone_number: "El formato del teléfono debe ser a 10 dígitos", status_phone_number:1 }});

}

  handlePass = event => {

    if (/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/.test(event.target.value) || event.target.value === "")
      this.setState({ pass: event.target.value, validate: { ...this.state.validate, status_pass: 0 } })
    else{
      this.setState({validate: { ...this.state.validate, status_pass: 1 }});
      this.setState({ pass: "" })
    }
  }

  handlePassCheck = event => {
    if(event.target.value !== "" && event.target.value === this.state.pass)
        this.setState({checkPass: event.target.value,
          validate: { ...this.state.validate, message_pass_user: "",status_check_pass: 0 } })
      else
        this.setState({validate: { ...this.state.validate, message_check_pass: "La contraseña no coincide",status_check_pass: 1 }});
  }

  handleAlert = event => {
      this.setState({ alert: true })
      this.setState({ alertContent: 'Registro exitoso'})
  }

  handleCheck = event => {
    this.setState({ acepto_aviso: !this.state.acepto_aviso })
  }

  handleCheckNews = event => {
    this.setState({ isNewsChecked: !this.state.isNewsChecked })
  }

  login = event => {
      
      var val_temp_email = false;
      var val_temp_pass = false;
      var validations = this.state.validate;
      
      //Validar no nulo
      if (this.state.user === ""){
        validations.message_user = "Favor de colocar un correo";
        validations.status_user = 1;
      }

      if (this.state.checkUser === ""){
        validations.status_check_user = 1;
      }

      if (this.state.pass === ""){
        validations.status_pass = 1;
      }

      if (this.state.checkPass === ""){
        validations.status_check_pass = 1;
      }

      if (this.state.phoneNumber === ""){
        validations.status_phone_number = 1;
      }
      
      this.setState({validate: validations,acepto_aviso: this.state.acepto_aviso})

      // Revisar que no exista un mensaje de error
      if(  validations.status_user === 1 
        || validations.status_check_user === 1
        || validations.status_pass === 1
        || validations.status_check_pass === 1){
          return false;
      }else{
        val_temp_email = true;
        val_temp_pass = true;
      }
      
      /*
      if (validateUser.email === validateUser.checkEmail){
        val_temp_email = true
      }else{
        this.setState({ correo: 1 })
      }

      if (validateUser.password === validateUser.checkPass){
        val_temp_pass = true
      }else{
        this.setState({ contraseña: 1 })
      }
      */

      const headersPost = {
        'content-type': 'text/json'
      }

      const user = {
          email: this.state.user,
          password: this.state.pass,
          phoneNumber: this.state.phoneNumber,
          acceptNews: this.state.isNewsChecked
          //signedup: this.state.isSignedup,
      };

      if (val_temp_email && val_temp_pass){
        const data = user
        const headers_post = JSON.stringify(headersPost)
        this.setState({loading : true});
        axios.post('https://prod-backend-dot-driven-presence-373215.uc.r.appspot.com/api/v1/login/register/', data, headers_post)
        .then(res => {
        if (res.request.status === 200){
          //sessionStorage.setItem('isSigned', true)
          //sessionStorage.setItem('email',this.state.user)
          //this.setState({ isSignedup: true })

          if(res.data.error){
            if(res.data.message.includes("ya registrado")){
              validations.message_user = "Correo ya registrado, por favor inicia sesión directamente";
              validations.status_user = 1;
              this.setState({validate: validations,acepto_aviso: this.state.acepto_aviso})
            }
          }else{
            this.setState({ openModalNested: true})
          }

        } else {
          this.setState({ isSignedup: false })
        }
        }).finally(()=>this.setState({loading : false}));
      }else{
        this.setState({ datosGeneral: 1})
      }
  }

  launchModal = e => {
      e.preventDefault()
      this.setState({ openModal: true})
  }

  launchModalNested = e => {
    e.preventDefault()
    this.setState({ openModalNested: true})
}

  closeModal = e => {
      this.setState({ 
        openModal: false,
        acepto_aviso: false,
        validate: {
          status_user: 0,
          message_user: "",
          status_check_user: 0,
          message_check_user: "",
          status_pass: 0,
          status_check_pass: 0,
          message_check_pass: "",
        }
      })
  }

  closeModalNested = e => {
    this.setState({ 
      openModal: false,
      openModalNested: false,
      acepto_aviso: false,
      validate: {
        status_user: 0,
        message_user: "",
        status_check_user: 0,
        message_check_user: "",
        status_pass: 0,
        status_check_pass: 0,
        message_check_pass: "",
      }
    })
}

  getPeriodIsActiveFromSessionStorage = () => {
    return sessionStorage.getItem('periodIsActive') === 'true';
  }

  componentDidMount(){
    window.addEventListener('updatedPeriodIsActive', (event) =>{
      if (this.props.openModal){
        this.setState({ setOpen:true})
        this.setState({ openModal: true})
      }
    })
  }

  render(){    
    return (
          <>
          {
          this.props.liga !== "true" ?
          <button 
            className='boton-registro2'
            onClick={this.launchModal}
          >
            {this.props.textBoton?
              <span>{this.props.textBoton}</span>: <span>aplica</span>
            }
          </button>
          :
          <a href="/" className="link" onClick={this.launchModal}>Regístrate aquí</a>
          }

          <Modal 
          open={this.state.openModal}
          onClose={this.closeModal} 
          id="modal-registro"
          >
            <Box
            component="form"
            sx={{
              '& > :not(style)': { m: 1},
              display: 'flex', flexWrap: 'wrap',
            }}
            noValidate
            autoComplete="off"
            style={{width: "85%", margin:"auto"}}
            >
        
              <DialogContent className='font-landing'>
                  {this.getPeriodIsActiveFromSessionStorage() === false &&(
                  <div>
                  <br></br>
                  <center><h5>Gracias por tu interés.<br></br> ¡Nuestro Programa llegó a su fin!</h5></center>
                  <br></br><br></br>
                  Regístrate <a href="https://forms.gle/DvFrhjr6PQ3jZwi98" className="link"
                    style={{margin:"auto"}} target="_blank" rel="noopener noreferrer">AQUÍ</a>  para mantenerte al tanto de próximas noticias.
                  <br></br><br></br>
                  </div>)
                  }
                  {this.getPeriodIsActiveFromSessionStorage() === true &&(
                  <div>
                  <DialogContentText style={{fontSize:'25px'}}>
                  Regístrate y aplica para una beca
                  </DialogContentText>
                  <br></br>
                  <p>Regístrate sólo si eres mayor de edad y si eres mexican@ o resides en México.</p>
                  <TextField 
                  helperText={this.state.validate.message_user}
                  error={this.state.validate.status_user === 1}
                  margin="dense" 
                  id="correo" 
                  label="Correo electrónico" 
                  type="email"
                  fullWidth variant="standard"
                  autoComplete='email'
                  onChange={this.handleUser}/>
                  
                  <TextField
                  helperText={this.state.validate.message_check_user}
                  error={this.state.validate.status_check_user === 1}
                  margin="dense" 
                  id="checkcorreo" 
                  label="Confirma tu correo" 
                  type="email"
                  fullWidth variant="standard"
                  onChange={this.handleUserCheck}/>

                  <TextField
                  helperText={this.state.validate.message_check_phone_number}
                  error={this.state.validate.status_phone_number === 1}
                  margin="dense" 
                  id="phoneNumber" 
                  label="Número de celular" 
                  type="tel"
                  inputProps={{ pattern: "[0-9]*" }} 
                  fullWidth variant="standard"
                  autoComplete='off'
                  onChange={this.handlePhoneCheck}/>

                  <FormHelperText id="phoneNumber">
                    Así podremos apoyarte en tu proceso de registro
                  </FormHelperText>
                  
                  <FormControl variant="standard" style={{width: "100%"}} >
                  <InputLabel htmlFor="standard-adornment-password"
                    error={this.state.validate.status_pass === 1}
                  >Contraseña</InputLabel>
                  <Input
                    error={this.state.validate.status_pass === 1}
                    margin="dense"
                    id="contraseña"
                    label="Contraseña"
                    type={this.state.showPassword ? 'text' : 'password'}
                    fullWidth variant="standard" 
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={this.handleClickShowPassword}
                        >
                          {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    onChange={this.handlePass}
                  />
                    <FormHelperText id="contraseña">
                      Longitud mínima de 8, al menos:<br/>
                      Una mayúscula<br/>
                      Una minúscula<br/>
                      Un dígito<br/>
                      Un símbolo
                    </FormHelperText>
                  </FormControl>
                  
                  <TextField
                  helperText={this.state.validate.message_check_pass}
                  error={this.state.validate.status_check_pass === 1}
                  margin="dense"
                  id="checkcontraseña" 
                  label="Confirma tu contraseña" 
                  type="password" 
                  fullWidth variant="standard" 
                  onChange={this.handlePassCheck}/>
                  
                  <FormGroup>
                    <br></br>
                    <FormControlLabel control={<Checkbox onChange={this.handleCheckNews}/>}
                      label="Quiero recibir noticias de INROADS"
                    />
                    <FormControlLabel control={<Checkbox onChange={this.handleCheck} />}
                      label="Acepto el aviso de privacidad y que sean enviadas notificaciones al correo electrónico que he proporcionado."
                    />
                    <a href="https://inroads.org.mx/aviso-de-privacidad/" className="link"
                    style={{margin:"auto"}} target="_blank" rel="noopener noreferrer">Ver aviso de privacidad</a> 
                    <br></br><br></br>
                    {this.state.acepto_aviso ? 

                      <LoadingButton
                        className="button_ovalado" 
                        style={{width:'40%', textTransform: 'None',margin: "auto"}}
                        onClick={this.login}
                        loading={this.state.loading}
                        loadingPosition="end"
                        variant="contained">
                            Regístrate
                      </LoadingButton>
                      : null }

                    <br />
                  </FormGroup>
                  
                    <Modal
                    open={this.state.openModalNested}
                    onClose={this.closeModalNested}
                    >

                      <Stack spacing={5} alignItems="center" style={{marginTop: "5%"}} id="modal-confirmacion" >
                        <br></br><br></br>
                      <Typography variant="h5" color="text.secondary">
                            Enviamos un correo para confirmar tu cuenta
                          </Typography>
                          <Typography variant="h6" color="text.secondary">
                            (sistemas@inroads.org.mx)
                          </Typography>
                          <Avatar sx={{ minWidth: 100 , minHeight: 100 }} variant="square" src={iconoConfirmacion}>
                            Confirmado
                          </Avatar>
                          <Typography variant="h6" color="text.secondary">
                            Te recomendamos revisar tu bandeja de spam
                          </Typography>
                          <br></br>
                      </Stack>
                        
                    </Modal>
                    </div>)
              }
              </DialogContent>
              </Box>
          </Modal>   
          </>
      )
  }
}

export default withRouter(SignUp);