import React, {useState,useEffect} from 'react';
import CSVFileValidator from 'csv-file-validator'
import axios from 'axios';
import AppBar from './AppBarHome';
import StickyFooter from "./StickyFooter";
import { Modal } from 'react-responsive-modal';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import "./css/adminStyle.css";
import { styled } from '@mui/material/styles';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { DataGrid } from "@mui/x-data-grid";

const DocumentoDescarga = ({link}) => (
    <a href={link}>
        <ArticleOutlinedIcon  sx={{ color: 'black' }} fontSize="large"/>
    </a>
);

export default function AdminAlumCert() {

    const [periodo, setPeriodo] = useState(0);
    const [listPeriodo, setListPeriodo] = useState([]);

    const [modal, setModal] = useState(false);
    const [csv, setCsv] = useState(null);
    const [muestraErrores, setMuestraErrores] = useState(false);
    const [certificados, setCertificados] = useState([]);
    const [activaBoton, setActivaBoton] = useState(false);
    const [refrescar, setRefrescar] = useState(false);
   
    // Para control de mensajes de exito y error
    const [mensaje, setMensaje] = useState({
        mostrarMensaje: false,
        textoMensaje:"Se actualizo de manera correcta",
        tipoMensaje:"success"
    });
    // Fin control de mensajes

    const Input = styled('input')({
        display: 'none',
    });

    // Expresiones para validaciones
    const regexTexto = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;
    const regexCorreo = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    const regexFecha = /^([0-2][0-9]|3[0-1])(\/|-)(0[1-9]|1[0-2])\2(\d{4})(\s)([0-1][0-9]|2[0-3])(:)([0-5][0-9])$/;
    const regexUrl = /^(http|https):\/\/[^ "]+$/;
    const regexEntero = /^[0-9]+$/;
    const regexDecimal = /^[0-9]+([.,][0-9]+)?$/;

    useEffect(() => {

        axios.get('https://prod-backend-dot-driven-presence-373215.uc.r.appspot.com/api/v1/admin/periodo_becas_read/',
        { headers: {"Authorization" : `Bearer ${sessionStorage.getItem('token')}`}})
        .then((response) => {
            setListPeriodo(response.data.periodos_becas);
        })
        .catch((error) => {
            console.error(error);
        });

    }, [refrescar]);

    

    // Metodos para validaciones
    const isTexto = (texto) => (regexTexto.test(texto) && texto.length < 101);
    const isCorreo = (correo) => (regexCorreo.test(correo) && correo.length < 101);
    const isFecha = (fecha) => (regexFecha.test(fecha) && fecha.length < 101);
    const isUrl = (url) => (regexUrl.test(url) && url.length < 101);
    const isEntero = (entero) => (regexEntero.test(entero) && entero.length < 14);
    const isDecimal = (decimal) => (regexDecimal.test(decimal) && decimal.length < 14);

    // Mensaje para errores
    const requiredError = (headerName, rowNumber, columnNumber) => {
        return `<div>${headerName} es requerido en la <strong>fila ${rowNumber}</strong> / <strong>columna ${columnNumber}</strong></div>`
    }
    
    const validateError = (headerName, rowNumber, columnNumber) => {
        return `<div>La <strong>columna ${headerName}</strong> no tiene un valor valido o supera el máximo número de caractares en la <strong>fila ${rowNumber}</strong></div>`
    }

    // Configuracion para validacion de csv
    const CSVConfig = {
        headers: [
            { name: 'Nombre', inputName: 'nombre', required: true, requiredError, validate: isTexto, validateError },
            { name: 'Correo', inputName: 'correo', required: true, requiredError, validate: isCorreo, validateError },
            { name: 'External ID', inputName: 'externalID', required: true, requiredError, validate: isEntero, validateError },
            { name: 'Certificado', inputName: 'certificado', required: true, requiredError, validate: isTexto, validateError },
            { name: 'Fecha de inscripción', inputName: 'fechaIncripcion', required: true, requiredError, validate: isFecha, validateError },
            { name: 'Completado', inputName: 'completado', required: true, requiredError, validate: isTexto, validateError },
            { name: 'Progreso', inputName: 'progreso', required: true, requiredError, validate: isDecimal, validateError },
            { name: 'Horas de aprendizaje', inputName: 'horasAprendizaje', required: true, requiredError, validate: isDecimal, validateError },
            { name: 'Calificación Certificado', inputName: 'calificacionCertificado', required: true, requiredError, validate: isDecimal, validateError },
            { name: 'Fecha de finalización', inputName: 'fechaFinalizacion', required: true, requiredError, validate: isFecha, validateError },
            { name: 'URL del certificado Final', inputName: 'urlCertificadoFinal', required: true, requiredError, validate: isUrl, validateError }
        ],
        isHeaderNameOptional: true,
        parserConfig: {
            encoding: "windows-1258",
        }
    };

    const validaFormatoCSV = (e) => {
        setMuestraErrores(false);
        if(e.target.files[0].type === "text/csv") {
            CSVFileValidator(e.target.files[0], CSVConfig)
		    .then(csvData => {
                if(csvData.inValidMessages.length > 0) {
                    setMuestraErrores(true);
                    csvData.inValidMessages.forEach(message => {
                        if(message.includes("Number of fields mismatch")){
                             message = `<div>La estructura del archivo no es valido</div>`
                        }
                        document.getElementById('invalidMessages').insertAdjacentHTML('beforeend', message)
                    });
                } else {
                    setMuestraErrores(false);
                    setCsv(e.target.files[0]);
                    setModal(true);
                }
		    })           
        }
    };

    const handleLimpiaDatosModal = () => {
        setModal(false);
        setActivaBoton(false);
        setCsv(null);
    };

    const limpiarInformacion = () => {
        setPeriodo(0);
        setCertificados([]);
        setRefrescar(!refrescar);
    }

    const handleEnviarInformacion = () => {
        const formData = new FormData();
        formData.append('reporte_coursera', csv);
        formData.append('id_periodo_becas',periodo);

        setActivaBoton(false);

        axios.post('https://prod-backend-dot-driven-presence-373215.uc.r.appspot.com/api/v1/admin/fin_certificado/upload_reporte_coursera/',formData,
        { headers: {"Authorization" : `Bearer ${sessionStorage.getItem('token')}`}})
        .then((response) => {
            if(response.data.estatus === 1) {
                setModal(false);
                limpiarInformacion();
                setMensaje({
                    mostrarMensaje:true,
                    textoMensaje:"Se cargo correctamente el archivo.",
                    tipoMensaje:"success"
                });
            } else {
                setMensaje({
                    mostrarMensaje:true,
                    textoMensaje:"Ocurrio un error al cargar el archivo.",
                    tipoMensaje:"error"
                });
            }
        })
        .catch((error) => {
            setMensaje({
                mostrarMensaje:true,
                textoMensaje:"Ocurrio un error al cargar el archivo.",
                tipoMensaje:"error"
            });
            console.error("Error en la petición ",error);
        });
    };

    const obtenerHistorial = (idPeriodo) => {
        setPeriodo(idPeriodo);

        axios.post('https://prod-backend-dot-driven-presence-373215.uc.r.appspot.com/api/v1/admin/fin_certificado/historial_reportes_coursera/',{id_periodo_becas:idPeriodo},
        { headers: {"Authorization" : `Bearer ${sessionStorage.getItem('token')}`}})
        .then((response) => {
            const historialAux = response.data.historial && response.data.historial.map(docu => ({...docu,id:docu.idDocumento}))
            setCertificados(historialAux);
        })
        .catch((error) => {
            console.error(error);
        });
    };

    const data = {
        columns:[
            {
                field: "id",
                hide: true,
                editable: false,
            }, 
            {
                field: "file_name",
                headerName: "Nombre archivo",
                hide: false,
                editable: false,
                cellClassName:"cell-center",
                width: 280,
            },
            {
                field: "linkDocumentoOk",
                headerName: "Archivo Original",
                hide: false,
                editable: false,
                renderCell:(data)=>(<DocumentoDescarga link={data.value}/>),
                cellClassName:"cell-center",
                width: 200,
            }, 
            {
                field: "linkDocumentoBad",
                headerName: "Archivo con registro erroneo",
                hide: false,
                editable: false,
                renderCell:(data)=>(<DocumentoDescarga link={data.value}/>),
                cellClassName:"cell-center",
                width: 200,
            }, 
            {
                field: "linkDocumentoTotal",
                headerName: "Archivo con registro cargados",
                hide: false,
                editable: false,
                renderCell:(data)=>(<DocumentoDescarga link={data.value}/>),
                cellClassName:"cell-center",
                width: 200,
            },
            {
                field: "numPersonasOk",
                headerName: "# de personas cargadas",
                hide: false,
                editable: false,
                cellClassName:"cell-center",
                width: 160,
            }, 
            {
                field: "numPersonasBad",
                headerName: "# de personas no cargadas",
                hide: false,
                editable: false,
                cellClassName:"cell-center",
                width: 160,
            }, 
            {
                field: "numPersonasTotal",
                headerName: "# total de personas",
                hide: false,
                editable: false,
                cellClassName:"cell-center",
                width: 160,
            }, 
            {
                field: "fechaCarga",
                headerName: "Fecha de creación",
                hide: false,
                editable: false,
                cellClassName:"cell-center",
                width: 200,
            }
        ],
        rows:certificados
    }

    const TablaHistorial = () => (
        <div className='hader-purple' style={{ height: 500, width: "100%" }}>
            <DataGrid
                {...data}
            />
        </div>
    );

    // -- Fin de estilos para tabla

    const ModalConfirmacion = () => (
        <Modal open={modal} onClose={handleLimpiaDatosModal} >
            <DialogContent>
                <DialogContentText >
                    <div className='dialog'>
                        <h2>¡Alerta!</h2>
                        <div>
                            <p>Al verificar esta casilla, confirmas la carga de información <br />
                            y dicha acción no es reversible.</p>
                        </div>
                        <div className='dialog-button form-button-background-morado'>
                            <Button 
                                variant="contained" 
                                size="large"
                                onClick={handleEnviarInformacion}
                            >Aceptar</Button>
                        </div>
                    </div>
                </DialogContentText>
            </DialogContent>
        </Modal>
    );

    return (
        <>
            <AppBar/>
            <ModalConfirmacion />
            <div className='card-main'>
                <h1>Módulo de administración de <br /> alumnos certificados</h1>
                <hr />
                
                <div className="form-table">
                    <div className='form-table-period'>
                        <span className='nota_asterisco'>*</span><span className='nota'> Selecciona periodo:</span>
                        <br />
                        <Select
                            className='select_admin'
                            id="idPeriodo"
                            onChange={(event)=>{obtenerHistorial(event.target.value)}}
                            value={periodo}
                        >
                            {
                                listPeriodo && 
                                listPeriodo.map( periodo => (
                                    <MenuItem key={periodo.id_periodo_becas} value={periodo.id_periodo_becas}>{periodo.nombre_periodo}</MenuItem>
                                ))
                            }
                        </Select>
                    </div>
                    <TablaHistorial/>
                </div>
                <div className='form-button form-button-background-morado'>
                    <label htmlFor="contained-button-file">
                        <Input accept=".csv" id="contained-button-file" multiple type="file" onChange={validaFormatoCSV} disabled={!periodo}/>
                        <Button variant="contained" component="span" disabled={!periodo}>
                            Carga CSV de Coursera
                        </Button>
                    </label>
                </div>
                {muestraErrores &&
                    <div className='caja-mensaje-errores'>
                        <h3>Errores encontrados en el archivo:</h3>
                        <div id='invalidMessages'>
                        </div>
                    </div>
                }
            </div>
            <Snackbar
                open={mensaje.mostrarMensaje}
                autoHideDuration={3000}
                onClose={()=>setMensaje({...mensaje,mostrarMensaje:false})}
            >
                <MuiAlert elevation={6} variant="filled" severity={mensaje.tipoMensaje}> {mensaje.textoMensaje} </MuiAlert>
            </Snackbar>
            <StickyFooter />
        </>
    );

}