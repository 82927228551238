import React from 'react';
import logo from "./inroads_logo_2024.png";
import FacebookIcon from './img/redes/footer/facebook.svg';
import LinkedInIcon from './img/redes/footer/linkedin.svg';
import InstaIcon from './img/redes/footer/insta.svg';
import Youtube from './img/redes/footer/icono_YT.svg';
import Whats from './img/redes/footer/icono_wapp.svg';

export default function FooterNew() {
    return(
        <div id="footer" className='container-footer'>
            <div className='container-footer_data'>
                <div>
                    <span>
                        <a href="https://inroads.org.mx/" style={{color: "white"}} target="_blank" rel="noopener noreferrer">
                            <img src={logo} alt="Inroads" className='logo'/>
                        </a>
                    </span>
                </div>
                <div>
                    <p> <a href="https://inroads.org.mx/nosotros" style={{color: "white"}} target="_blank" rel="noopener noreferrer"> Nosotros </a></p>
                    <p> <a href="https://inroads.org.mx/formacion-de-jovenes/" style={{color: "white"}} target="_blank" rel="noopener noreferrer"> Programas </a></p>
                    <p> <a href="https://inroads.org.mx/mentorias-corporativas-mentee/" style={{color: "white"}} target="_blank" rel="noopener noreferrer"> Soy Mentee </a></p>
                    <p> <a href="https://inroads.org.mx/mentorias-corporativas-mentor/" style={{color: "white"}} target="_blank" rel="noopener noreferrer"> Quiero ser Mentor </a></p>
                    <p> <a href="https://inroads.org.mx/vinculacion-con-empresas" style={{color: "white"}} target="_blank" rel="noopener noreferrer"> Soy empresa </a></p>
                </div>
                <div>
                    <p> <a href="https://www.tfaforms.com/5115099?_gl=1*ctwlht*_gcl_au*MTU2ODM1OTc4OC4xNzEzMzkyMDY4LjE1ODQ0MTA1MDEuMTcxMzkwMTI3OS4xNzEzOTAxMjc5*_ga*MTQ1MDg0Nzk4NC4xNzEzMzkyMDcw*_ga_RJMH9BGVQ1*MTcxMzkwNjQ0NS4xMy4wLjE3MTM5MDY0NTQuNTEuMC4w" style={{color: "white"}} target="_blank" rel="noopener noreferrer"> Pre-inscripción </a></p>
                    <p> <a href="https://inroads.org.mx/comunidad-inroader/" style={{color: "white"}} target="_blank" rel="noopener noreferrer"> Comunidad </a></p>
                    <p> <a href="https://inroads.org.mx/convocatorias-y-eventos/" style={{color: "white"}} target="_blank" rel="noopener noreferrer"> Convocatorias y eventos </a></p>
                    <p> <a href="https://inroads.org.mx/donaciones/" style={{color: "white"}} target="_blank" rel="noopener noreferrer"> Dona Hoy </a></p>
                    <p> <a href="https://inroads.org.mx/web2022/contacto/" style={{color: "white"}} target="_blank" rel="noopener noreferrer"> Contáctanos</a></p>
                </div>
                
                <div>
                    <p> <a href="https://inroads.org.mx/aviso-de-privacidad/" style={{color: "white"}} target="_blank" rel="noopener noreferrer"> Aviso de Privacidad </a></p>
                    <p> <a href="https://wa.me/524428755968?text=Hola%2C+buen+d%C3%ADa%21+quiero+recibir+informaci%C3%B3n+sobre+INROADS" style={{color: "white"}} target="_blank" rel="noopener noreferrer"> Tel: 55 51 86 05 86 </a></p>
                    <p> <a href="mailto:inroads.mexico@inroads.org.mx" style={{color: "white"}}> inroads.mexico@inroads.org.mx</a></p>
                </div>
            </div>
            <div className='container-footer_redes_sociales'>
                <a href="https://www.facebook.com/INROADS.Mexico/" target="_blank" rel="noopener noreferrer">
                    <img src={FacebookIcon} alt="Facebook" />
                </a>
                <a href="https://www.youtube.com/c/INROADSdeM%C3%A9xicoAC" target="_blank" rel="noopener noreferrer">
                    <img src={Youtube} alt="Youtube" />
                </a>
                <a href="https://www.instagram.com/inroads_mx/" target="_blank" rel="noopener noreferrer">
                    <img src={InstaIcon} alt="Instagram" />
                </a>
                <a href="https://www.linkedin.com/company/inroads-de-m-xico-a-c-/" target="_blank" rel="noopener noreferrer">
                    <img src={LinkedInIcon} alt="Linkedin"/>
                </a>
                <a href="https://wa.me/524428755968?text=Hola%2C+buen+d%C3%ADa%21+quiero+recibir+informaci%C3%B3n+sobre+INROADS" target="_blank" rel="noopener noreferrer">
                    <img src={Whats} alt="WhatsApp" />
                </a>
            </div>
            <div className='container-footer_derechos'>
                <p>© 2023 INROADS de México. Todos los derechos reservados.</p>
            </div>
        </div>
    )
};
