import React from 'react';

export default function SeccionIntro() {

    return(
        <div className='container-intro'>
             <div className='sub-container-intro'>
                <div className='continair-intro_exp'>
                    <div className='container-intro_titulo'>
                        <p>Amplía tus posibilidades de crecimiento con los Fundamentos y  
                        Certificados de Carrera de Google</p>
                    </div>
                    <div className='container-intro_texto'>
                        <p>Desarrolla competencias de alta demanda en el mercado laboral.
                        INROADS de México A.C. en alianza con Google, te ofrecen becas para apoyar
                        tu desarrollo profesional 
                        que te ayudarán a enfrentar los retos de empleabilidad actuales y futuros, 
                        en un entorno de  crecimiento tecnológico.
                        </p>
                        <p>Gracias a esta beca podrás cursar los Fundamentos y Certificados de Carrera de Google sin costo.</p>
                    </div>
                </div>
                <div className='container-explain_video'>
                    <iframe src="https://www.youtube.com/embed/ehAWfLNLS0E?si=G9Hp4q0VTZaf5CgK"
                        title="YouTube video player" frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                        allowfullscreen>
                    </iframe>
                    {/* <img src={ImgSust} alt="video"/> */}
                </div>
            </div>
        </div>
    );
}