import React, { Component } from 'react';

import AppBar from './AppBar';
import TimeLineCards from './TimeLineCard';
import ParallaxImg from './components/Parallax';
import SeccionIntro from './SeccionIntro';
import ExplainCerts from './ExplainCerts';
import SeccionListCerts from './SeccionListCerts';
import SeccionAsk from './SeccionAsk';
import SeccionRegister from './SeccionRegister';
import SeccionContact from './SeccionContact';
import FooterNew from './FooterNew';
import SeccionAskHome from './SeccionAskHome';
import SeccionExperience from './SeccionExperience';

class Home extends Component {
    state = {
        periodIsActive: false,
    };

    updatePeriodIsActive = (value) => {
        this.setState({ periodIsActive: value });
        sessionStorage.setItem('periodIsActive', value);
    };

    componentDidMount(){
        sessionStorage.setItem('periodIsActive', false);
    }

    render() {
        return(
            <div>
                <AppBar />
                <ParallaxImg despliegue={"index"}/>
                <TimeLineCards />
                <SeccionIntro/>
                <ExplainCerts/>
                <SeccionListCerts updatePeriodIsActive={this.updatePeriodIsActive}/>
                <SeccionAskHome />
                <SeccionAsk soloPreguntasFrecuentes={true}/>
                <SeccionRegister/>
                <SeccionContact/>
                <SeccionExperience/>
                <FooterNew />
            </div>
        )
    }
}

export default Home