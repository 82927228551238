import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Autocomplete from '@mui/material/Autocomplete';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import TextField from "@mui/material/TextField";
import Select from '@mui/material/Select';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import {DropzoneDialog} from 'material-ui-dropzone'
import FileUploadIcon from '@mui/icons-material/FileUpload';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Input from '@mui/material/Input';
import Button from '@mui/material/Button';
import { Modal } from 'react-responsive-modal';
import PopForm from './img/pop_form.svg';
import "./css/form.css";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment/moment';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

export default function Form({updateEstatusBeca}) {

    const classes = useStyles();

    const numGridColumn = 12;
    //variables para vaslidar
    const id_otro = 1;
    const id_orgnazacion_select = 4
    const escuelaValidos = [6,7,8];

    const idPeridioBeca = parseInt(sessionStorage.getItem("periodoBeca"));
    const prePhoneNumber = sessionStorage.getItem("phoneNumber") == 'null' ? '' : sessionStorage.getItem("phoneNumber");

    const phoneRef = useRef();

    const [formData, setFormData] = useState(
        {
            clave_pre_registro: "",
            curp: "",
            nombre_oficial: "",
            apellido_oficial: "",
            nombre_certificado: "",
            apellido_certificado: "",
            id_catalogo_genero: null,
            id_catalogo_estado: null,
            edad: "",
            codigo_postal: "",
            telefono_celular: prePhoneNumber,
            telefono_celular_confirm: prePhoneNumber,
            id_catalogo_ocupacion: null,
            id_catalogo_estudio: null,
            fecha_graduacion: "",
            id_catalogo_carrera: null,
            id_catalogo_universidad: null,
            otra_universidad: "",
            id_catalogo_promedio: null,
            id_catalogo_grupo_poblacion: null,
            id_catalogo_primer_certificado: null,
            id_catalogo_razon_primer_cert: null,
            id_catalogo_dedicacion: null,
            id_catalogo_motivo: null,
            id_catalogo_ingresos: null,
            id_catalogo_difusion: null,
            id_catalogo_organizacion: null,
            acceso_computadora: null,
            propia_computadora: null,
            acceso_internet: null,
            //file_identificacion: null,
            //file_estudios: null
        }
    );

    const [mensajesForm, setMensajesForm] = useState({
        clave_pre_registro:"",
        curp:"",
        nombre_oficial:"",
        apellido_oficial:"",
        nombre_certificado:"",
        apellido_certificado:"",
        id_catalogo_genero:"",
        id_catalogo_estado:"",
        edad:"",
        codigo_postal:"",
        telefono_celular:"",
        telefono_celular_confirm:"",
        id_catalogo_ocupacion:"",
        id_catalogo_estudio:"",
        fecha_graduacion:"",
        id_catalogo_carrera:"",
        id_catalogo_universidad:"",
        otra_universidad:"",
        id_catalogo_promedio:"",
        id_catalogo_grupo_poblacion:"",
        id_catalogo_primer_certificado:"",
        id_catalogo_razon_primer_cert:"",
        id_catalogo_dedicacion:"",
        id_catalogo_motivo:"",
        id_catalogo_ingresos:"",
        id_catalogo_difusion:"",
        id_catalogo_organizacion:"",
        acceso_computadora:"",
        propia_computadora:"",
        acceso_internet:"",
        //file_identificacion:"",
        //file_estudios:""
    });

    const [listaCatalogos, setListaCatalogos] = useState({
        generos:[],
        estados:[],
        ocupaciones:[],
        niveles_estudio:[],
        carreras:[],
        universidades:[],
        promedios_generales:[],
        comunidades:[],
        certificados:[],
        preparaciones_certificado:[],
        horas_dedicar:[],
        motivos_solicitud:[],
        ingresos_mensuales:[],
        medios_difusion:[],
        organizaciones:[],
    });

    const [nombreArchivos, setNombreArchivos] = useState({
        nombreIdenticacion:"",
        nombreComprobante:""
    });

    const [preClaveCorrecta, setPreClaveCorrecta] = useState(false);
    const [dialogoIdentificacion, setDialogoIdentificacion] = useState(false);
    const [dialogoComprobante, setDialogoComprobante] = useState(false);

    const [checksAviso, setChecksAviso] = useState({
        datosPersonalesOrganizacion:false,
        informacionProporcionada:false,
        cumplimientoTiempos:false,
        tratamientoDatos:false,
        avisoPrivacidad:false
    });
    
    const [checksCompletos, setChecksCompletos] = useState(false);

    const [fomularioInvalido, setFomularioInvalido] = useState(false);

    const [modalConfirmacion, setModalConfirmacion] = useState(false);

    const [bloqueo, setBloqueo] = useState(false);

    const regexCurp = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/;
    const regexTexto = /^[0-9a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-_]+$/u;
    const regexEntero = /^[0-9]+$/;
    const regexMayorEdad = /^(1[89]|[2-9][0-9])$/;


    const mensajeRequerido = "Campo requerido";
    const mensajeDatoCadena = "La cadena no debe de tener carácteres especiales";
    const mesajeDatoEntero = "El dato debe de ser númerico"
    const mensajeCurpInvalido = "Por favor ingresa un CURP valido";
    const mensajeDatoMin = "El número de teléfonico debe tener mínimo 10 digitos"
    const mensajeEdadInvalida = "Edad inválida, recuerda que la persona debe de ser mayor de edad";

    const metaFormData = 
    {
        clave_pre_registro: {
            tipoValidaciones:{regex:regexTexto}
        },
        curp: {
            tipoValidaciones:{requerido:true,regex:regexCurp}
        },
        nombre_oficial: {
            tipoValidaciones:{requerido:true,regex:regexTexto}
        },
        apellido_oficial: {
            tipoValidaciones:{requerido:true,regex:regexTexto}
        },
        nombre_certificado: {
            tipoValidaciones:{requerido:true,regex:regexTexto}
        },
        apellido_certificado: {
            tipoValidaciones:{requerido:true,regex:regexTexto}
        },
        id_catalogo_genero: {
            tipoValidaciones:{requerido:true}
        },
        id_catalogo_estado: {
            tipoValidaciones:{requerido:true}
        },
        edad: {
            tipoValidaciones:{requerido:true,regex:regexMayorEdad, esNumero:true}
        },
        codigo_postal: {
            tipoValidaciones:{requerido:true,regex:regexEntero, esNumero:true}
        },
        telefono_celular: {
            tipoValidaciones:{requerido:true,regex:regexEntero, esNumero:true, minLength:10}
        },
        telefono_celular_confirm: {
            tipoValidaciones:{requerido:true,regex:regexEntero, esNumero:true, minLength:10}
        },
        id_catalogo_ocupacion: {
            tipoValidaciones:{requerido:true}
        },
        id_catalogo_estudio: {
            tipoValidaciones:{requerido:true}
        },
        fecha_graduacion: {
            tipoValidaciones:{requerido:escuelaValidos.indexOf(formData.id_catalogo_estudio) !== -1}
        },
        id_catalogo_carrera: {
            tipoValidaciones:{requerido:escuelaValidos.indexOf(formData.id_catalogo_estudio) !== -1}
        },
        id_catalogo_universidad: {
            tipoValidaciones:{requerido:escuelaValidos.indexOf(formData.id_catalogo_estudio) !== -1}
        },
        otra_universidad: {
            tipoValidaciones:{requerido:formData.id_catalogo_universidad === id_otro,regex:regexTexto}
        },
        id_catalogo_promedio: {
            tipoValidaciones:{requerido:true,}
        },
        id_catalogo_grupo_poblacion: {
            tipoValidaciones:{requerido:true,}
        },
        id_catalogo_primer_certificado: {
            tipoValidaciones:{requerido:true,}
        },
        id_catalogo_razon_primer_cert: {
            tipoValidaciones:{requerido:true,}
        },
        id_catalogo_dedicacion: {
            tipoValidaciones:{requerido:true,}
        },
        id_catalogo_motivo: {
            tipoValidaciones:{requerido:true,}
        },
        id_catalogo_ingresos: {
            tipoValidaciones:{requerido:true,}
        },
        id_catalogo_difusion: {
            tipoValidaciones:{requerido:true,}
        },
        id_catalogo_organizacion: {
            tipoValidaciones:{requerido:formData.id_catalogo_difusion === id_orgnazacion_select,}
        },
        acceso_computadora: {
            tipoValidaciones:{requerido:true}
        },
        propia_computadora: {
            tipoValidaciones:{requerido:true}
        },
        acceso_internet: {
            tipoValidaciones:{requerido:true}
        },/*
        file_identificacion: {
            tipoValidaciones:{requerido:true}
        },
        file_estudios: {
            tipoValidaciones:{requerido:true}
        }*/
    };

    useEffect(() => {
        axios.get('https://prod-backend-dot-driven-presence-373215.uc.r.appspot.com/api/v1/catalogos_formulario_read/?id_periodo_becas='+idPeridioBeca,{
            headers: {"Authorization" : `Bearer ${sessionStorage.getItem('token')}`}
        })
        .then((response) => {
            setListaCatalogos({...response.data});
        })
        .catch((error) => {
            console.error(error);
        });
    }, []);

    const handleChangue = (value,nombreParametro,uppercase) => {
        const valueAux = uppercase ? value.toUpperCase() : value;
        validaDato(valueAux,nombreParametro);
        setFormData({...formData,[nombreParametro]:valueAux});
        setChecksAviso({
            datosPersonalesOrganizacion:false,
            informacionProporcionada:false,
            cumplimientoTiempos:false,
            tratamientoDatos:false,
            avisoPrivacidad:false
        });
        setChecksCompletos(false);
    };

    const handleSaveIdentificacion = (files) => {
        setNombreArchivos({...nombreArchivos,nombreIdenticacion:files[0].name})
        setFormData({...formData,file_identificacion:files[0]});
        setMensajesForm({...mensajesForm,file_identificacion:""});
        setDialogoIdentificacion(false);
    };

    const handleSaveComprobante = (files) => {
        setNombreArchivos({...nombreArchivos,nombreComprobante:files[0].name})
        setFormData({...formData,file_estudios:files[0]});
        setMensajesForm({...mensajesForm,file_estudios:""});
        setDialogoComprobante(false);
    };

    const handleChangueCheck = (event,nombreParametro) => {
        const auxChecks = {...checksAviso,[nombreParametro]:event.target.checked}
        let countChecks = 0;

        for(const nameCheck in auxChecks) {
            if(auxChecks[nameCheck]) {
                countChecks++;
            }
        }
        setChecksAviso(auxChecks);
        setChecksCompletos((preClaveCorrecta&&countChecks===5)||(!preClaveCorrecta&&countChecks===4));
    };

    const validaDato = (value,nombreParametro) => {
        //Depende del tipo de validación que tenga es el que se va a realizar
        //Si es requerido
        if(metaFormData[nombreParametro].tipoValidaciones.requerido&&!value) {
            setMensajesForm({...mensajesForm, [nombreParametro]:mensajeRequerido});
            setFomularioInvalido(true);
            return false;
        }

        //Si tiene un regex para validar tipo de estructura de dato
        if(metaFormData[nombreParametro].tipoValidaciones.regex&&!metaFormData[nombreParametro].tipoValidaciones.regex.test(value)&&value) {
            switch(nombreParametro) {
                case 'curp': 
                        setMensajesForm({...mensajesForm, [nombreParametro]:mensajeCurpInvalido});
                        break;
                case 'edad': setMensajesForm({...mensajesForm, [nombreParametro]:mensajeEdadInvalida});
                        break;
                default:    if(metaFormData[nombreParametro].tipoValidaciones.esNumero) {
                                setMensajesForm({...mensajesForm, [nombreParametro]:mesajeDatoEntero});
                            } else {
                                setMensajesForm({...mensajesForm, [nombreParametro]:mensajeDatoCadena});
                            }
                        break;
            }
            setFomularioInvalido(true);
            return false;
        }

        //Si se tiene que validar un minimo
        if(metaFormData[nombreParametro].tipoValidaciones.minLength && metaFormData[nombreParametro].tipoValidaciones.minLength > value.length) {
            setMensajesForm({...mensajesForm, [nombreParametro]:mensajeDatoMin});
            return false;
        }

        //Se limpia mensaje
        setMensajesForm({...mensajesForm, [nombreParametro]:""});
        setFomularioInvalido(false);
        return true;
    };

    const validaClavePreRegistro = (event) => {
        const value = event.target.value
        setPreClaveCorrecta(false);
        setFormData({...formData,
            id_catalogo_difusion:null,
            clave_pre_registro:null,
            id_catalogo_organizacion:null
        });

        if(!value||!validaDato(value,"clave_pre_registro")) {
            return false; 
        }

        const formDataAux = new FormData();
        formDataAux.append("id_periodo_becas",idPeridioBeca);
        formDataAux.append("clave_pre_registro",value);

        axios.post('https://prod-backend-dot-driven-presence-373215.uc.r.appspot.com/api/v1/form_check_clave_pre_registro',formDataAux,
        { headers: {"Authorization" : `Bearer ${sessionStorage.getItem('token')}`}})
        .then(res => {
            setFormData({...formData,
                id_catalogo_difusion:res.data.id ? res.data.id:null,
                clave_pre_registro:value,
                id_catalogo_organizacion: res.data.id_organizacion ? res.data.id_organizacion:null
            })
            setPreClaveCorrecta(true);
        })
        .catch(error => {
            setFormData({...formData,clave_pre_registro:value})
            setMensajesForm({...mensajesForm,clave_pre_registro:"La clave de pre registro no es valida"});
            setFormData({...formData,
                id_catalogo_difusion:null,
                clave_pre_registro:null,
                id_catalogo_organizacion:null
            });
            console.error(error)
        });
    };

    const validaCurp = (event) => {
        const value = event.target.value;
        
        if(!validaDato(value,"curp")) {
           return false; 
        }

        const formDataAux = new FormData();
        formDataAux.append("id_periodo_becas",idPeridioBeca);
        formDataAux.append("curp",value);

        axios.post('https://prod-backend-dot-driven-presence-373215.uc.r.appspot.com/api/v1/form_check_no_duplicated_curp',formDataAux,
        {
            headers: {"Authorization" : `Bearer ${sessionStorage.getItem('token')}`}
        })
        .then(res => {
            if(res.data.respuesta !== 'ok') {
                setMensajesForm({...mensajesForm,curp:"La CURP ya se encuentra registrada para este periodo"});
            } 
        })
        .catch(error => {
            setMensajesForm({...mensajesForm,curp:"No se pudo validar la CURP"});
            console.error(error)
        });
    };

    const validaCelulares = (event,otroTel) => {
        const value = event.target.value;
        const mensaje = value !== otroTel ? "Los telefonos no coinciden":"";

        if (mensaje !== "") {
            phoneRef.current.focus();
            window.scrollTo({
                top: phoneRef.current.offsetTop - 100,
                behavior: "smooth",
            });
        }
       
        setMensajesForm({...mensajesForm,telefono_celular_confirm:mensaje});
    };

    const limpiaDatosUniversidad = () => {
        setFormData({...formData,fecha_graduacion:"",id_catalogo_carrera:null,id_catalogo_universidad:null,otra_universidad:null});
    };

    const limpiaDatosOrganizacion = () => {
        setFormData({...formData,id_catalogo_organizacion:null});
    };

    const validaEnviaForm = () => {

        for(const elmentForm in formData) {
            if(!validaDato(formData[elmentForm],elmentForm)) {
               return false;
            }
        }

        setBloqueo(true);
        //Si todo esta correcto se raliza el envio de la información
        const formDataAux = new FormData();
        for(const elmentForm in formData) {
            formDataAux.append(elmentForm,formData[elmentForm]);
        }

        //Para el caso de la fecha se reemplaza para cambiar el formato
        if(formData.fecha_graduacion) {
            formDataAux.delete("fecha_graduacion");
            const fechaFormateada = moment(formData.fecha_graduacion, "yyyy-MM-DD").format("yyyy-MM-DD");
            formDataAux.append("fecha_graduacion",fechaFormateada);
        } else {
            formDataAux.delete("fecha_graduacion");
        }
        
        formDataAux.append("id_periodo_becas",idPeridioBeca);
        console.log(JSON.stringify(formDataAux))
        
        
        axios.post('https://prod-backend-dot-driven-presence-373215.uc.r.appspot.com/api/v1/form_datos_usuario_write',formDataAux,
        {
            headers: {"Authorization" : `Bearer ${sessionStorage.getItem('token')}`}
        })
        .then(res => {
            sessionStorage.setItem('estatusBeca','2');
            sessionStorage.setItem('hasPreRegistrationKey', formData.clave_pre_registro !== '');
            updateEstatusBeca(2);
            setBloqueo(false);
            // setModalConfirmacion(true);
        })
        .catch(error => {
            console.error(error);
            setBloqueo(false);
        });
        
    };

    return(
        <div className='content content-formulario'>
        <div className='head-formulario'>
            <p> 
                <b>Formulario Solicitud de Beca</b> <br />
                Te pedimos completar todos los campos solicitados
            </p>
        </div>
        <Grid container rowSpacing={6} columnSpacing={8}>
            <Grid item xs={12} md={12}>
                <Alert severity="info">Recuerda que debes de llenar tu formulario</Alert>
            </Grid>
            <Grid item xs={12} md={numGridColumn} >
                <label htmlFor="clavePreRegistro">Si tienes una clave, (eres recomendado de alguna organización o concluiste un Certificado al 100%) ingrésala, si no, puedes dejar este campo vacío.</label>
                <TextField 
                    margin="dense"
                    id="clavePreRegistro"
                    label=""
                    value={formData.clave_pre_registro}
                    onChange={(event)=>handleChangue(event.target.value,'clave_pre_registro',true)}
                    error={mensajesForm.clave_pre_registro}
                    helperText={mensajesForm.clave_pre_registro}
                    type="text"
                    fullWidth
                    variant="standard"
                    inputProps={{ 
                        maxLength: 22,
                        minLength: 22,
                        style: { textTransform: "uppercase" },
                        onBlur:validaClavePreRegistro
                    }}
                />
            </Grid>
            <Grid item xs={12} md={numGridColumn} >
                <TextField 
                    margin="dense"
                    id="curp"
                    label="Ingresa tu CURP"
                    value={formData.curp}
                    onChange={(event)=>handleChangue(event.target.value,'curp',true)}
                    error={mensajesForm.curp}
                    helperText={mensajesForm.curp}
                    type="text"
                    fullWidth
                    variant="standard"
                    inputProps={{ 
                        maxLength: 18,
                        minLength: 18,
                        style: { textTransform: "uppercase" },
                        onBlur:validaCurp
                    }}
                />
                <span style={{fontWeight:"lighter"}}>Si no sabes cual es tu CURP, puedes consultarlo 
                <a className='link' href='https://www.gob.mx/curp/' target="_blank" rel="noopener noreferrer"> aquí.</a>
                </span>
            </Grid>
            <Grid item xs={12} md={numGridColumn} >
                <TextField 
                    margin="dense"
                    id="nombreOficial"
                    label="Nombre/s oficial"
                    value={formData.nombre_oficial}
                    onChange={(event)=>handleChangue(event.target.value,'nombre_oficial',true)}
                    error={mensajesForm.nombre_oficial}
                    helperText={mensajesForm.nombre_oficial}
                    type="text"
                    fullWidth
                    variant="standard"
                    inputProps={{ 
                        maxLength: 40,
                        style: { textTransform: "uppercase" }
                    }}
                />
            </Grid>
            <Grid item xs={12} md={numGridColumn} >
                <TextField 
                    margin="dense"
                    id="apellidoOficial"
                    label="Apellido/s oficial"
                    value={formData.apellido_oficial}
                    onChange={(event)=>handleChangue(event.target.value,'apellido_oficial', true)}
                    error={mensajesForm.apellido_oficial}
                    helperText={mensajesForm.apellido_oficial}
                    type="text"
                    fullWidth
                    variant="standard"
                    inputProps={{ 
                        maxLength: 100,
                        style: { textTransform: "uppercase" }
                    }}
                />
            </Grid>
            <Grid item xs={12} md={numGridColumn} >
                <TextField 
                    margin="dense"
                    id="nombreCertificado"
                    label="Nombre/s con el cual quieres que aparezca tu Certificado"
                    value={formData.nombre_certificado}
                    onChange={(event)=>handleChangue(event.target.value,'nombre_certificado', true)}
                    error={mensajesForm.nombre_certificado}
                    helperText={mensajesForm.nombre_certificado}
                    type="text"
                    fullWidth
                    variant="standard"
                    inputProps={{ 
                        maxLength: 40,
                        style: { textTransform: "uppercase" }
                    }}
                />
            </Grid>
            <Grid item xs={12} md={numGridColumn} >
                <TextField 
                    margin="dense"
                    id="apellidoCertificado"
                    label="Apellido/s con el cual quieres que aparezca tu Certificado"
                    value={formData.apellido_certificado}
                    onChange={(event)=>handleChangue(event.target.value,'apellido_certificado', true)}
                    error={mensajesForm.apellido_certificado}
                    helperText={mensajesForm.apellido_certificado}
                    type="text"
                    fullWidth
                    variant="standard"
                    inputProps={{ 
                        maxLength: 100,
                        style: { textTransform: "uppercase" }
                    }}
                />
            </Grid>
            <Grid item xs={12} md={numGridColumn} >
                <FormControl error={mensajesForm.id_catalogo_genero} fullWidth variant="standard">
                    <InputLabel htmlFor="genero">Género</InputLabel>
                    <Select id="genero"
                        value={formData.id_catalogo_genero}
                        error={mensajesForm.id_catalogo_genero}
                        onChange={(event)=>handleChangue(event.target.value,'id_catalogo_genero')}
                    >
                        { 
                            listaCatalogos.generos.map(data => (
                                <MenuItem className='menu-item-form' key={data.id} value={data.id}>{data.etiqueta}</MenuItem>
                            ))
                        }
                    </Select>
                    { mensajesForm.id_catalogo_genero&&
                    <p className='nota_mensaje_error'>{mensajesForm.id_catalogo_genero}</p>
                    }
                </FormControl>
            </Grid>
            <Grid item xs={12} md={numGridColumn} >
                <FormControl error={mensajesForm.id_catalogo_estado} fullWidth variant="standard">
                    <InputLabel htmlFor="estado">Estado residencia</InputLabel>
                    <Select id="estado"
                        value={formData.id_catalogo_estado}
                        error={mensajesForm.id_catalogo_estado}
                        onChange={(event)=>handleChangue(event.target.value,'id_catalogo_estado')}
                    >
                        { 
                            listaCatalogos.estados.map(data => (
                                <MenuItem className='menu-item-form' key={data.id} value={data.id}>{data.etiqueta}</MenuItem>
                            ))
                        }
                    </Select>
                    { mensajesForm.id_catalogo_estado&&
                    <p className='nota_mensaje_error'>{mensajesForm.id_catalogo_estado}</p>
                    }
                </FormControl>
            </Grid>
            <Grid item xs={12} md={numGridColumn} >
                <TextField 
                    margin="dense"
                    id="edad"
                    label="Edad"
                    value={formData.edad}
                    onChange={(event)=>handleChangue(event.target.value,'edad')}
                    error={mensajesForm.edad}
                    helperText={mensajesForm.edad}
                    type="text"
                    fullWidth
                    variant="standard"
                    inputProps={{ 
                        maxLength:2
                    }}
                />
            </Grid>
            <Grid item xs={12} md={numGridColumn} >
                <TextField 
                    margin="dense"
                    id="codigoPostal"
                    label="Código Postal"
                    value={formData.codigo_postal}
                    onChange={(event)=>handleChangue(event.target.value,'codigo_postal')}
                    error={mensajesForm.codigo_postal}
                    helperText={mensajesForm.codigo_postal}
                    type="text"
                    fullWidth
                    variant="standard"
                    inputProps={{ 
                        maxLength:5
                    }}
                />
            </Grid>
            <Grid item xs={12} md={numGridColumn} >
                <TextField 
                    margin="dense"
                    id="telefonoCelular"
                    label="Teléfono celular (10 dígitos)"
                    value={formData.telefono_celular}
                    onChange={(event)=>handleChangue(event.target.value,'telefono_celular')}
                    error={mensajesForm.telefono_celular}
                    helperText={mensajesForm.telefono_celular}
                    type="text"
                    fullWidth
                    variant="standard"
                    inputProps={{ 
                        minLength:10,
                        maxLength:10,
                        onBlur:(event)=>validaCelulares(event,formData.telefono_celular_confirm)
                    }}
                />
            </Grid>
            <Grid item xs={12} md={numGridColumn} >
                <TextField 
                    margin="dense"
                    id="confirmCelular"
                    label="Confirmar teléfono celular (10 dígitos)"
                    value={formData.telefono_celular_confirm}
                    onChange={(event)=>handleChangue(event.target.value,'telefono_celular_confirm')}
                    error={mensajesForm.telefono_celular_confirm}
                    helperText={mensajesForm.telefono_celular_confirm}
                    type="text"
                    fullWidth
                    variant="standard"
                    ref={phoneRef}
                    inputProps={{ 
                        minLength:10,
                        maxLength:10,
                        onBlur:(event)=>validaCelulares(event,formData.telefono_celular)
                    }}
                />
            </Grid>
            <Grid item xs={12} md={numGridColumn} >
                <FormControl error={mensajesForm.id_catalogo_ocupacion} fullWidth variant="standard">
                    <InputLabel htmlFor="ocupacion">¿Actualmente te encuentras?</InputLabel>
                    <Select id="ocupacion"
                        value={formData.id_catalogo_ocupacion}
                        error={mensajesForm.id_catalogo_ocupacion}
                        onChange={(event)=>handleChangue(event.target.value,'id_catalogo_ocupacion')}
                    >
                        { 
                            listaCatalogos.ocupaciones.map(data => (
                                <MenuItem className='menu-item-form' key={data.id} value={data.id}>{data.etiqueta}</MenuItem>
                            ))
                        }
                    </Select>
                    { mensajesForm.id_catalogo_ocupacion&&
                    <p className='nota_mensaje_error'>{mensajesForm.id_catalogo_ocupacion}</p>
                    }
                </FormControl>
            </Grid>
            <Grid item xs={12} md={numGridColumn} >
                <FormControl error={mensajesForm.id_catalogo_estudio} fullWidth variant="standard">
                    <InputLabel htmlFor="nivelEstudios">¿Cuál es tu último nivel de estudios?</InputLabel>
                    <Select id="nivelEstudios"
                        value={formData.id_catalogo_estudio}
                        error={mensajesForm.id_catalogo_estudio}
                        onChange={(event)=>{handleChangue(event.target.value,'id_catalogo_estudio');}}
                        inputProps={{ 
                            onBlur:limpiaDatosUniversidad
                        }}
                    >
                        { 
                            listaCatalogos.niveles_estudio.map(data => (
                                <MenuItem className='menu-item-form' key={data.id} value={data.id}>{data.etiqueta}</MenuItem>
                            ))
                        }
                    </Select>
                    { mensajesForm.id_catalogo_estudio&&
                    <p className='nota_mensaje_error'>{mensajesForm.id_catalogo_estudio}</p>
                    }
                </FormControl>
            </Grid>
            { escuelaValidos.indexOf(formData.id_catalogo_estudio) !== -1 &&
            <>
            <Grid item xs={12} md={numGridColumn}>
                <TextField 
                    margin="dense"
                    id="fechaGraduacion"
                    label="Fecha estimada de Graduación"
                    value={formData.fecha_graduacion}
                    onChange={(event)=>handleChangue(event.target.value,'fecha_graduacion')}
                    error={mensajesForm.fecha_graduacion}
                    helperText={mensajesForm.fecha_graduacion}
                    type="date"
                    fullWidth
                    variant="standard"
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>
            <Grid item xs={12} md={numGridColumn} >
                <FormControl error={mensajesForm.id_catalogo_carrera} fullWidth variant="standard">
                    <InputLabel htmlFor="carrera">Carrera</InputLabel>
                    <Select id="carrera"
                        value={formData.id_catalogo_carrera}
                        error={mensajesForm.id_catalogo_carrera}
                        onChange={(event)=>handleChangue(event.target.value,'id_catalogo_carrera')}
                    >
                        { 
                            listaCatalogos.carreras.map(data => (
                                <MenuItem className='menu-item-form' key={data.id} value={data.id}>{data.etiqueta}</MenuItem>
                            ))
                        }
                    </Select>
                    { mensajesForm.id_catalogo_carrera&&
                    <p className='nota_mensaje_error'>{mensajesForm.id_catalogo_carrera}</p>
                    }
                </FormControl>
            </Grid>
            <Grid item xs={12} md={numGridColumn} >
                <FormControl fullWidth variant="standard" >
                    <Autocomplete
                    disablePortal
                    id="auto_complete"
                    options={ listaCatalogos.universidades.map(data => ( {
                        label:data.etiqueta, id:data.id
                        }
                    ))}
                    onChange={(event,value)=>{handleChangue(value.id,'id_catalogo_universidad')}}
                    getOptionSelected = {()=>{}}
                    renderInput={(params) => <TextField {...params} fullWidth variant="standard" margin="dense" label="¿A que universidad perteneces?" />}
                    />
                    { mensajesForm.id_catalogo_universidad&&
                    <p className='nota_mensaje_error'>{mensajesForm.id_catalogo_universidad}</p>
                    }
                </FormControl>
            </Grid>
            { formData.id_catalogo_universidad === id_otro &&
            <Grid item xs={12} md={numGridColumn} >
                <TextField 
                    margin="dense"
                    id="otraUniversidad"
                    label="Otra Universidad"
                    value={formData.otra_universidad}
                    onChange={(event)=>handleChangue(event.target.value,'otra_universidad',true)}
                    error={mensajesForm.otra_universidad}
                    helperText={mensajesForm.otra_universidad}
                    type="text"
                    fullWidth
                    variant="standard"
                    inputProps={{ 
                        maxLength:30
                    }}
                />
            </Grid>
            }
            </>
            }
            <Grid item xs={12} md={numGridColumn} >
                <FormControl error={mensajesForm.id_catalogo_promedio} fullWidth variant="standard">
                    <InputLabel htmlFor="promedioEstudios">¿Promedio General de tu último nivel de estudios?</InputLabel>
                    <Select id="promedioEstudios"
                        value={formData.id_catalogo_promedio}
                        error={mensajesForm.id_catalogo_promedio}
                        onChange={(event)=>handleChangue(event.target.value,'id_catalogo_promedio')}
                    >
                        { 
                            listaCatalogos.promedios_generales.map(data => (
                                <MenuItem className='menu-item-form' key={data.id} value={data.id}>{data.etiqueta}</MenuItem>
                            ))
                        }
                    </Select>
                    { mensajesForm.id_catalogo_promedio&&
                    <p className='nota_mensaje_error'>{mensajesForm.id_catalogo_promedio}</p>
                    }
                </FormControl>
            </Grid>
            <Grid item xs={12} md={numGridColumn} >
                <FormControl error={mensajesForm.id_catalogo_grupo_poblacion} fullWidth variant="standard">
                    <InputLabel htmlFor="grupoPoblacional">¿Perteneces a alguno de los siguientes grupos poblacionales?</InputLabel>
                    <Select id="grupoPoblacional"
                        value={formData.id_catalogo_grupo_poblacion}
                        error={mensajesForm.id_catalogo_grupo_poblacion}
                        onChange={(event)=>handleChangue(event.target.value,'id_catalogo_grupo_poblacion')}
                    >
                        { 
                            listaCatalogos.comunidades.map(data => (
                                <MenuItem className='menu-item-form' key={data.id} value={data.id}>{data.etiqueta}</MenuItem>
                            ))
                        }
                    </Select>
                    { mensajesForm.id_catalogo_grupo_poblacion&&
                    <p className='nota_mensaje_error'>{mensajesForm.id_catalogo_grupo_poblacion}</p>
                    }
                </FormControl>
            </Grid>
            <Grid item xs={12} md={numGridColumn} >
                <FormControl error={mensajesForm.id_catalogo_primer_certificado} fullWidth variant="standard">
                    <InputLabel htmlFor="primerCertificado">¿Qué Certificado de Carrera Google deseas preparar primero?</InputLabel>
                    <Select id="primerCertificado"
                        value={formData.id_catalogo_primer_certificado}
                        error={mensajesForm.id_catalogo_primer_certificado}
                        onChange={(event)=>handleChangue(event.target.value,'id_catalogo_primer_certificado')}
                    >
                        { 
                            listaCatalogos.certificados.map(data => (
                                <MenuItem className='menu-item-form' key={data.id} value={data.id}>{data.etiqueta}</MenuItem>
                            ))
                        }
                    </Select>
                    { mensajesForm.id_catalogo_primer_certificado&&
                    <p className='nota_mensaje_error'>{mensajesForm.id_catalogo_primer_certificado}</p>
                    }
                </FormControl>
            </Grid>
            <Grid item xs={12} md={numGridColumn} >
                <FormControl error={mensajesForm.id_catalogo_razon_primer_cert} fullWidth variant="standard">
                    <InputLabel htmlFor="razonPrepararCert">¿Por qué deseas preparar este Certificado?</InputLabel>
                    <Select id="razonPrepararCert"
                        value={formData.id_catalogo_razon_primer_cert}
                        error={mensajesForm.id_catalogo_razon_primer_cert}
                        onChange={(event)=>handleChangue(event.target.value,'id_catalogo_razon_primer_cert')}
                    >
                        { 
                            listaCatalogos.preparaciones_certificado.map(data => (
                                <MenuItem className='menu-item-form' key={data.id} value={data.id}>{data.etiqueta}</MenuItem>
                            ))
                        }
                    </Select>
                    { mensajesForm.id_catalogo_razon_primer_cert&&
                    <p className='nota_mensaje_error'>{mensajesForm.id_catalogo_razon_primer_cert}</p>
                    }
                </FormControl>
            </Grid>
            <Grid item xs={12} md={numGridColumn} >
                <FormControl error={mensajesForm.id_catalogo_dedicacion} fullWidth variant="standard">
                    <InputLabel htmlFor="horasDedicar">¿Cuántas horas a la semana le puedes dedicar al curso?</InputLabel>
                    <Select id="horasDedicar"
                        value={formData.id_catalogo_dedicacion}
                        error={mensajesForm.id_catalogo_dedicacion}
                        onChange={(event)=>handleChangue(event.target.value,'id_catalogo_dedicacion')}
                    >
                        { 
                            listaCatalogos.horas_dedicar.map(data => (
                                <MenuItem className='menu-item-form' key={data.id} value={data.id}>{data.etiqueta}</MenuItem>
                            ))
                        }
                    </Select>
                    { mensajesForm.id_catalogo_dedicacion&&
                    <p className='nota_mensaje_error'>{mensajesForm.id_catalogo_dedicacion}</p>
                    }
                </FormControl>
            </Grid>
            <Grid item xs={12} md={numGridColumn} >
                <FormControl error={mensajesForm.id_catalogo_motivo} fullWidth variant="standard">
                    <InputLabel htmlFor="motivoSolicitud">¿Cuál es el motivo por el que solicitas una beca?</InputLabel>
                    <Select id="motivoSolicitud"
                        value={formData.id_catalogo_motivo}
                        error={mensajesForm.id_catalogo_motivo}
                        onChange={(event)=>handleChangue(event.target.value,'id_catalogo_motivo')}
                    >
                        { 
                            listaCatalogos.motivos_solicitud.map(data => (
                                <MenuItem className='menu-item-form' key={data.id} value={data.id}>{data.etiqueta}</MenuItem>
                            ))
                        }
                    </Select>
                    { mensajesForm.id_catalogo_motivo&&
                    <p className='nota_mensaje_error'>{mensajesForm.id_catalogo_motivo}</p>
                    }
                </FormControl>
            </Grid>
            <Grid item xs={12} md={numGridColumn} >
                <FormControl error={mensajesForm.id_catalogo_ingresos} fullWidth variant="standard">
                    <InputLabel htmlFor="ingresoMensual">¿Cuál es el ingreso mensual de tu familia?</InputLabel>
                    <Select id="ingresoMensual"
                        value={formData.id_catalogo_ingresos}
                        error={mensajesForm.id_catalogo_ingresos}
                        onChange={(event)=>handleChangue(event.target.value,'id_catalogo_ingresos')}
                    >
                        { 
                            listaCatalogos.ingresos_mensuales.map(data => (
                                <MenuItem className='menu-item-form' key={data.id} value={data.id}>{data.etiqueta}</MenuItem>
                            ))
                        }
                    </Select>
                    { mensajesForm.id_catalogo_ingresos&&
                    <p className='nota_mensaje_error'>{mensajesForm.id_catalogo_ingresos}</p>
                    }
                </FormControl>
            </Grid>
            { preClaveCorrecta &&
            <Grid item xs={12} md={numGridColumn} >
                <FormControl error={mensajesForm.id_catalogo_difusion} fullWidth variant="standard">
                    <InputLabel htmlFor="medioDifusion">¿Cómo te enteraste de este programa?</InputLabel>
                    <Select id="medioDifusion" disabled={preClaveCorrecta}
                        value={formData.id_catalogo_difusion}
                        error={mensajesForm.id_catalogo_difusion}
                        onChange={(event) => {handleChangue(event.target.value,'id_catalogo_difusion');}}
                        inputProps={{ 
                            onBlur:limpiaDatosOrganizacion
                        }}
                    >
                        { 
                            listaCatalogos.medios_difusion.map(data => (
                                <MenuItem className='menu-item-form' key={data.id} value={data.id}>{data.etiqueta}</MenuItem>
                            ))
                        }
                    </Select>
                    { mensajesForm.id_catalogo_difusion&&
                    <p className='nota_mensaje_error'>{mensajesForm.id_catalogo_difusion}</p>
                    }
                </FormControl>
            </Grid>
            }
            { !preClaveCorrecta &&
            <Grid item xs={12} md={numGridColumn} >
                <FormControl error={mensajesForm.id_catalogo_difusion} fullWidth variant="standard">
                    <InputLabel htmlFor="medioDifusion2">¿Cómo te enteraste de este programas?</InputLabel>
                    <Select id="medioDifusion2" disabled={preClaveCorrecta}
                        value={formData.id_catalogo_difusion}
                        error={mensajesForm.id_catalogo_difusion}
                        onChange={(event) => {handleChangue(event.target.value,'id_catalogo_difusion');}}
                        inputProps={{ 
                            onBlur:limpiaDatosOrganizacion
                        }}
                    >
                        { 
                            listaCatalogos.medios_difusion.map(data => (
                                <MenuItem className='menu-item-form' key={data.id} value={data.id}>{data.etiqueta}</MenuItem>
                            ))
                        }
                    </Select>
                    { mensajesForm.id_catalogo_difusion&&
                    <p className='nota_mensaje_error'>{mensajesForm.id_catalogo_difusion}</p>
                    }
                </FormControl>
            </Grid>
            }
            { formData.id_catalogo_difusion === id_orgnazacion_select &&
            <Grid item xs={12} md={numGridColumn} >
                <FormControl error={mensajesForm.id_catalogo_organizacion} fullWidth variant="standard">
                    <InputLabel htmlFor="organizacion">Organización</InputLabel>
                    <Select id="organizacion" disabled={preClaveCorrecta}
                        value={formData.id_catalogo_organizacion}
                        error={mensajesForm.id_catalogo_organizacion}
                        onChange={(event)=>handleChangue(event.target.value,'id_catalogo_organizacion')}
                    >
                        { 
                            listaCatalogos.organizaciones.map(data => (
                                <MenuItem className='menu-item-form' key={data.id} value={data.id}>{data.etiqueta}</MenuItem>
                            ))
                        }
                    </Select>
                    { mensajesForm.id_catalogo_organizacion&&
                    <p className='nota_mensaje_error'>{mensajesForm.id_catalogo_organizacion}</p>
                    }
                </FormControl>
            </Grid>
            }
            <Grid item xs={12} md={6} >
                <FormControl error={mensajesForm.acceso_computadora} fullWidth variant="standard">
                    <InputLabel htmlFor='accesoComputadora'>¿Tienes acceso a una computadora, tablet o dispositivo electrónico?</InputLabel>
                </FormControl>
            </Grid>
            <Grid item xs={12} md={6} >
                <FormControl fullWidth variant="standard">
                    <RadioGroup id='accesoComputadora' row 
                        value={formData.acceso_computadora}
                        onChange={(event)=>handleChangue(event.target.value,'acceso_computadora')}
                    >
                        <FormControlLabel value="1" control={<Radio />} label="Sí" />
                        <FormControlLabel value="0" control={<Radio />} label="No" />
                    </RadioGroup>
                    { mensajesForm.acceso_computadora&&
                    <p className='nota_mensaje_error'>{mensajesForm.acceso_computadora}</p>
                    }
                </FormControl>
            </Grid>
            <Grid item xs={12} md={6} >
                <FormControl error={mensajesForm.propia_computadora} fullWidth variant="standard">
                    <InputLabel htmlFor='computadoraPropia'>¿Es propio?</InputLabel>
                </FormControl>
            </Grid>
            <Grid item xs={12} md={6} >
                <FormControl fullWidth variant="standard">
                    <RadioGroup id='computadoraPropia' row
                        value={formData.propia_computadora}
                        onChange={(event)=>handleChangue(event.target.value,'propia_computadora')}
                    >
                        <FormControlLabel value="1" control={<Radio />} label="Sí" />
                        <FormControlLabel value="0" control={<Radio />} label="No" />
                    </RadioGroup>
                    { mensajesForm.propia_computadora&&
                    <p className='nota_mensaje_error'>{mensajesForm.propia_computadora}</p>
                    }
                </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
                <FormControl error={mensajesForm.acceso_internet} fullWidth variant="standard">
                    <InputLabel htmlFor='accesoInternet'>¿Cuentas con acceso a internet en casa?</InputLabel>
                </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
                <FormControl fullWidth variant="standard">
                    <RadioGroup id='accesoInternet' row
                        value={formData.acceso_internet}
                        onChange={(event)=>handleChangue(event.target.value,'acceso_internet')}
                    >
                        <FormControlLabel value="1" control={<Radio />} label="Sí" />
                        <FormControlLabel value="0" control={<Radio />} label="No" />
                    </RadioGroup>
                    { mensajesForm.acceso_internet&&
                    <p className='nota_mensaje_error'>{mensajesForm.acceso_internet}</p>
                    }
                </FormControl>
            </Grid>
            {/*
            <Grid item xs={12} md={numGridColumn} >
                <FormControl error={mensajesForm.file_identificacion} fullWidth variant="standard">
                    <InputLabel htmlFor="identificacion">Identificación oficial con fotografía</InputLabel>
                    <Input disabled
                        id="identificacion"
                        value={nombreArchivos.nombreIdenticacion}
                        endAdornment={
                        <InputAdornment position="end">
                            <IconButton color="primary" aria-label="upload picture" onClick={()=>setDialogoIdentificacion(true)}>
                                <FileUploadIcon />
                            </IconButton>
                        </InputAdornment>
                        }
                    />
                    { mensajesForm.file_identificacion&&
                    <p className='nota_mensaje_error'>{mensajesForm.file_identificacion}</p>
                    }
                    <DropzoneDialog
                            open={dialogoIdentificacion}
                            onSave={handleSaveIdentificacion}
                            onClose={()=>setDialogoIdentificacion(false)}
                            acceptedFiles={['image/jpeg', 'image/png', 'image/bmp', 'application/pdf']}
                            showPreviews={true}
                            maxFileSize={5000000}
                            dropzoneText="Carga tu identificación"
                            filesLimit={1}
                            dialogTitle=""
                            cancelButtonText="Cancelar"
                            submitButtonText="Subir"
                            previewText="Verificar"
                    />
                    <FormHelperText id="helper_comprobante_estudios">
                        Formato jpeg, png, pdf y límite de 6MB.
                    </FormHelperText>
                </FormControl>
            </Grid>
            <Grid item xs={12} md={numGridColumn}>
                <FormControl 
                    fullWidth
                    error={mensajesForm.file_estudios}
                    variant="standard">
                    <InputLabel htmlFor="comprobanteEstudios">Comprobante de tu último nivel de estudios</InputLabel>
                    <Input disabled
                        id="comprobanteEstudios"
                        value={nombreArchivos.nombreComprobante}
                        endAdornment={
                        <InputAdornment position="end">
                            <IconButton color="primary" aria-label="upload picture" onClick={()=>setDialogoComprobante(true)}>
                                <FileUploadIcon />
                            </IconButton>
                        </InputAdornment>
                        }
                    />
                    { mensajesForm.file_estudios&&
                    <p className='nota_mensaje_error'>{mensajesForm.file_estudios}</p>
                    }
                    <DropzoneDialog
                        open={dialogoComprobante}
                        onSave={handleSaveComprobante}
                        onClose={()=>setDialogoComprobante(false)}
                        acceptedFiles={['image/jpeg', 'image/png', 'image/bmp','application/pdf']}
                        showPreviews={true}
                        maxFileSize={5000000}
                        dropzoneText="Carga tu comprobante"
                        dialogTitle=""
                        filesLimit={1}
                        cancelButtonText="Cancelar"
                        submitButtonText="Subir"
                        previewText="Verificar"
                    />
                    <FormHelperText id="helper_comprobante_estudios">
                        Formato jpeg, png, pdf y límite de 6MB.
                        <br></br>
                        Si no cuentas con un comprobante de tu último nivel de estudio, favor de compartir una carta bajo protesta de decir 
                        verdad indicando la universidad, carrera, promedio y fechas de inicio y termino
                    </FormHelperText>
                </FormControl>
            </Grid>
                */}
            <Grid item xs={12} md={12}>
                <b>Para concluir tu solicitud debes seleccionar todas las casillas y dar clic en "Guardar y enviar".</b>
                <FormGroup className='form-cheks'>
                    { preClaveCorrecta && <FormControlLabel control={<Checkbox />} checked={checksAviso.datosPersonalesOrganizacion} onChange={(event)=>handleChangueCheck(event,"datosPersonalesOrganizacion")} label="Acepto que mis datos personales y de avances en Coursera sean compartidos con la Organizacion Aliada que me recomendó, y sean tratados conforme a lo señalado en el Aviso de Privacidad presente."/>}
                    <FormControlLabel control={<Checkbox />} checked={checksAviso.informacionProporcionada} onChange={(event)=>handleChangueCheck(event,"informacionProporcionada")} label="Declaro que toda la información proporcionada es verdadera, completa,  y correcta.  En caso de ser necesario, INROADS podrá solicitar la documentación comprobatoria."/>
                    <FormControlLabel control={<Checkbox />} checked={checksAviso.cumplimientoTiempos} onChange={(event)=>handleChangueCheck(event,"cumplimientoTiempos")} label="Me comprometo a concluir en tiempo y  forma de acuerdo a lo establecido, en caso de ser seleccionad@ para una beca del programa de Certificados de Carrera de Google"/>
                    <FormControlLabel control={<Checkbox />} checked={checksAviso.tratamientoDatos} onChange={(event)=>handleChangueCheck(event,"tratamientoDatos")} label="Acepto que mis datos personales de identificación, de contacto, académicos y financieros, sean tratados conforme a lo señalado en el Aviso de Privacidad presente"/>
                    <FormControlLabel control={<Checkbox />} checked={checksAviso.avisoPrivacidad} onChange={(event)=>handleChangueCheck(event,"avisoPrivacidad")} label={<a href='https://inroads.org.mx/aviso-de-privacidad/' target="_blank" rel="noopener noreferrer">Aviso de privacidad</a>} />
                </FormGroup>
            </Grid>
            {fomularioInvalido &&
            <Grid item xs={12} md={12}>
                <Alert severity="warning">Favor de revisar el formulario, existen campos vacios o invalidos.</Alert>
            </Grid>
            }
            <Grid item xs={12} md={12} className="grid-boton">
                <Button 
                    variant="contained"
                    fullWidth
                    disabled={!checksCompletos}
                    onClick={()=>{validaEnviaForm()}}
                    className={checksCompletos ? "button_ovalado boton-form" : "button_ovalado_disabled boton-form"}
                    style={{textTransform: 'None',margin: "auto"}}
                >
                        Guardar y enviar
                </Button>
            </Grid>
        </Grid>
        <Modal 
          open={modalConfirmacion}
          onClose={()=>{setModalConfirmacion(false); updateEstatusBeca(2);}} 
          id="modal-form"
          >
            <div className='form-modal'>
                <div className='form-modal_img'>
                    <img src={PopForm} style={{width:'80%'}} alt="compartir" />
                </div>
                <div className='form-modal_titulo'>
                    <p>Recibirás 2 correos electrónicos:</p>
                </div>
                <div className='form-modal_texto'>
                    <p>
                        Uno por parte de INROADS (sistemas@inroads.org.mx) <br />
                        Otro de Coursera (aparece como INROADS + Coursera)
                        mediante el cual debes activar tu beca, inscribiéndote a 
                        tu Certificado (Revisa cómo aquí).
                    </p>
                </div>
            </div>
          </Modal> 
          <Backdrop className={classes.backdrop} open={bloqueo}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
    )
}