import * as React from "react";
import "./index.css";
import { ButtonAppBar } from "./MyAppBar";

export default function App() {
  return (
    <div className="App">
      <ButtonAppBar />
    </div>
  );
}
