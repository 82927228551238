import React, { Component } from 'react';
import Stack from '@mui/material/Stack';
import logo from "./inroads_isologo.svg";
import "./index.css";
import logoL from "./linea_logos.svg";
import IconButton from '@mui/material/IconButton';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';

class Footer extends Component {
    render() {
        return(
            <>
            <div className="footer footer-no-mobil" style={{marginTop:"40px", height:"500px"}}>
                <Stack spacing={2} direction="row" justifyContent="center" alignItems="center">
                <p>
                <a href="https://inroads.org.mx/" target="_blank" rel="noopener noreferrer" style={{color: "white"}}>
                    <img src={logo} alt="Inroads" className='logo' style={{minHeight: "120px"}}/>
                </a>
                </p>
                    <img src={logoL} alt="Line" className='logoL' />

                    <IconButton aria-label="delete" size="large" style={{color:'white'}}>
                        <a href="https://www.instagram.com/inroads_mx/?hl=es-la"
                        style={{color: "inherit"}}
                        target="_blank"
                        rel="noopener noreferrer" 
                        ><InstagramIcon fontSize="inherit" /></a>
                    </IconButton>
                    <IconButton aria-label="delete" size="large" style={{color:'white'}}>
                        <a href="https://www.facebook.com/INROADS.Mexico"
                        style={{color: "inherit"}}
                        target="_blank"
                        rel="noopener noreferrer" 
                        ><FacebookIcon fontSize="inherit" /></a>
                    </IconButton>
                    <IconButton aria-label="delete" size="large" style={{color:'white'}}>
                        <a href="https://www.youtube.com/c/INROADSdeM%C3%A9xicoAC"
                        style={{color: "inherit"}}
                        target="_blank"
                        rel="noopener noreferrer" 
                        ><YouTubeIcon fontSize="inherit" /></a>
                    </IconButton>
                    <IconButton aria-label="delete" size="large" style={{color:'white'}} to="https://www.linkedin.com/in/inroadsdemexico/#experience-section">
                        <a href="https://www.linkedin.com/in/inroadsdemexico/#experience-section"
                        style={{color: "inherit"}}
                        target="_blank"
                        rel="noopener noreferrer" 
                        ><LinkedInIcon fontSize="inherit" /></a>
                    </IconButton>
                    <img src={logoL} alt="Line" className='logoL' />
                    <div>
                        <div style={{ color:'white', textAlign:'left'}}>
                            <a href="https://inroads.org.mx/aviso-de-privacidad/" style={{color:"white"}} target="_blank" rel="noopener noreferrer"> <p>Aviso de privacidad</p> </a>
                        </div>
                        <div style={{ color:'white', textAlign:'left'}}>
                            <p>becas.certificado.google@inroads.org.mx</p>
                        </div>
                        <div style={{ color:'white', textAlign:'left'}}>
                            <p>&copy;2022 INROADS de México. Todos los derechos reservados</p>
                        </div>
                    </div> 
                </Stack>
            </div>
            <div  className="footer footer-mobil" style={{marginTop:"40px", height:'540px', textAlign:'center'}}>
                <div style={{marginTop:"20px"}}>
                    <a href="https://inroads.org.mx/" style={{color: "white"}} target="_blank" rel="noopener noreferrer">
                        <img src={logo} alt="Inroads" width={100} className='logo' style={{minHeight: "120px"}}/>
                    </a>
                </div>
                <div>
                    <div style={{ color:'white'}}>
                        <a href="https://inroads.org.mx/aviso-de-privacidad/" style={{color:"white"}} target="_blank" rel="noopener noreferrer"> <p>Aviso de privacidad</p> </a>
                    </div>
                    <div style={{ color:'white'}}>
                        <p>inroads.mexico@inroads.org.mx</p>
                    </div>
                    <div style={{ color:'white'}}>
                        <p>&copy;2022 INROADS de México. Todos los derechos reservados</p>
                    </div>
                 </div> 
                 <div style={{display:'grid'}}>
                    <IconButton aria-label="delete" size="large" style={{color:'white'}}>
                        <a href="https://www.instagram.com/inroads_mx/?hl=es-la"
                        style={{color: "inherit"}}
                        target="_blank"
                        rel="noopener noreferrer" 
                        ><InstagramIcon fontSize="inherit" /></a>
                    </IconButton>
                    <IconButton aria-label="delete" size="large" style={{color:'white'}}>
                        <a href="https://www.facebook.com/INROADS.Mexico"
                        style={{color: "inherit"}}
                        target="_blank"
                        rel="noopener noreferrer" 
                        ><FacebookIcon fontSize="inherit" /></a>
                    </IconButton>
                    <IconButton aria-label="delete" size="large" style={{color:'white'}}>
                        <a href="https://www.youtube.com/c/INROADSdeM%C3%A9xicoAC"
                        style={{color: "inherit"}}
                        target="_blank"
                        rel="noopener noreferrer" 
                        ><YouTubeIcon fontSize="inherit" /></a>
                    </IconButton>
                    <IconButton aria-label="delete" size="large" style={{color:'white'}} to="https://www.linkedin.com/in/inroadsdemexico/#experience-section">
                        <a href="https://www.linkedin.com/in/inroadsdemexico/#experience-section"
                        style={{color: "inherit"}}
                        target="_blank"
                        rel="noopener noreferrer" 
                        ><LinkedInIcon fontSize="inherit" /></a>
                    </IconButton>
                 </div>
            </div>
            </>
        )
    }
}

export default Footer  