import React from 'react';
import RedesSociales from './RedesSociales';
import "../css/card.css";
import comparte from '../img/comparte.svg';

export default function ComparteRedes ({estatusBeca}) {
    return(
      <div className='container-redes'>
        <div className='container-redes_img'>
            <img src={comparte} style={{width:'100%'}} alt="compartir" />
        </div>
        
        <div  className='container-redes_mensaje'>
          { estatusBeca === 2 &&
          <p>Te invitamos a seguirnos en nuestras redes sociales para enterarte de todos los
            beneficios que podemos ofrecerte.
          </p>
          }
          { estatusBeca > 2 &&
          <p>Comparte esta gran noticia e invita a tus amigos a solicitar una beca también.</p>
          }
          <RedesSociales estatusBeca={estatusBeca} />
        </div>
      </div>
    )
}