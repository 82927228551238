import React from 'react';
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton } from "react-share";
import FacebookIcon from '../img/redes/facebook.svg';
import TwitterIcon from '../img/redes/twitter.svg';
import LinkedInIcon from '../img/redes/linkedin.svg';
import '../css/redes.css'

export default function RedesSociales({estatusBeca}) {
    return(
        <>
        { estatusBeca === 2 &&
        <div className='content-redes'>
             <a href="https://www.facebook.com/INROADS.Mexico" target="_blank" rel="noopener noreferrer"><img src={FacebookIcon} alt="face" /></a>
             <a href="https://www.linkedin.com/company/inroads-de-m-xico-a-c-/" target="_blank" rel="noopener noreferrer"><img src={LinkedInIcon} alt="linkedin" /></a>
             <a href="https://twitter.com/INROADS_MX" target="_blank" rel="noopener noreferrer"><img src={TwitterIcon} alt="twitter" /></a>
        </div>
        }
        { estatusBeca > 2 &&
        <div className='content-redes'>
            <FacebookShareButton
                url={"https://avid-wavelet-344214.wl.r.appspot.com/"}
                quote={"Obtén tu beca con INROADS y Google "}
                description={"esto es la descripcion"}
            >
                {/* <FacebookIcon color='disabled' style={{ fontSize: 25 }}/> */}
                <img src={FacebookIcon} alt="compartir" />
            </FacebookShareButton>
            <LinkedinShareButton
                title={"Obtén tu beca con INROADS y Google "}
                url={"https://avid-wavelet-344214.wl.r.appspot.com/"}
                source={"INROADS"}
            >
                <img src={LinkedInIcon} alt="compartir" />
            </LinkedinShareButton>
            <TwitterShareButton
                title={"Obtén tu beca con INROADS y Google"}
                url={"https://avid-wavelet-344214.wl.r.appspot.com/"}
            >
                <img src={TwitterIcon} alt="compartir" />
            </TwitterShareButton>
        </div>
        }
        </>
    );
}