import React from 'react';
import ListCerts from './components/ListCerts';

export default function SeccionBecaOrtogada() {

    const certAssign = JSON.parse(sessionStorage.getItem("certificados"));

    const listCertsData = certAssign.map(certAux => ({
        tituloCert:certAux.certificado.titulo_certificado,
        textoCert:`No olvides activar tu Certificado mediante la invitación personalizada que recibiste de Coursera, vía correo (revisa tus distintas bandejas).
        
        Luego, es importante que ingreses a Coursera, desde AQUÍ (portal INROADS) para estar al tanto de todas las novedades que tendremos para ti.`,
        imgCert:certAux.certificado.url_imagen,
        colorCert:certAux.certificado.hex_color,
        estatusCertificado:4,
    }));
    
    return (
        <div className='conatiner-beca-otorgada'>
            <div className='container-intro'>
                <div className='container-intro_titulo'>
                    <p>¡Gracias por solicitar una Beca con INROADS para los Certificados de Caerrera <br />
                    de Google en español! <br />  <br />
                    ¡Te hemos otorgado una beca!  <br />
                    ¡Muchas Felicidades!
                    </p>
                </div>
                <div className='container-intro_texto'>
                    <p>
                        Ahora, comienza exitosamente tu camino de aprendizaje.  <br />
                        Únete a tu Certificado en Coursera. <b><a href='https://drive.google.com/file/d/1ipCpzl1KvMfeqCkQIC-c7MPLC0J8UWGN/view?usp=sharing' target='_blank'>Revisa Aquí</a></b>
                    </p>
                </div>
            </div>
            <div className='container-video-beca-aceptada'>
                <div>
                    <p>
                        Las recompensas al finalizar pueden ser enormes  <br />
                        <b>¡Empieza y alcanza tus metas al igual que Ricardo!</b>
                    </p>
                </div>
                
                <iframe src="https://www.youtube.com/embed/tnRDkWDKImg?si=G8pA6A56kJWJsXTk" 
                    title="YouTube video player" frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    allowfullscreen>
                </iframe>
            </div>
            <ListCerts listCertsData={listCertsData} />
        </div>
    )
};
