import React,{useState, useEffect} from 'react';
import axios from 'axios';
import { ParallaxProvider } from 'react-scroll-parallax';
import { ParallaxBanner } from 'react-scroll-parallax';
import "../css/parallax.css";
import BasicTimeline from '../Timeline';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import 'bootstrap/dist/css/bootstrap.min.css';
import Carousel from 'react-bootstrap/Carousel';


const ParallaxImage = ({estatusBeca, despliegue}) => {
  const rutaImg = estatusBeca !== 2 ? './noticias_header_girl.png' : './header_01.jpg';
  const [imgUrl, setImgUrl] = useState([]);
  const [descagaCorrecta, setdescagaCorrecta] = useState(false);

  useEffect(() => {
    axios.get('https://prod-backend-dot-driven-presence-373215.uc.r.appspot.com/api/v1/frontend/noticias/?despliegue='+despliegue)
    .then((response) => {
      setImgUrl(response.data.conf_noticias);
      setdescagaCorrecta(true);
    })
    .catch((error) => {
      setdescagaCorrecta(false);
      console.error("Error en la petición ",error);
    });
  }, []);

  const ParallaxItem = ({img, text}) => (
      <>
        <div className='parallax-none'>
            <img src={img}/>
            {text &&
            <span>
               <div dangerouslySetInnerHTML={{ __html: text}} />
            </span>
            }
        </div>
      </>
  );


    return (
      <>
      { descagaCorrecta ? 
      <Carousel>
        {
          imgUrl.map( img => (
            <Carousel.Item> 
              {
                img.url_link ? 
                <a href={img.url_link} target="_blank" without rel="noopener noreferrer"><ParallaxItem img={img.url_noticia} text={img.texto_img}/></a> :
                <ParallaxItem img={img.url_noticia} text={img.texto_img}/>
              }
            </Carousel.Item>
          ))
        }
      </Carousel> :
        <>
        { estatusBeca !== 2 ?
        <ParallaxItem img={rutaImg} 
        text={"<b>Comienza tu camino al éxito</b><br/><span>Obtén una beca INROADS para los Certificados de Carrera de Google.</span>"} /> :
        <ParallaxItem img={rutaImg} 
        text={"Certificados de Carrera de Google. <br/><b>Aquí inicia tu camino al éxito</b></span>"} />
        }
        </> 
        
      }
    </>
    );
};

export default ParallaxImage