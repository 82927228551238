import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import headerInroads from "./img/inroads_becas_email_header.jpg";
import headerInroadsMobil from "./img/inroads_becas_email_header_mobil.jpg";
import ResponsiveDialog from './DailogRegister';
import SignUp from "./SignIn";
import SessionContex from './provider/SessionContex';
import "./css/appBar.css";

export function ButtonAppBar() {
 
  return (
    <>
    <SessionContex.Consumer>
    { (context) => (
      <div className="appbar">
        <div className="header-logo">
          <img src={headerInroadsMobil} alt="Inroads"/>
        </div>
        <div className="header-nav">
          <a href="#list=certs">Certificados disponibles</a>
          <a href="#req">Requisitos</a>
          <a href="#ask-freq">Preguntas frecuentes</a>
        </div>
        <div className="header-boton">
          {/* <ResponsiveDialog /> */}
          <a className="boton-aplica-ref" href="#seccion-aplica">Aplica</a>
          <SignUp context={context}></SignUp>
        </div>
      </div>
      )
    }
    </SessionContex.Consumer>
    </>
  );
}
