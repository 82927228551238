import React, {useEffect,useState} from 'react';
import axios from 'axios';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TransferCerts from './components/TransferList';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';


export default function RelPeriodCert() {
  
  const [periodo, setPeriodo] = useState(0);
  const [listPeriodos, setListPeriodos] = useState([]);
  const [listCertAsignados, setListCertAsignados] = useState([]);
  const [listCertNoAsignados, setlistCertNoAsignados] = useState([]);
  const [listCerts, setListCerts] = useState([]);

  const [refrescar, setRefrescar] = useState(false);

  // Para control de mensajes de exito y error
  const [mensaje, setMensaje] = useState({
    mostrarMensaje: false,
    textoMensaje:"Se actualizo de manera correcta",
    tipoMensaje:"success"
  });
  // Fin control de mensajes

  var listCertAsignadosAux;
  var listCertNoAsignadosAux;

  useEffect(() => {
    limpiarDatos();
    axios.get('https://prod-backend-dot-driven-presence-373215.uc.r.appspot.com/api/v1/admin/relationship_periodo_certificados_read/',
    { headers: {"Authorization" : `Bearer ${sessionStorage.getItem('token')}`}})
    .then((response) => {
      setListPeriodos(response.data.lista_periodos);

      const listCertAux = response.data.certificados;
      const listCertNew = [];
      listCertAux.forEach(cert => {
        listCertNew.push({id:cert.id_certificado,name:cert.titulo_certificado});
      });
      setListCerts(listCertNew);
    })
    .catch((error) => {
        console.error(error);
    });
  }, [refrescar]);

  const handleChanguePeriodo = (idPeriodo) => {
    if(idPeriodo > 0) {
      const periodoAux = listPeriodos.find( per => per.id_periodos_becas === idPeriodo);
      const listIdCerts = [];
      listCerts.forEach(certAux => {listIdCerts.push(certAux.id)});

      const listIdCertNoAsignados = []
      listIdCerts.forEach(idCert => {
        if(periodoAux.id_certificados.indexOf(idCert) === -1) {
          listIdCertNoAsignados.push(idCert);
        } 
      });
      
      setPeriodo(idPeriodo);
      setListCertAsignados(periodoAux.id_certificados);
      setlistCertNoAsignados(listIdCertNoAsignados);

    } else {
      setRefrescar(!refrescar);
    }
  }

  const getListaCerts = (listNoAsigCert, listAsignCert) => {
    listCertAsignadosAux = listAsignCert;
    listCertNoAsignadosAux = listNoAsigCert;
  }

  const limpiarDatos = () => {
    setPeriodo(0);
    setListPeriodos([]);
    setListCertAsignados([])
    setlistCertNoAsignados([])
    setListCerts([]);
    listCertAsignadosAux = null;
    listCertNoAsignadosAux = null;
  }

  const guardarDatos = () => {
    const infoRelPeriodoCert = {
      id_periodo_becas:periodo,
      add_certificados:listCertAsignadosAux,
      del_certificados:listCertNoAsignadosAux,
    }
    axios.post('https://prod-backend-dot-driven-presence-373215.uc.r.appspot.com/api/v1/admin/modify_relationship/',infoRelPeriodoCert,
    { headers: {"Authorization" : `Bearer ${sessionStorage.getItem('token')}`}})
      .then(res => {
        if(res.data.relationship ==='ok') {
          setRefrescar(!refrescar);
          setMensaje({
              mostrarMensaje:true,
              textoMensaje:"Se guardo correctamente la información.",
              tipoMensaje:"success"
          });
      } else {
          setMensaje({
              mostrarMensaje:true,
              textoMensaje:"Se produjo un error al guardar la información: "+res.data.relationship,
              tipoMensaje:"error"
          });
      }
      })
      .catch(error => {
        setMensaje({
          mostrarMensaje:true,
          textoMensaje:"Se produjo un error al guardar la información.",
          tipoMensaje:"error"
        });
        console.error(error)
      })
  }

  return (
    <>
    <h3>Relación Periodo - Certificado</h3> 
    <hr /> <br />
    <br />
    <Grid className='' container rowSpacing={6} columnSpacing={6}>
      <Grid item xs={12} md={12}>
        <Grid container rowSpacing={6} columnSpacing={6}>
            <Grid item xs={12} md={12}>
                <label htmlFor="idPeriodo">Periodo</label>
                <br />
                <Select
                className='select_admin'
                id="idPeriodo"
                onChange={(event) => handleChanguePeriodo(event.target.value)}
                value={periodo}
                >
                    <MenuItem value={0}>Selecciona periodo</MenuItem>
                    {
                      listPeriodos&&
                      listPeriodos.map(periodoAux => (<MenuItem key={periodoAux.id_periodos_becas} 
                      value={periodoAux.id_periodos_becas}>
                        {periodoAux.nombre_periodo}
                      </MenuItem>))
                    }
                </Select>
            </Grid>
            <Grid item xs={12} md={12}>
                <TransferCerts
                nameLeft={"Certificados No Asignados"}
                nameRight={"Certificados Asignados"}
                listLeft={listCertNoAsignados} 
                listRight={listCertAsignados} 
                listValue={listCerts}
                asignList={getListaCerts}
                />
            </Grid>
        </Grid>
      </Grid>
    </Grid>
    <div className='form-button form-button-background-morado'>
        <Button variant="contained" 
        disabled={!periodo}
        onClick={guardarDatos}
        size="small" 
        >Guardar</Button>
    </div>
    <Snackbar
        open={mensaje.mostrarMensaje}
        autoHideDuration={3000}
        onClose={()=>setMensaje({...mensaje,mostrarMensaje:false})}
    >
        <MuiAlert elevation={6} variant="filled" severity={mensaje.tipoMensaje}> {mensaje.textoMensaje} </MuiAlert>
    </Snackbar>
    </>   
    );
  
  }