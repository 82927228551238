import React, { Component } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import headerInroads from "./img/inroads_becas_header_short.jpg";
import LogoutIcon from '@mui/icons-material/Logout';
import { Drawer, Divider, IconButton } from '@material-ui/core';
import { withRouter } from 'react-router';
import ReorderIcon from '@material-ui/icons/Reorder';
import { Link } from 'react-router-dom';
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import SearchIcon from '@mui/icons-material/Search';
import AssignmentTurnedInSharpIcon from '@mui/icons-material/AssignmentTurnedInSharp';
import HistoryIcon from '@material-ui/icons/History';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import FileDownloadSharpIcon from '@mui/icons-material/FileDownloadSharp';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import "./css/appBar.css";
import headerInroadsMobil from "./img/inroads_becas_email_header_mobil.jpg";

const styles = {
  loginButton: {
      maxWidth: 100,
      maxHeight: 500,
      marginRight: '10px'
  }
};

class ButtonAppBar extends Component {

  state = {
    isDrawerOpened: false,
    admin: false
  }

  componentDidMount = () => {
    if(sessionStorage.getItem('Admin') === "true" ){
      this.setState({ admin:true})
    }
  }

  logout = (event) => {
    sessionStorage.setItem('isSigned',false)
    sessionStorage.clear()
    this.props.history.push('/')
  }

  toggleDrawerStatus = () => {
    this.setState({
      isDrawerOpened: true,
    })
  }

  closeDrawer = () => {
    this.setState({
      isDrawerOpened: false,
      })
  }

  render() {
    const usuario = sessionStorage.getItem('email')
    const { isDrawerOpened } = this.state;

    return (
      <>
      <div className="appbar appbar-admin">
        {this.state.admin  &&
        <div className="appbar-admin-menu">
          <IconButton onClick={this.toggleDrawerStatus} style={styles.loginButton}>
          {!isDrawerOpened && <ReorderIcon />}
          </IconButton>
          <Divider/>
        </div>
        }
        <div className="header-logo">
          <img src={headerInroadsMobil} alt="Inroads"/>
        </div>
        <div className="header-boton">
          <p>Bienvenido {usuario}</p>
          <button className='boton-registro' onClick={this.logout}>Salir</button>
        </div>
      </div>
      <Drawer
        variant="temporary"
        open={isDrawerOpened}
        onClose={this.closeDrawer}
        >
        <Link to='/admin' style={styles.link}>
            <List>
                <ListItem button key='Busqueda'>
                    <ListItemIcon><SearchIcon/>
                    </ListItemIcon>
                    <ListItemText primary='Busqueda' />
                </ListItem>
            </List>
        </Link>
        <Link to='/asignacion' style={styles.link}>
          <List>
            <ListItem button key='Asignación'>
                <ListItemIcon><AssignmentTurnedInSharpIcon/>
                </ListItemIcon>
                <ListItemText primary='Asignación' />
                </ListItem>
            </List>
        </Link>
        <Link to='/org-claves-pr' style={styles.link}>
          <List>
            <ListItem button key='Organizaciones y Claves de pre registro'>
                <ListItemIcon><AssignmentTurnedInSharpIcon/>
                </ListItemIcon>
                <ListItemText primary='Organizaciones y Universidades' />
                </ListItem>
            </List>
        </Link>
        <Link to='/certificados' style={styles.link}>
          <List>
            <ListItem button key='Cerficados'>
                <ListItemIcon><VerifiedUserIcon/>
                </ListItemIcon>
                <ListItemText primary='Administración de alumnos certificados' />
                </ListItem>
            </List>
        </Link>
        <Link to='/AlumnosExportarCoursera' style={styles.link}>
          <List>
            <ListItem button key='AlumnosExportarCoursera'>
                <ListItemIcon><FileDownloadSharpIcon/>
                </ListItemIcon>
                <ListItemText primary='Alumnos con más de un certificado' />
                </ListItem>
            </List>
        </Link>
        <Link to='/adminDismiss' style={styles.link}>
          <List>
            <ListItem button key='AdminBaja'>
                <ListItemIcon>
                  <DeleteSweepIcon/>
                </ListItemIcon>
                  <ListItemText primary='Administración de baja definitiva' />
                </ListItem>
            </List>
        </Link>
        <Link to='/registroPeriodoCursos' style={styles.link}>
          <List>
            <ListItem button key='RegPeriodCert'>
                <ListItemIcon>
                  <CalendarTodayIcon/>
                </ListItemIcon>
                  <ListItemText primary='Registro de periodos y certificados' />
                </ListItem>
            </List>
        </Link>
      </Drawer>
      </>
    )
  }
}

export default withRouter(ButtonAppBar);
