import React from 'react';
//import Testiomino from '../src/img/testimonio_cert.jpeg';

export default function ExplainCerts() {

    return(
        <div className='container-explain'>
            <div className='sub-container-explain'>
                <div className='container-explain_video'>
                    <iframe src="https://www.youtube.com/embed/eiw26dWkYmk?si=ADBie22So4Dt5Avy"
                        title="YouTube video player" frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                        allowfullscreen>
                    </iframe>
                </div>
                <div className='container-explain_texto'>
                    <p  className='container-explain_texto_titulo'>
                        ¿Qué son los Certificados de Carrera <br />
                        de Google?
                    </p>
                    <p>
                        Son programas de formación online flexibles, 
                        diseñados para adquirir habilidades digitales 
                        con gran demanda en el mundo laboral y que 
                        ayudarán en tu crecimiento. 
                        Las personas que realicen estos Certificados 
                        no requieren formación ni experiencia previa. <br /><br />
                        Revisa el Proceso de Solicitud <strong><a href='https://drive.google.com/file/d/1tlSOIEgdRJAD_GjA9w3hB8giyOt9scfM/view?usp=sharing' target="_blank" rel="noopener noreferrer">Aquí</a></strong>
                    </p>
                </div>
            </div>
        </div>
    );
}