import React from 'react';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

export default function SeccionContact() {
    return(
        <div className='content-contacto'>
            <div className='content-contacto_content'>
                <div></div>
                <div className='content-contacto_content_text'>
                    <p className='content-contacto_content_text_titulo'>¿Tienes alguna duda?</p>
                    <p className='content-contacto_content_text_soporte_ayuda'>
                        Consulta nuestra sección de preguntas frecuentes <br />
                        o escribe a nuestro equipo de <b>Soporte y Ayuda</b>.
                    </p>
                    <p className='content-contacto_content_text_datos_contacto'>
                        <a href="mailto:becas.certificado.google@inroads.org.mx"><MailOutlineIcon className='logo_email'/> <span>&nbsp;becas.certificado.google@inroads.org.mx</span></a>
                    </p>
                    <p className='content-contacto_content_text_datos_contacto'>
                       <a href="https://wa.link/or8oge" target="_blank" rel="noopener noreferrer"><WhatsAppIcon className='logo_whatsapp'/> <span>&nbsp;56.3388.9253</span></a>
                    </p>
                </div>
            </div>
        </div>
    )
};
