import React,{useState, useEffect } from 'react';
import "./css/landing_body.css";
import ArrowNext from './img/arrow_bot2.svg';
import Check from './img/check.png';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';

export default function TimeLineCard({estatusBeca, pagePreview}) {

  const listCardPrimeraPasos= [
    {
      num:"0",
      text:"PASOS",
      colorFondo:"#6468cf",
    },
    {
      num:"1.",
      text:"Regístrate y verifica tu correo.",
      colorFondo:"#f1965b",
      checked: estatusBeca
    },
    {
      num:"2.",
      text:"Completa el formulario y selecciona el Certificado de tu interés.",
      colorFondo:"#98d6b2",
      checked:estatusBeca > 1
    },
    {
      num:"3.",
      text:"Espera la respuesta a tu solicitud ( de 1 a 2 semanas).",
      colorFondo:"#f1965b",
      checked:estatusBeca > 1
    },
    {
      num:"4.",
      text:"Si te fue otorgada la beca, actívala en Coursera.",
      colorFondo:"#98d6b2",
      checked:estatusBeca > 3
    },
  ];

  const listCardSegundaPasos= [
    {
      num:"5.",
      text:"Organiza tus tiempos para concluir tu Certificado al 100% (todos los cursos).",
      colorFondo:"#f1965b",
      checked:estatusBeca > 3
    },
    {
      num:"6.",
      text:"Al concluir todos los cursos, descarga tu Certificado final en Coursera.",
      colorFondo:"#98d6b2",
      checked:estatusBeca > 4
    },
    {
      num:"7.",
      text:"¡Si concluiste tu Certificado al 100%, puedes solicitar otro!",
      colorFondo:"#f1965b",
      checked:estatusBeca > 4
    },
  ];

  const [tipoLista, setTipoLista] = useState(2);
  const [listCardPasos, setListCardPasos] = useState(listCardPrimeraPasos);

  const cambiaLista = (tipoLista) => {
    if(tipoLista === 1) {
      setTipoLista(2);
      setListCardPasos(listCardPrimeraPasos);
    } else {
      setTipoLista(1);
      setListCardPasos(listCardSegundaPasos);
    }
  };

  useEffect(() => {
    if (pagePreview === 2) {
      cambiaLista(pagePreview);
    }
  }, []); 

  return (
    <div className='linea-tiempo'>
      <div className='line-arrow-back'>
        {tipoLista === 1 &&
          <img src={ArrowNext} height={40} onClick={()=>cambiaLista(1)} style={{transform:"scaleX(-1)"}} />
        }
      </div>
      {
        listCardPasos.map( card => (
          <>
          { card.num == 0 ?
            <div className='card-linea-tiempo' style={{backgroundColor:card.colorFondo}}>
                <div className='card-linea-tiempo_contenedor_aux'>
                  <div className='card-linea-tiempo_text_aux'>
                    <ChevronRightRoundedIcon />
                  </div>
                  <p className='card-linea-tiempo_text_aux'>{card.text}</p>
                </div>
            </div> :
            <div className='card-linea-tiempo' style={{backgroundColor:card.colorFondo}}>
              <div className='card-linea-tiempo_contenedor'>
                <p className='card-linea-tiempo_num'>{card.num}</p>
                <p className='card-linea-tiempo_text'>{card.text}</p>
                { card.num == 6 &&
                  <p className='card-linea-tiempo_text'>Revisa <a target="_blank" href='https://drive.google.com/file/d/185ILoTy2JJSpGHIJEUtV9HPCIc1Uj4HB/view?usp=sharing'>Aquí</a></p>
                }
                { card.num == 7 &&
                  <p className='card-linea-tiempo_text'><strong><a  target="_blank" href='https://drive.google.com/file/d/1B_bf1Jzl_an-WJRjGwe-15Sjq5YhekJO/view'>Revisa Aquí</a></strong></p>
                }
              </div>
              { card.checked &&
              <div className='card-linea-tiempo_check'>
                <img src={Check} alt="C" />
              </div>
              }
            </div>
          }
          
          </>
        ))
      }
      <div className='line-arrow-next'>
      {tipoLista === 2 && 
        <img src={ArrowNext} height={40} onClick={()=>cambiaLista(2)} />
      }
      </div>
    </div>
  );
}