import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import useMediaQuery from '@mui/material/useMediaQuery';
import CardPersonExp from './components/CardPersonExp';
import user1 from './img/personas/BrendaOlivaresOsorno.jpg';
import user2 from './img/personas/DavidLangarica.png';
import user3 from './img/personas/EliudVillanueva.jpeg';
import user4 from './img/personas/FebeMadaiMartinez.jpg';
import user5 from './img/personas/HugoEnriquePalaciosMontoya.jpeg';
import user6 from './img/personas/SebasCedeno.jpg';
import user7 from './img/personas/NoraChirino.jpg';
import user8 from './img/personas/JuanPablo.jpg';
import user9 from './img/personas/BeatrizLondono.jpg';
import user10 from './img/personas/JenniferGarcia.jpg';
import user11 from './img/personas/ErickMarquez.jpg';
import user12 from './img/personas/KarenN.jpeg';

export default function SeccionExperience() {

    const cuatroElementos = useMediaQuery('screen and (min-width: 1748px)') ? 4:null;
    const tresElementos = useMediaQuery('screen and (min-width: 1393px)') ? 3:null;
    const dosElementos = useMediaQuery('screen and (min-width: 1014px)') ? 2:null;

    const slidesToShow = cuatroElementos ? cuatroElementos : tresElementos ? tresElementos: dosElementos? dosElementos:1;

    const settings = {
        dots: false,
        infinite: false,
        slidesToShow,
        slidesToScroll: 1,
        autoplay: false,
        speed: 300,
        autoplaySpeed: 0,
        swipe: true,
        cssEase: "linear"
    };

    const listUsuariosExp = [
        {
            imagen:user6,
            nombre:"Sebastián Cedeño",
            texto:"El Certificado de Carrera de Google en Gestión de Proyectos me ayudó mucho a aprender a delegar, y a tener una mejor comunicación con mi equipo. Me encantó la manera tan clara, y amigable con la que los instructores explican los temas. En suma, mi experiencia fue motivante, desafiante y fructífera. Lo recomiendo mucho.",
        },
        {
            imagen:user7,
            nombre:"Nora Chirino",
            texto:"Estoy súper feliz porque cursar el Certificado me ayudó muchísimo para conseguir un nuevo trabajo. Hoy en día estoy trabajando en una empresa global como Project Manager. Agradezco a INROADS y Google por todo el apoyo.",
        },
        {
            imagen:user8,
            nombre:"Juan Pablo",
            texto:"Mi vida ha cambiado radicalmente en tan solo 16 meses. Después de aplicar todas las recomendaciones que dan en el Certificado ya he tenido la oportunidad de trabajar para dos empresas en el campo del Análisis de Datos.",
        },
        {
            imagen:user9,
            nombre:"Beatriz Londono",
            texto:"Gracias por esta valiosa oportunidad para aprender y avanzar en mi profesión. Disfruté mucho el Certificado, aprendí nuevas habilidades y me llevo muchas enseñanzas.",
        },
        {
            imagen:user10,
            nombre:"Jennifer García",
            texto:"El Certificado me ayudó a ser más analítica y a reforzar conocimientos que me fueron útiles en una entrevista técnica para ingresar a Mercado Libre como Software Developer. Gracias a eso, actualmente estoy en un equipo de Machine learning.",
        },
        {
            imagen:user11,
            nombre:"Erick Márquez Becerra",
            texto:"Me encantó poder llevar a la práctica los conocimientos adquiridos en el Certificado de Diseño UX en un proyecto laboral. Gracias al desarrollo y entrega de éste, fue que me ascendieron en mi trabajo actual. Gracias INROADS y Google, porque esta oportunidad me brindó satisfacción, logro y crecimiento profesional.",
        },
        {
            imagen:user12,
            nombre:"Karen Nareth Hernández",
            texto:"Tanto el Certificado de IT como el de Gestión de Proyectos han sido una parte fundamental en mi vida profesional y personal porque me han impulsado a mejorar mis proyectos personales y me han permitido ver un panorama diferente de cómo ser paciente y profesional para trabajar con los demás.",
        },
        {
            imagen:user2,
            nombre:"David René Langarica Hernández",
            texto:"Todas mis expectativas iniciales se han cumplido. Me ha gustado mucho cursar el Certificado porque he aprendido cosas bastante aplicables en mi carrera que me serán de ayuda para mejorar profesionalmente en un futuro.",
        },
        {
            imagen:user1,
            nombre:"Brenda  Olivares Osorno",
            texto:"Estudiar el certificado de carrera de Google me ha cambiado la vida, literalmente, ya que me ayudó a confirmar mi gusto por el área de TI y el análisis de datos. Tan así ha sido de relevante que inicié una Maestría en TI, y pienso combinar mi experiencia como Ingeniero Químico (Área medioambiental) con Tecnologías de la Información, para desarrollar aplicaciones para la industria.",
        },
        {
            imagen:user4,
            nombre:"Febe Madai Martínez de la Vega",
            texto:"Es muy entendible y la práctica me permite aprender mucho.",
        },
        {
            imagen:user3,
            nombre:"Eliud Villanueva  Salazar",
            texto:"Estoy muy emocionado por terminar una de las certificaciones ya que el contenido es muy completo y entendible.",
        },
        {
            imagen:user5,
            nombre:"Hugo Enrique Palacios Montoya",
            texto:"Inroads apareció en un momento crucial de mi vida; cuando decidí cambiar de carrera, no encontraba rumbo alguno, sin embargo, esta iniciativa en colaboración con Google apareció como un regalo de la vida, me dieron un gran aliento y mucha esperanza en mi situación laboral además, el contenido del curso es fascinante, no tengo más que palabras de agradecimiento.",
        },
    ];
    
    return(
        <div className='contiainer-seccion-exp'>
            <div className='contiainer-seccion-exp_titulo'>
                <p>¡Las experiencias dejan huella!</p>
            </div>
            <div className='contiainer-seccion-exp_texto'>
                <p>Conoce qué piensan nuestrxs becarixs del programa Certificados de Carerra de Google.</p>
            </div>
            <div className='contiainer-seccion-exp_cards'>
                <Slider {...settings}>
                {
                    listUsuariosExp.map(user => (
                        <CardPersonExp {...user}/> 
                    ))
                }
                </Slider>
            </div>
        </div>
    )
};
