import React, { useState } from 'react';
import ListCerts from './components/ListCerts';
import axios from 'axios';

export default function SeccionBecaFinalizada() {

    const certAssign = JSON.parse(sessionStorage.getItem("certificados"));
    /*
     const certAssign = [
         {id_certificado:1,tituloCert:"Titulo de Certificado",estatusCertificado:1},{id_certificado:2,tituloCert:"Titulo de Certificado",estatusCertificado:2},{id_certificado:3,tituloCert:"Titulo de Certificado",estatusCertificado:3},{id_certificado:4,tituloCert:"Titulo de Certificado",estatusCertificado:4}
     ];
     */

    const [listCertsData, setlistCertsData] = useState(certAssign.map(certAux => ({
        ...certAux,
        tituloCert:certAux.titulo_certificado,
        textoCert:certAux.estatusCertificado === 1 ? 'Acabaste tu certificado de Carerra de Google y estás listo para llevar tu carrera al siguiente nivel':
         certAux.estatusCertificado === 3 || certAux.estatusCertificado === 4 ?
        `No olvides activar tu curso con la invitación personializada que recibiste de parte de Coursera por correo. Revisa tus distintas bandejas.
        Es importante que luego SIEMPRE te conectes a Coursera desde AQUI para estar al tanto de todas las novedades que tendremos para ti.` : certAux.texto_certificado,
        imgCert:certAux.url_imagen,
        colorCert:certAux.hex_color,
        estatusCertificado:certAux.estatusCertificado
    })));

    console.log("lo que llega de info para los certs", listCertsData);
    const seleccionaNuevoCertificado = (idCert) => {
        const idPeriodo = parseInt(sessionStorage.getItem("periodoBeca"));

        const listCertsDataAux = listCertsData.map(certAux => {

            if(certAux.id_certificado === idCert) {
                return {...certAux,estatusCertificado:3,
                    textoCert:  `No olvides activar tu curso con la invitación personializada que recibiste de parte de Coursera por correo. Revisa tus distintas bandejas.
                    Es importante que luego SIEMPRE te conectes a Coursera desde AQUI para estar al tanto de todas las novedades que tendremos para ti.`
                }
            }

            return certAux;
        })

        const  listCertsDataAuxFilter = listCertsDataAux.filter(certAux => certAux.estatusCertificado === 1 || certAux.estatusCertificado === 3) ;

        axios.post('https://prod-backend-dot-driven-presence-373215.uc.r.appspot.com/api/v1/usuario_solicita_otro_certificado',{id_periodo_becas:idPeriodo,id_certificado:idCert},
        { headers: {"Authorization" : `Bearer ${sessionStorage.getItem('token')}`}})
        .then((response) => {
            sessionStorage.setItem("certificados",JSON.stringify(listCertsDataAuxFilter));
            setlistCertsData(listCertsDataAuxFilter);
        })
        .catch((error) => {
            console.error(error);
        });
    }

    return(
        <div>
            <div className='conatiner-beca-otorgada'>
                <div className='container-intro'>
                    <div className='container-intro_titulo'>
                        <p>
                            <b>¡Lo lograste!</b><br/>
                            ¡Muchas felicidades!
                        </p>
                    </div>
                    <div className='container-intro_texto'>
                        <p>
                            ​Ahora, aprovecha al máximo <br/>
                            <b>¡Elige otro Certificado! </b>
                        </p>
                    </div>
                </div>
            </div>
            <div className='container-explain'>
            <div className='sub-container-explain'>

                <div className='container-intro_video'>
                    <iframe src="https://www.youtube.com/embed/tnRDkWDKImg"
                        title="YouTube video player" frameborder="0" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                        allowfullscreen>
                    </iframe>
                </div>
                <div className='container-intro_select_otra_beca'>
                        <p>
                        Les contamos: Gracias a que Ricardo contestó nuestra encuesta al finalizar su Certificado, pudimos conocer su linda y motivadora historia.<br />

                            ¡También queremos conocer la tuya!
                        </p>
                        <div className='content-collaborators-button'>
                        <a href='https://www.tfaforms.com/5113606' target="_blank" rel="noopener noreferrer">¡Cuéntanosla aquí!</a>
                        </div>
                </div>  
            </div>
            </div>
            <ListCerts listCertsData={listCertsData} seleccionaNuevoCertificado={seleccionaNuevoCertificado} />
        </div>
    )
};
