import React, { useEffect, useState, useRef} from 'react';
import axios from 'axios';
import { Modal } from 'react-responsive-modal';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import "./css/adminStyle.css";
import AppBarAdmin from './AppBarHome';
import StickyFooter from "./StickyFooter";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import FormControl from '@mui/material/FormControl';
import moment from 'moment/moment';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import FormHelperText from '@mui/material/FormHelperText';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import SendIcon from '@mui/icons-material/Send';
import Link from '@material-ui/core/Link';
import LoadingButton from '@mui/lab/LoadingButton';
import 'moment/locale/es'

export default function OrganizacionesClavesPR() {

    const [organizaciones, setOrganizaciones] = useState([]);
    const [universidades, setUniversidades] = useState([]);

    const [periodos, setPeriodos] = useState([]);

    const [refrescaOrg, setRefrescaOrg] = useState(0);
    const [refrescaUniv, setRefrescaUniv] = useState(0);

    const [refrescaClavesOrg, setRefrescaClavesOrg] = useState(0);
    const [refrescaClavesUniv, setRefrescaClavesUniv] = useState(0);

    const [mensaje, setMensaje] = useState({
        mostrarMensaje: false,
        textoMensaje:"Se actualizo de manera correcta",
        tipoMensaje:"success"
    });

    const [newOrg, setNewOrg] = useState({
        email_notificacion_reportes: "",
        nombre_organizacion: "",
        email_notificacion_reportes_cc: ""
    });

    const [editOrgData, setEditOrgData] = useState({
        fecha_alta: "",
        id_catalogo_organizacion: ""
    });

    const [newUniv, setNewUniv] = useState({
        email_notificacion: "",
        nombre_universidad: "",
        email_notificacion_reportes_cc: ""
    });

    const [editUnivData, setEditUnivData] = useState({
        fecha_alta: "",
        id_catalogo_universidad: ""
    });

    const [orgCodigoPreReg, setOrgCodigoPreReg] = useState(null);

    const [univCodigoPreReg, setUnivCodigoPreReg] = useState(null);

    const [univClavesPreReg, setUnivClavesPreReg] = useState([]);
    const [orgClavesPreReg, setOrgClavesPreReg] = useState([]);
    
    const [loading, setLoading] = useState(false);

    const [formOrg, setFormOrg] = useState({
        email_notificacion_reportes: "",
        nombre_organizacion: "",
        email_notificacion_reportes_cc: ""
    });

    const [formUniv, setFormUniv] = useState({
        email_notificacion: "",
        nombre_universidad: "",
        email_notificacion_reportes_cc: ""
    });

    const [formCodigos, setFormCodigos] = useState({
        periodo: "",
        fecha_inicio: "",
        fecha_fin: "",
        cantidad: ""
    });

    const [selectedPeriodoOrg, setSelectedPeriodoOrg] = useState("");
    const [selectedPeriodoUniv, setSelectedPeriodoUniv] = useState("");

    const [codigos, setCodigos] = useState({
        fecha_inicio: null,
        fecha_fin: null,
        cantidad: 0
    });

    const validMailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    const validAlphaNumRegex = /^[a-zA-Z0-9\u00C0-\u024F\u1E00-\u1EFF-_\(\)\"\',\s\.]+$/;

    const mensajeRequerido = "Campo requerido";
    const mensajeAphaNum = "Debe ser alfanumérico";
    const mensajeCorreo = "El correo no tiene un formato válido";
    const mensajeCorreoCSV = "Alguno de los correos separados por comas no tiene un formato válido";

    const [modalClavesOrg, setModalClavesOrg] = useState(false);
    const [modalClavesUniv, setModalClavesUniv] = useState(false);

    const [modalNewClavesOrg, setModalNewClavesOrg] = useState(false);
    const [modalNewClavesUniv, setModalNewClavesUniv] = useState(false);

    const [tabValue, setTabValue] = useState(0);

    const orgFormRef = useRef();
    const univFormRef = useRef();

    useEffect(() => {
        console.log("Token: " + sessionStorage.getItem('token'));
        axios.get('https://prod-backend-dot-driven-presence-373215.uc.r.appspot.com/api/v1/admin/catalogo_organizaciones_read/',
        { 
            headers: {"Authorization" : `Bearer ${sessionStorage.getItem('token')}`}
        }).then((response) => {
            console.log("before");
            console.log(response.data);

            if(response.data.organizaciones){
                response.data.organizaciones.map(org => {
                    org.fecha_alta = moment(org.fecha_alta, "DD-MM-yyyyThh:mm");
                    org.fecha_baja = moment(org.fecha_baja, "DD-MM-yyyyTHH:mm");
                    return org;
                });
            }
            console.log(response.data);
            console.log("after");
            setOrganizaciones(response.data.organizaciones);
        }).catch((error) => {
            console.error(error);
        });
    }, [refrescaOrg]);

    useEffect(() => {
        axios.get('https://prod-backend-dot-driven-presence-373215.uc.r.appspot.com/api/v1/admin/catalogo_universidades_read/', 
        { 
            headers: {"Authorization" : `Bearer ${sessionStorage.getItem('token')}`}
        }).then((response) => {
            console.log("before");
            console.log(response.data);

            if(response.data.universidades){
                response.data.universidades.map(univ => {
                    univ.fecha_alta = moment(univ.fecha_alta, "DD-MM-yyyyThh:mm")
                    univ.fecha_baja = moment(univ.fecha_baja, "DD-MM-yyyyTHH:mm")
                    return univ;
                });
            }
            console.log(response.data);
            console.log("after");
            setUniversidades(response.data.universidades);
        }).catch((error) => {
            console.error(error);
        });
    }, [refrescaUniv]);

    useEffect(() => {
        console.log("orgCodigoPreReg=>", orgCodigoPreReg, ", selectedPeriodoOrg=>", selectedPeriodoOrg)
        if(orgCodigoPreReg && selectedPeriodoOrg){
            const data = new FormData();
            data.append('id_periodo_becas', selectedPeriodoOrg.id_periodo_becas);
            data.append("id_catalogo_organizacion", orgCodigoPreReg.id_catalogo_organizacion);

            axios.post('https://prod-backend-dot-driven-presence-373215.uc.r.appspot.com/api/v1/admin/lista_claves_pre_registro_read/', 
            data, 
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization" : `Bearer ${sessionStorage.getItem('token')}`
                    
                }
            }).then((response) => {
                console.log(response.data);
                setOrgClavesPreReg(response.data.respuesta);
                if(!response.data.respuesta || response.data.respuesta.length == 0){
                    setMensaje({
                        mostrarMensaje:true,
                        textoMensaje:"No se encontraron claves de pre registro.",
                        tipoMensaje:"info"
                    });
                }
            }).catch((error) => {
                console.error(error);
            });
        }
    }, [refrescaClavesOrg]);

    useEffect(() => {
        console.log("univCodigoPreReg=>", univCodigoPreReg, ", selectedPeriodoUniv=>", selectedPeriodoUniv)
        if(univCodigoPreReg && selectedPeriodoUniv){
            const data = new FormData();
            data.append('id_periodo_becas', selectedPeriodoUniv.id_periodo_becas);
            data.append("id_catalogo_universidad", univCodigoPreReg.id_catalogo_universidad);

            axios.post('https://prod-backend-dot-driven-presence-373215.uc.r.appspot.com/api/v1/admin/lista_claves_pre_registro_read/', 
            data, 
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization" : `Bearer ${sessionStorage.getItem('token')}`
                }
            }).then((response) => {
                console.log(response.data);
                setUnivClavesPreReg(response.data.respuesta);

                if(!response.data.respuesta || response.data.respuesta.length == 0){
                    setMensaje({
                        mostrarMensaje:true,
                        textoMensaje:"No se encontraron claves de pre registro.",
                        tipoMensaje:"info"
                    });
                }
            }).catch((error) => {
                console.error(error);
            });
        }
    }, [refrescaClavesUniv]);

    useEffect(() => {
        axios.get('https://prod-backend-dot-driven-presence-373215.uc.r.appspot.com/api/v1/admin/periodo_becas_read/',
        {
            headers: {
            "Authorization" : `Bearer ${sessionStorage.getItem('token')}`
        }
        }).then((response) => {
            console.log("before");
            console.log(response.data);
            setPeriodos(response.data.periodos_becas);
        }).catch((error) => {
            console.error(error);
        });
    }, []);

    // handle's
                            
    const handleOrgEditCodes = org => {
        console.log("handleOrgEditCodes");
        console.log(org);
        setOrgCodigoPreReg(org);
    };

    const handleUnivEditCodes = univ => {
        console.log("handleUnivEditCodes");
        console.log(univ);
        setUnivCodigoPreReg(univ);
    };

    const handleOrgEdit = org => {
        setEditOrgData({
            fecha_alta: org.fecha_alta,
            id_catalogo_organizacion: org.id_catalogo_organizacion
        });

        setNewOrg({
            email_notificacion_reportes: org.email_notificacion_reportes,
            nombre_organizacion: org.nombre_organizacion,
            email_notificacion_reportes_cc: (org.email_notificacion_reportes_cc ? org.email_notificacion_reportes_cc : "")
        });

        window.scrollTo({ behavior: 'smooth', top: orgFormRef.current.offsetTop })
    };

    const handleUnivEdit = univ => {
        setEditUnivData({
            fecha_alta: univ.fecha_alta,
            id_catalogo_universidad: univ.id_catalogo_universidad
        });

        setNewUniv({
            email_notificacion: univ.email_notificacion_reportes,
            nombre_universidad: univ.nombre_universidad,
            email_notificacion_reportes_cc: (univ.email_notificacion_reportes_cc ? univ.email_notificacion_reportes_cc : "")
        });

        window.scrollTo({ behavior: 'smooth', top: univFormRef.current.offsetTop })
    };

    const handleDeleteOrg = org => {
        console.log("handleDelete");
        console.log(org);
    };

    const handleSaveOrg =  event => {
        event.preventDefault();
        
        if(validaFormOrg()){
            console.log("saving");            
            console.log(newOrg);         
            
            const data = new FormData();
            data.append('nombre_organizacion', newOrg.nombre_organizacion);
            data.append("email_notificacion_reportes", newOrg.email_notificacion_reportes);
            data.append("email_notificacion_reportes_cc", newOrg.email_notificacion_reportes_cc ? newOrg.email_notificacion_reportes_cc : "");

            //Manejar si e trata de editar
            if(editOrgData.id_catalogo_organizacion){
                data.append('fecha_alta', editOrgData.fecha_alta.format('YYYY/MM/DD HH:mm:ss'));
                data.append('id_catalogo_organizacion', editOrgData.id_catalogo_organizacion);
            }
            setLoading(true);
            axios.post('https://prod-backend-dot-driven-presence-373215.uc.r.appspot.com/api/v1/admin/catalogo_organizaciones_write/', 
            data, 
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization" : `Bearer ${sessionStorage.getItem('token')}`
                }
            }).then(res => {
                setMensaje({
                    mostrarMensaje:true,
                    textoMensaje:"Organizacion guardada.",
                    tipoMensaje:"success"
                });
                setRefrescaOrg((refrescaOrg) => refrescaOrg ? 0:1);
                setNewOrg({
                    email_notificacion_reportes: "",
                    nombre_organizacion: "",
                    email_notificacion_reportes_cc: ""
                });

                setEditOrgData({
                    fecha_alta: "",
                    id_catalogo_organizacion: ""
                });
                
            }).catch(error => {
                setMensaje({
                    mostrarMensaje:true,
                    textoMensaje:"Error al guardar.",
                    tipoMensaje:"error"
                });
                console.error(error);
            }).finally(()=> setLoading(false));            
        }
        
    };

    const handleSaveUniv =  event => {
        event.preventDefault();
        
        if(validaFormUniv()){
            console.log("saving");            
            console.log(newUniv);         
            
            const data = new FormData();
            data.append('nombre_universidad', newUniv.nombre_universidad);
            data.append("email_notificacion", newUniv.email_notificacion);
            data.append("email_notificacion_reportes_cc", newUniv.email_notificacion_reportes_cc ? newUniv.email_notificacion_reportes_cc : "");

            //Manejar si e trata de editar
            if(editUnivData.id_catalogo_universidad){
                data.append('fecha_alta', editUnivData.fecha_alta.format('YYYY/MM/DD HH:mm:ss'));
                data.append('id_catalogo_universidad', editUnivData.id_catalogo_universidad);
            }

            setLoading(true);

            axios.post('https://prod-backend-dot-driven-presence-373215.uc.r.appspot.com/api/v1/admin/catalogo_universidades_write/', 
            data, 
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization" : `Bearer ${sessionStorage.getItem('token')}`
                }
            }).then(res => {
                setMensaje({
                    mostrarMensaje:true,
                    textoMensaje:"Universidad guardada.",
                    tipoMensaje:"success"
                });
                setRefrescaUniv((refrescaUniv) => refrescaUniv ? 0:1);
                setNewUniv({
                    email_notificacion: "",
                    nombre_universidad: "",
                    email_notificacion_reportes_cc: ""
                });
                setEditUnivData({
                    fecha_alta: "",
                    id_catalogo_universidad: ""
                });
            }).catch(error => {
                setMensaje({
                    mostrarMensaje:true,
                    textoMensaje:"Error al guardar.",
                    tipoMensaje:"error"
                });
                console.error(error);
            }).finally(()=> setLoading(false));            
        }
        
    };

    const handleCancelNewOrg =  event => {
        console.log("Cancelling organization");
        console.log(newOrg);
        setNewOrg({
            email_notificacion_reportes: "",
            nombre_organizacion: "",
            email_notificacion_reportes_cc: ""
        });

        setEditOrgData({
            fecha_alta: "",
            id_catalogo_organizacion: ""
        });
    }

    const handleCancelNewUniv =  event => {
        console.log("Cancelling university");
        console.log(newUniv);
        setNewUniv({
            email_notificacion: "",
            nombre_universidad: "",
            email_notificacion_reportes_cc: ""
        });
       
        setEditUnivData({
            fecha_alta: "",
            id_catalogo_universidad: ""
        });

    }

    const handleOrgChangeFormOrg = (event, field) => {
        const value = event.target.value;
        setNewOrg({...newOrg, [field]:value});
        realizaValidacionesOrg(field, value);
    };

    const handleOrgChangeFormUniv = (event, field) => {
        const value = event.target.value;
        setNewUniv({...newUniv, [field]:value});
        realizaValidacionesUniv(field, value);
    };

    const handleGuardarClaves = (event, tipo) => {
        let localFormValidation = {
            periodo: "",
            fecha_inicio: "",
            fecha_fin: "",
            cantidad: ""
        }

        if(tipo == "clave-org"){
            localFormValidation = {...localFormValidation, periodo: validaRequerido(selectedPeriodoOrg)};
            setFormCodigos(localFormValidation);
            
        }

        if(tipo == "clave-univ"){
            localFormValidation = {...localFormValidation, periodo: validaRequerido(selectedPeriodoUniv)};
            setFormCodigos(localFormValidation);
        }

        for (const nombreCampo in codigos) {
            let mensaje = validaRequerido(codigos[nombreCampo]);
            localFormValidation = {...localFormValidation, [nombreCampo]: mensaje};
            setFormCodigos(localFormValidation);
            
        }

        if(codigos.cantidad == null || codigos.cantidad <0){
            localFormValidation = {...localFormValidation, cantidad: "La cantidad de códigos no puede ser negativa"};
            setFormCodigos(localFormValidation);
        }

        if(codigos.cantidad == null || codigos.cantidad ==0){
            localFormValidation = {...localFormValidation, cantidad: "La cantidad de códigos no puede ser cero"};
            setFormCodigos(localFormValidation);
        }

        if(codigos.fecha_inicio && !codigos.fecha_inicio.isValid()){
            localFormValidation = {...localFormValidation, fecha_inicio: "No es una fecha válida"};
            setFormCodigos(localFormValidation);
        }

        if(codigos.fecha_fin && !codigos.fecha_fin.isValid()){
            localFormValidation = {...localFormValidation, fecha_fin: "No es una fecha válida"};
            setFormCodigos(localFormValidation);
        }

        for (const nombreCampo in localFormValidation) {
            if(localFormValidation[nombreCampo]){
                return false;
            }
        }

        if(codigos.cantidad > 5000){
            localFormValidation = {...localFormValidation, cantidad: "La cantidad de códigos no puede ser mayor a 5000"};
            setFormCodigos(localFormValidation);
        }

        if(codigos.fecha_inicio.toDate().getTime()  >= codigos.fecha_fin.toDate().getTime()){
            localFormValidation = {...localFormValidation, fecha_fin: "La fecha de fin de vigencia debe ser posterior a la fecha de inicio"};
            setFormCodigos(localFormValidation);
        }
        
        for (const nombreCampo in localFormValidation) {
            if(localFormValidation[nombreCampo]){
                return false;
            }
        }

        /* Construccion del objeto de peticion */
        const data = new FormData();
            
        if(tipo == "clave-org"){
            data.append('id_catalogo_organizacion', orgCodigoPreReg.id_catalogo_organizacion);
            data.append('id_periodo_becas', selectedPeriodoOrg.id_periodo_becas);
        }

        if(tipo == "clave-univ"){
            data.append('id_catalogo_universidad', univCodigoPreReg.id_catalogo_universidad);
            data.append('id_periodo_becas', selectedPeriodoUniv.id_periodo_becas);
        }

        let f_ini = codigos.fecha_inicio;
        let f_fin = codigos.fecha_fin;

        f_ini.set({hour:0,minute:0,second:0,millisecond:0});
        f_fin.set({hour:23,minute:59,second:59,millisecond:0});

        console.log("Fecha vigencia:");
        console.log(f_ini.format("yyyy/MM/DD HH:mm:ss"));
        console.log(f_fin.format("yyyy/MM/DD HH:mm:ss"));

        data.append('numero_claves', codigos.cantidad);
        data.append('fecha_alta', f_ini.format("yyyy/MM/DD HH:mm:ss"));
        data.append('fecha_expiracion', f_fin.format("yyyy/MM/DD HH:mm:ss"));


        setLoading(true);
        axios.post('https://prod-backend-dot-driven-presence-373215.uc.r.appspot.com/api/v1/admin/generador_claves_pre_registro/', 
        data, 
        {
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization" : `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(res => {

            //reseteamos y abrimos la seccion de descarga
            setFormCodigos({
                periodo: "",
                fecha_inicio: "",
                fecha_fin: "",
                cantidad: ""
            });
            setCodigos({
                fecha_inicio: null,
                fecha_fin: null,
                cantidad: 0
            });

            if(tipo == "clave-org"){
                setModalNewClavesOrg(false);
                setModalClavesOrg(true);
                setRefrescaClavesOrg((refrescaClavesOrg) => refrescaClavesOrg ? 0:1);
                handleSendClavesOrg(res.data, orgCodigoPreReg, selectedPeriodoOrg);
            }
    
            if(tipo == "clave-univ"){
                setModalNewClavesUniv(false);
                setModalClavesUniv(true);
                setRefrescaClavesUniv((refrescaClavesUniv) => refrescaClavesUniv ? 0:1);
                handleSendClavesUniv(res.data, univCodigoPreReg, selectedPeriodoUniv);
            }

        }).catch(error => {
            setMensaje({
                mostrarMensaje:true,
                textoMensaje:"Error al guardar.",
                tipoMensaje:"error"
            });
            console.error(error);
        }).finally(() => setLoading(false));            

    }

    const handleSendClavesUniv = (claves, univ, selectedPeriodoUniv) => {
        console.log(claves);
        console.log(univ);
        const data = new FormData();
        data.append('id_periodo_becas', selectedPeriodoUniv.id_periodo_becas);
        data.append('id_catalogo_universidad', univ.id_catalogo_universidad);
        data.append('id_archivo_clave_pre_registro', claves.id_archivo_clave_pre_registro);
        handleSendClaves(data);
    };

    const handleSendClavesOrg = (claves, org, selectedPeriodoOrg) => {
        const data = new FormData();
        data.append('id_periodo_becas', selectedPeriodoOrg.id_periodo_becas);
        data.append('id_catalogo_organizacion', org.id_catalogo_organizacion);
        data.append('id_archivo_clave_pre_registro', claves.id_archivo_clave_pre_registro);
        handleSendClaves(data);
    };
    
    const handleSendClaves = (data) => {
        
        setLoading(true);
        axios.post('https://prod-backend-dot-driven-presence-373215.uc.r.appspot.com/api/v1/admin/enviar_claves_pre_registro/', 
        data, 
        {
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization" : `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(res => {
            setMensaje({
                mostrarMensaje:true,
                textoMensaje:"Se han enviado por correo las claves de pre registro generadas.",
                tipoMensaje:"success"
            });

        }).catch(error => {
            setMensaje({
                mostrarMensaje:true,
                textoMensaje:"Error al enviar el correo.",
                tipoMensaje:"error"
            });
            console.error(error);
        }).finally(() => setLoading(false)); 
    };

    const handleCancelarClavesOrg = (event) => {
        setOrgCodigoPreReg(null);
        setSelectedPeriodoOrg("");
        setFormCodigos({
            periodo: "",
            fecha_inicio: "",
            fecha_fin: "",
            cantidad: ""
        });
        setCodigos({
            fecha_inicio: null,
            fecha_fin: null,
            cantidad: 0
        });
    }

    const handleCancelarClavesUniv = (event) => {
        setUnivCodigoPreReg(null);
        setSelectedPeriodoUniv("");
        setFormCodigos({
            periodo: "",
            fecha_inicio: "",
            fecha_fin: "",
            cantidad: ""
        });
        setCodigos({
            fecha_inicio: null,
            fecha_fin: null,
            cantidad: 0
        });
    }

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
        
        setSelectedPeriodoOrg("");
        setSelectedPeriodoUniv("");
        setOrgCodigoPreReg(null);
        setUnivCodigoPreReg(null);
        setFormOrg({
            email_notificacion_reportes: "",
            nombre_organizacion: "",
            email_notificacion_reportes_cc: ""
        });
        setFormUniv({
            email_notificacion: "",
            nombre_universidad: "",
            email_notificacion_reportes_cc: ""
        });
        setFormCodigos({
            periodo: "",
            fecha_inicio: "",
            fecha_fin: "",
            cantidad: ""
        });
        setCodigos({
            fecha_inicio: null,
            fecha_fin: null,
            cantidad: 0
        });
        setNewOrg({
            email_notificacion_reportes: "",
            nombre_organizacion: "",
            email_notificacion_reportes_cc: ""
        });
        setNewUniv({
            email_notificacion: "",
            nombre_universidad: "",
            email_notificacion_reportes_cc: ""
        });
        setEditUnivData({
            fecha_alta: "",
            id_catalogo_universidad: ""
        });
        setEditOrgData({
            fecha_alta: "",
            id_catalogo_organizacion: ""
        });

        console.log("cleaning the forms");
    };

    const realizaValidacionesOrg = (nombreCampo, valorCampo) => {
        if(nombreCampo != "email_notificacion_reportes_cc"){
            let mensaje = validaRequerido(valorCampo);
            setFormOrg({...formOrg,[nombreCampo]: mensaje});
            return mensaje;
        }
        return "";
        
    };

    const realizaSubmitValidacionesOrg = () => {
        let mensaje = "";
        let mensaje_cc = "";
        console.log(formOrg);
        for (const nombreCampo in formOrg) {
            if(nombreCampo != "email_notificacion_reportes_cc"){
                mensaje = realizaValidacionesOrg(nombreCampo, newOrg[nombreCampo]);
                if(mensaje){
                    return false;
                }
            }
        }
        let auxFormOrg = {...formOrg};
        mensaje = validaCorreo(newOrg.email_notificacion_reportes);
        auxFormOrg = ({...auxFormOrg, "email_notificacion_reportes": mensaje});
        if(newOrg.email_notificacion_reportes_cc){
            mensaje_cc = validaCorreoCSV(newOrg.email_notificacion_reportes_cc);
            auxFormOrg = ({...auxFormOrg, "email_notificacion_reportes_cc": mensaje_cc});    
        }else{
            auxFormOrg = ({...auxFormOrg, "email_notificacion_reportes_cc": ""});    
        }
        if(mensaje || mensaje_cc){
            setFormOrg(auxFormOrg);
            return false;
        }
        mensaje = validaAlfanumerico(newOrg.nombre_organizacion);
        auxFormOrg = ({...auxFormOrg, "nombre_organizacion": mensaje});
        setFormOrg(auxFormOrg);
        return mensaje ? false: true;
    };

    const realizaValidacionesUniv = (nombreCampo, valorCampo) => {
        if(nombreCampo != "email_notificacion_reportes_cc"){
            let mensaje = validaRequerido(valorCampo);
            setFormUniv({...formUniv,[nombreCampo]: mensaje});
            return mensaje;
        }
        return "";
    };

    const realizaSubmitValidacionesUniv = () => {
        let mensaje = "";
        let mensaje_cc = "";
        console.log(formUniv);
        for (const nombreCampo in formUniv) {
            if(nombreCampo != "email_notificacion_reportes_cc"){
                mensaje = realizaValidacionesUniv(nombreCampo, newUniv[nombreCampo]);
                if(mensaje){
                    return false;
                }
            }
            
        }
        let auxFormUniv = {...formUniv};
        mensaje = validaCorreo(newUniv.email_notificacion);
        auxFormUniv = ({...auxFormUniv, "email_notificacion": mensaje});
        if(newUniv.email_notificacion_reportes_cc){
            mensaje_cc = validaCorreoCSV(newUniv.email_notificacion_reportes_cc);
            auxFormUniv = ({...auxFormUniv, "email_notificacion_reportes_cc": mensaje_cc});
        }else{
            auxFormUniv = ({...auxFormUniv, "email_notificacion_reportes_cc": ""});
        }
        
        if(mensaje || mensaje_cc){
            setFormUniv(auxFormUniv);
            return false;
        }
        mensaje = validaAlfanumerico(newUniv.nombre_universidad);
        auxFormUniv = ({...auxFormUniv, "nombre_universidad": mensaje});
        setFormUniv(auxFormUniv);
        return mensaje ? false: true;
    };

    const validaRequerido = (valorCampo) => {
        return (valorCampo === null || valorCampo === "") ? mensajeRequerido:"";
    };

    const validaAlfanumerico = (valorCampo) => {
        return valorCampo.match(validAlphaNumRegex) ? "":mensajeAphaNum;
    };

    const validaCorreo = (valorCampo) => {
        return valorCampo.match(validMailRegex) ? "":mensajeCorreo;
    };

    const validaCorreoCSV = (valorCampo) => {
        let emails = valorCampo.replace(/\s/g,'').split(",");
        var valid = true;
        
        for (var i = 0; i < emails.length; i++) {
             if( emails[i] == "" || validaCorreo(emails[i]) != ""){
                 valid = false;
             }
        }
        return valid ? "":mensajeCorreoCSV;
    };

    const validaFormOrg = () => {

        if(realizaSubmitValidacionesOrg()){
            for (const nombreCampo in formOrg) {
                if(formOrg[nombreCampo]){
                    return false;
                }
            }
        }else{
            return false;
        }
        return true;
    }

    const validaFormUniv = () => {

        if(realizaSubmitValidacionesUniv()){
            for (const nombreCampo in formUniv) {
                if(formUniv[nombreCampo]){
                    return false;
                }
            }
        }else{
            return false;
        }
        return true;
    }

    // -- Fin handle's
   
      const orgColumns = [
        { field: 'nombre_organizacion', headerName: 'Nombre', width: 250 },
        { field: 'email_notificacion_reportes', headerName: 'Correo', width: 300 },
        { field: 'email_notificacion_reportes_cc', headerName: 'Correo CC', width: 100 },
        {   field: 'fecha_alta', 
            headerName: 'Fecha de alta', 
            width: 250, 
            type: 'dateTime',
            valueFormatter: ({ value }) => value.format("DD/MM/yyyy HH:mm:ss") + " hrs", },
        {   headerName: 'Acciones', 
            width: 400,
            field: 'acciones',
            type: 'actions',
            renderCell: (params) => (
            <OrgActions {...{ params}} />
            )}
      ];

      const univColumns = [
        { field: 'nombre_universidad', headerName: 'Nombre', width: 250 },
        { field: 'email_notificacion_reportes', headerName: 'Correo', width: 300 },
        { field: 'email_notificacion_reportes_cc', headerName: 'Correo CC', width: 100 },
        {   field: 'fecha_alta', 
            headerName: 'Fecha de alta', 
            width: 250, 
            type: 'dateTime',
            valueFormatter: ({ value }) => value.format("DD/MM/yyyy HH:mm:ss") + " hrs", },
        {   headerName: 'Acciones', 
            width: 400,
            field: 'acciones',
            type: 'actions',
            renderCell: (params) => (
            <UnivActions {...{ params}} />
            )}
      ];

      const univClavesColumns = [
        { field: 'id_archivo_clave_pre_registro', headerName: 'Llave', width: 100 },
        { field: 'nombre_archivo', headerName: 'Archivo', width: 350 },
        {   headerName: 'Acciones', 
            width: 200,
            field: 'descarga',
            type: 'actions',
            renderCell: (params) => (
                <>
                <Link style={{display: "inline-flex"}} href={params.row.url_archivo} >
                     Descargar <DownloadOutlinedIcon/>
                </Link>
                <Link   style={{display: "inline-flex", cursor: "pointer", marginLeft: "10px"}}
                        onClick={() => {
                            handleSendClavesUniv(params.row, univCodigoPreReg, selectedPeriodoUniv);
                    }}>
                     Enviar <SendIcon/>
                </Link>
                </>
            )}
      ];
    
      const orgClavesColumns = [
        { field: 'id_archivo_clave_pre_registro', headerName: 'Llave', width: 100 },
        { field: 'nombre_archivo', headerName: 'Archivo', width: 350 },
        {   headerName: 'Acciones', 
            width: 200,
            field: 'descarga',
            type: 'actions',
            renderCell: (params) => (
                <>
                <Link style={{display: "inline-flex"}} href={params.row.url_archivo} >
                     Descargar <DownloadOutlinedIcon/>
                </Link>
                <Link   style={{display: "inline-flex", cursor: "pointer", marginLeft: "10px"}} 
                        onClick={() => {
                            handleSendClavesOrg(params.row, orgCodigoPreReg, selectedPeriodoOrg);
                    }}>
                    Enviar <SendIcon/> 
                </Link>
                </>
            )}
      ];


    function TabPanel(props) {
        const { children, value, index, ...other } = props;
        return (
            <div
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}
            >
            {value === index && (
                <Box>
                <Typography>{children}</Typography>
                </Box>
            )}
            </div>
        );
    }

    function LinkTab(props) {
        return (
            <Tab
            component="a"
            onClick={(event) => {
                event.preventDefault();
            }}
            {...props}
            />
        );
    }

    function a11yProps(index) {
        return {
            id: `nav-tab-${index}`,
            'aria-controls': `nav-tabpanel-${index}`,
        };
    }

    const SelectPeriodoOrg = (props) => {
        const { changeCallback, ...other } = props;

        return (
        <FormControl sx={{ m: 1, minWidth: 300 }} style={{"bottom": "8px", "width":"90%"}} error={formCodigos.periodo}>
        <InputLabel id="periodos-select-helper-label" style={{left: "-13px"}}>Periodo de becas</InputLabel>

        <Select
            labelId="periodos-select-helper-label"
            variant="standard"
            id="periodo-select-helper"
            value={selectedPeriodoOrg}
            label="Periodo de becas"
            onChange={changeCallback}
            >
            {periodos.map(periodo => (
                <MenuItem key={periodo.id_periodo_becas} value={periodo}>{periodo.nombre_periodo}</MenuItem>))
            }
        </Select>
        {formCodigos.periodo && <FormHelperText>{formCodigos.periodo}</FormHelperText>}
        </FormControl>
    )};

    const SelectPeriodoUniv = (props)=> {
        const { changeCallback, ...other } = props;
        return (
        <FormControl sx={{ m: 1, minWidth: 300 }} style={{"bottom": "8px", "width":"90%"}} error={formCodigos.periodo}>
        <InputLabel id="periodos-select-helper-label" style={{left: "-13px"}}>Periodo de becas</InputLabel>

        <Select
            labelId="periodos-select-helper-label"
            variant="standard"
            id="periodo-select-helper"
            value={selectedPeriodoUniv}
            label="Periodo de becas"
            onChange={changeCallback}
            >
            {periodos.map(periodo => (
                <MenuItem key={periodo.id_periodo_becas} value={periodo}>{periodo.nombre_periodo}</MenuItem>))
            }
        </Select>
        {formCodigos.periodo && <FormHelperText>{formCodigos.periodo}</FormHelperText>}
        </FormControl>
        )
    };


    const ModalClavesOrg = () => (
        <Modal open={modalClavesOrg} onClose={() => {
            setModalClavesOrg(false);
            setOrgClavesPreReg([]);
            setSelectedPeriodoOrg("");
            }} >
            <DialogContent>
                <DialogContentText >
                    <p>Claves de pre registro:</p>    
                    <InputLabel style={{"marginTop": '20px', "marginBottom": '20px'}}>Seleccione un periodo de becas:</InputLabel>
                    <Box sx={{ display: 'inline-flex', flexWrap: 'wrap', alignContent: 'flex-start'}}>
                        {SelectPeriodoOrg(
                            {changeCallback: (event)=>{
                                setSelectedPeriodoOrg(event.target.value);
                                setRefrescaClavesOrg((refrescaClavesOrg) => refrescaClavesOrg ? 0:1);
                                }
                            }
                        )}  
                        <div style={{ height: 550, width: '100%', "marginTop": '20px' }}>
                            <DataGrid 
                                getRowId={ (row) => row.id_archivo_clave_pre_registro}
                                rows={orgClavesPreReg} 
                                columns={orgClavesColumns} 
                                components={{ Toolbar: GridToolbar }} 
                                initialState={{
                            sorting: {
                                sortModel: [{ field: 'id_archivo_clave_pre_registro', sort: 'desc' }],
                            },
                            pagination: {
                                pageSize: 25,
                                }
                            }}/>
                        </div>
                    </Box>
                </DialogContentText>
            </DialogContent>
        </Modal>
    );

    const ModalNewClavesOrg = () => (
        <Modal open={modalNewClavesOrg} onClose={(event) => {
            setModalNewClavesOrg(false);
            handleCancelarClavesOrg(event);
            }} >
            <DialogContent>
                <DialogContentText >
                    {orgCodigoPreReg &&
                    <Box sx={{ display: 'inline-flex', flexWrap: 'wrap', alignContent: 'flex-start'}}>
                        
                        <p>Crear claves de pre registro:</p>
                        <Grid container spacing={0} style={{"marginTop": '30px', "marginBottom": '30px'}}>
                            <Grid item md={6} xs={12} sm={12} justifyContent="center">
                            <TextField variant="standard" style={{ "width":"90%"}}
                                    label="Organización"
                                    key="org-name-claves-input"
                                    disabled
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    value={orgCodigoPreReg.nombre_organizacion}  />
                            </Grid>
                            <Grid item md={6} xs={12} sm={12} justifyContent="center">
                                {SelectPeriodoOrg(
                                    {changeCallback: (event)=>setSelectedPeriodoOrg(event.target.value)}
                                )}
                            </Grid>
                        </Grid>

                        <Grid container xs={12}  style={{"marginTop": '30px', "marginBottom": '50px'}} >
                        <Grid item md={6} xs={12} sm={12} justifyContent="center">
                            <LocalizationProvider dateAdapter={AdapterMoment} locale={"es-ES"} >
                            <DatePicker 
                                inputFormat="DD/MM/YYYY"
                                label="Fecha inicio"
                                value={codigos.fecha_inicio}
                                onChange={(newValue)=> setCodigos({...codigos, fecha_inicio: newValue})}
                                renderInput={(params) => 
                                <TextField {...params} 
                                    error= {formCodigos.fecha_inicio}
                                    variant="standard"
                                    style={{ "width":"90%"}}
                                    helperText= {formCodigos.fecha_inicio}
                                />}
                            />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item md={6} xs={12} sm={12} justifyContent="center" >
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                                label="Fecha fin"
                                inputFormat="DD/MM/YYYY"
                                value={codigos.fecha_fin}
                                onChange={(newValue)=> setCodigos({...codigos, fecha_fin: newValue})}
                                renderInput={(params) => <TextField {...params}
                                    variant="standard"
                                    error= {formCodigos.fecha_fin}
                                    style={{ "width":"90%"}}
                                    helperText= {formCodigos.fecha_fin}
                                />}
                            />
                        </LocalizationProvider>
                        </Grid>
                        </Grid> 

                        <Grid container spacing={0} style={{"marginTop": '30px', "marginBottom": '30px'}}>
                        <Grid item md={6} xs={12} sm={12} justifyContent="center">
                            <TextField variant="standard" style={{"width":"90%"}}
                                label="Cantidad de claves"
                                error={formCodigos.cantidad}
                                type="number"
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                helperText={formCodigos.cantidad}  
                                value={codigos.cantidad} 
                                onChange={(event) => {if(event.target.value && event.target.value > 9999) {return;} setCodigos({...codigos, cantidad: event.target.value});}} />
                        </Grid>
                        </Grid>

                        
                            <Grid container xs={12} justifyContent="center" style={{"marginTop": '30px', "marginBottom": '50px'}}>
                            <Grid item xl={2} xs={4}>
                               
                                <LoadingButton
                                    size="medium"
                                    type="submit"
                                    onClick={(event)=> handleGuardarClaves(event, "clave-org")}
                                    loading={loading}
                                    style={{"marginLeft": "8px"}}
                                    variant="contained">
                                        Generar
                                </LoadingButton>
                            </Grid>
                            <Grid item xl={2} xs={4}>
                                <Button variant="outlined"
                                 style={{"marginLeft": "8px"}}
                                 size="medium"
                                    type="button" 
                                    onClick={(event) => {
                                        setModalNewClavesOrg(false);
                                        handleCancelarClavesOrg(event);
                                        }}>Cancelar</Button>
                            </Grid>
                            </Grid>
                    </Box>
                    }
                </DialogContentText>
            </DialogContent>
        </Modal>
    );

    const ModalClavesUniv = () => (
        <Modal open={modalClavesUniv} onClose={() => {
            setModalClavesUniv(false);
            setUnivClavesPreReg([]);
            setSelectedPeriodoUniv("");
            }} >
            <DialogContent>
                <DialogContentText >
                    <p>Claves de pre registro:</p>
                    <InputLabel style={{"marginTop": '20px', "marginBottom": '20px'}}>Seleccione un periodo de becas:</InputLabel>
                    <Box sx={{ display: 'inline-flex', flexWrap: 'wrap', alignContent: 'flex-start'}}>
                        {SelectPeriodoUniv(
                            {changeCallback: (event)=>{
                                setSelectedPeriodoUniv(event.target.value);
                                setRefrescaClavesUniv((refrescaClavesUniv) => refrescaClavesUniv ? 0:1);
                                }
                            }
                        )}  
                        
                        <div style={{ height: 550, width: '100%',"marginTop": '20px' }}>
                            <DataGrid 
                                getRowId={ (row) => row.id_archivo_clave_pre_registro}
                                rows={univClavesPreReg} 
                                columns={univClavesColumns} 
                                components={{ Toolbar: GridToolbar }} 
                                initialState={{
                                    sorting: {
                                        sortModel: [{ field: 'id_archivo_clave_pre_registro', sort: 'desc' }],
                                    },
                                    pagination: {
                                        pageSize: 25,
                                        }
                                    }}/>
                        </div>
                    </Box>
                </DialogContentText>
            </DialogContent>
        </Modal>
    );

    const ModalNewClavesUniv = () => (
        <Modal open={modalNewClavesUniv} onClose={(event) => {
            setModalNewClavesUniv(false);
            handleCancelarClavesUniv(event);
            }} >
            <DialogContent>
                <DialogContentText >
                    {univCodigoPreReg &&
                    <Box sx={{ display: 'inline-flex', flexWrap: 'wrap', alignContent: 'flex-start'}}>
                    <p>Crear claves de pre registro:</p>
                    <Grid container spacing={0}>
                        <Grid item md={6} xs={12} sm={12} justifyContent="center">
                        <TextField variant="standard"
                                label="Universidad"
                                key="univ-name-claves-input"
                                disabled
                                InputProps={{
                                    readOnly: true,
                                  }}
                                value={univCodigoPreReg.nombre_universidad}  
                                style={{"width":"90%"}}
                                />
                        </Grid>
                        <Grid item md={6} xs={12} sm={12} justifyContent="center">
                            {SelectPeriodoUniv(
                                    {changeCallback: (event)=>setSelectedPeriodoUniv(event.target.value)}
                            )}
                        </Grid>
                    </Grid>

                    <Grid container xs={12}  style={{"marginTop": '30px', "marginBottom": '50px'}}>
                    <Grid item md={6} xs={12} sm={12} justifyContent="center">
                    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={"es"}>
                        <DatePicker 
                            inputFormat="DD/MM/YYYY"
                            label="Fecha inicio"
                            value={codigos.fecha_inicio}
                            onChange={(newValue)=> setCodigos({...codigos, fecha_inicio: newValue})}
                            renderInput={(params) => 
                            <TextField {...params}
                                error= {formCodigos.fecha_inicio}
                                style={{ "width":"90%"}}
                                variant="standard"
                                helperText= {formCodigos.fecha_inicio}
                            />}
                        />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item md={6} xs={12} sm={12} justifyContent="center">
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                            label="Fecha fin"
                            inputFormat="DD/MM/YYYY"
                            value={codigos.fecha_fin}
                            onChange={(newValue)=> setCodigos({...codigos, fecha_fin: newValue})}
                            renderInput={(params) => <TextField {...params}
                                variant="standard"
                                error= {formCodigos.fecha_fin}
                                style={{ "width":"90%"}}
                                helperText= {formCodigos.fecha_fin}
                            />}
                        />
                    </LocalizationProvider>
                    </Grid>
                    </Grid> 

                    <Grid container spacing={0} style={{"marginTop": '30px', "marginBottom": '30px'}}>
                    <Grid item md={6} xs={12} sm={12} justifyContent="center">
                        <TextField variant="standard" style={{"width":"90%", "margin": "8px"}}
                            label="Cantidad de claves"
                            error={formCodigos.cantidad}
                            type="number"
                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                            helperText={formCodigos.cantidad}  
                            value={codigos.cantidad} 
                            onChange={(event) => {if(event.target.value && event.target.value > 9999) {return;} setCodigos({...codigos, cantidad: event.target.value})}} />
                    </Grid>
                    </Grid>

                    <Grid container xs={12}  style={{"marginTop": '30px', "marginBottom": '50px'}} justifyContent="center">
                    <Grid item xl={2} xs={4}>
                            <LoadingButton
                                    style={{"marginLeft": "8px"}}
                                    size="medium"
                                    type="submit"
                                    onClick={(event)=> handleGuardarClaves(event, "clave-univ")}
                                    loading={loading}
                                    variant="contained">
                                        Generar
                                </LoadingButton>
                    </Grid>
                    <Grid item xl={2} xs={4}>
                        <Button variant="outlined" style={{"marginLeft": "8px"}}
                            size="medium"
                            type="button" 
                            onClick={(event) => {
                                setModalNewClavesUniv(false);
                                handleCancelarClavesUniv(event);
                                }}>Cancelar</Button>
                    </Grid>
                    </Grid>
                    </Box>
                    }
                </DialogContentText>
            </DialogContent>
        </Modal>
    );


      const OrgActions = ({params}) => (
        <>
            <Button
            style={{"marginRight": '8px'}}
            variant="outlined"
            size="medium"
            onClick={() => {
                if(!params.row.email_notificacion_reportes){
                    setMensaje({
                        mostrarMensaje:true,
                        textoMensaje:"Es necesario definir el correo de la organización antes de generar sus claves de pre registro.",
                        tipoMensaje:"info"
                    });
                    return;
                }

                handleOrgEditCodes(params.row);
                setModalNewClavesOrg(true);
            }}>
                Crear claves
            </Button>
            <Button
            style={{"marginRight": '8px'}}
            variant="contained"
            color="primary"
            size="medium"
            onClick={() => {
                setModalClavesOrg(true);
                handleOrgEditCodes(params.row);
                }}>
                Descargar claves
            </Button>
            <Button
            variant="contained"
            color="primary"
            size="medium"
            onClick={() => {
                handleOrgEdit(params.row);
                }}>
                Editar
            </Button>
        </>
    );

    const UnivActions = ({params}) => (
        <>
            <Button
            style={{"marginRight": '8px'}}
            variant="outlined"
            size="medium"
            onClick={() => {
                if(!params.row.email_notificacion_reportes){
                    setMensaje({
                        mostrarMensaje:true,
                        textoMensaje:"Es necesario definir el correo de la universidad antes de generar sus claves de pre registro.",
                        tipoMensaje:"info"
                    });
                    return;
                }

                handleUnivEditCodes(params.row);
                setModalNewClavesUniv(true);
            }}>
                Crear claves
            </Button>
            <Button
            style={{"marginRight": '8px'}}
            variant="contained"
            color="primary"
            size="medium"
            onClick={() => {
                setModalClavesUniv(true);
                handleUnivEditCodes(params.row);
                }}>
                Descargar claves
            </Button>
            <Button
            variant="contained"
            color="primary"
            size="medium"
            onClick={() => {
                handleUnivEdit(params.row);
                }}>
                Editar
            </Button>
        </>
    );

    const FormOrg = () => (
        <>
        <form onSubmit={handleSaveOrg}>
            <Grid container spacing={0} style={{"marginTop": '30px', "marginBottom": '30px'}}>
                
                <Grid item lg={3} md={12} xs={12} sm={12} justifyContent="center">

                        <TextField variant="standard" style={{"width":"90%"}}
                            label="Nombre"
                            error={formOrg.nombre_organizacion}
                            helperText={formOrg.nombre_organizacion}  
                            key="org-name-input"
                            value={newOrg.nombre_organizacion} 
                            inputProps={{ maxLength: 200 }}
                            //disabled={editOrgData.id_catalogo_organizacion}
                            onChange={(event) => {handleOrgChangeFormOrg(event, "nombre_organizacion");}} />
                        

                </Grid>
                <Grid item lg={3} md={12}  xs={12} sm={12}>

                        <TextField variant="standard" style={{"width":"90%"}}
                            label="Correo"
                            error={formOrg.email_notificacion_reportes}
                            helperText={formOrg.email_notificacion_reportes}  
                            key="org-email-input"
                            inputProps={{ maxLength: 63 }}
                            value={newOrg.email_notificacion_reportes} 
                            onChange={(event) => {handleOrgChangeFormOrg(event, "email_notificacion_reportes");}} />

                    
                </Grid>
                <Grid item lg={3} md={12}  xs={12} sm={12}>

                        <TextField variant="standard" style={{"width":"90%"}}
                            label="Correo CC"
                            error={formOrg.email_notificacion_reportes_cc}
                            helperText={formOrg.email_notificacion_reportes_cc}  
                            key="org-email-input"
                            inputProps={{ maxLength: 63 }}
                            value={newOrg.email_notificacion_reportes_cc} 
                            onChange={(event) => {handleOrgChangeFormOrg(event, "email_notificacion_reportes_cc");}} />

                    
                </Grid>
            </Grid>
            <Box width="100%" />
            <Grid container xs={12}  style={{"marginTop": '30px', "marginBottom": '50px'}}>
                <Grid item xl={2} lg={3} md={6} xs={6} sm={6}>
                    <LoadingButton
                        size="medium"
                        type="submit"
                        loading={loading}
                        variant="contained">
                            Guardar
                    </LoadingButton>
                </Grid>
                <Grid item xl={2} lg={3} md={6} xs={6} sm={6}>
                    <Button variant="outlined" 
                        size="medium"
                        type="button" 
                        onClick={handleCancelNewOrg}>Cancelar</Button>
                </Grid>
            </Grid>
            
        </form>
        
        </>
    );

    const FormUniv = () => (
        <>
        <form onSubmit={handleSaveUniv}>
            <Grid container spacing={0} style={{"marginTop": '30px', "marginBottom": '30px'}}>
                
                <Grid item lg={3} md={6} xs={6} sm={6} justifyContent="center">

                        <TextField variant="standard" style={{"width":"90%"}}
                            label="Nombre"
                            error={formUniv.nombre_universidad}
                            helperText={formUniv.nombre_universidad}  
                            inputProps={{ maxLength: 200 }}
                            key="univ-name-input"
                            value={newUniv.nombre_universidad} 
                            onChange={(event) => {handleOrgChangeFormUniv(event, "nombre_universidad");}} />
                        

                </Grid>
                <Grid item lg={3} md={6} xs={6} sm={6}>

                        <TextField variant="standard" style={{"width":"90%"}}
                            label="Correo"
                            error={formUniv.email_notificacion}
                            helperText={formUniv.email_notificacion}  
                            key="univ-email-input"
                            inputProps={{ maxLength: 63 }}
                            value={newUniv.email_notificacion} 
                            onChange={(event) => {handleOrgChangeFormUniv(event, "email_notificacion");}} />

                    
                </Grid>
                <Grid item lg={3} md={6} xs={6} sm={6}>

                        <TextField variant="standard" style={{"width":"90%"}}
                            label="Correo CC"
                            error={formUniv.email_notificacion_reportes_cc}
                            helperText={formUniv.email_notificacion_reportes_cc}  
                            key="univ-email-input"
                            inputProps={{ maxLength: 63 }}
                            value={newUniv.email_notificacion_reportes_cc} 
                            onChange={(event) => {handleOrgChangeFormUniv(event, "email_notificacion_reportes_cc");}} />

                    
                </Grid>
            </Grid>
            <Box width="100%" />
            <Grid container xs={12}  style={{"marginTop": '30px', "marginBottom": '50px'}}>
                <Grid item xl={2} lg={3} md={6} xs={6} sm={6}>

                    <LoadingButton
                        size="medium"
                        type="submit"
                        loading={loading}
                        variant="contained">
                            Guardar
                    </LoadingButton>

                </Grid>
                <Grid item xl={2} lg={3} md={6} xs={6} sm={6}>
                    <Button variant="outlined" 
                        size="medium"
                        type="button" 
                        onClick={handleCancelNewUniv}>Cancelar</Button>
                </Grid>
            </Grid>
            
        </form>
        
        </>
    );

    const OrgManage = () => (
        <>
        <h3 style={{ marginTop: "40px"}}>Gestión de organizaciones y sus claves de pre registro</h3>
        <hr style={{ marginTop: "44px", marginBottom: "40px" }} />
        <h3 ref={orgFormRef}>Agregar una organización:</h3>
        
        {FormOrg()}
        <hr id="seccion-tabla-org" style={{ marginTop: "51px", marginBottom: "42px" }}  />
        <h3>Organizaciones:</h3>
        <div style={{ height: 550, width: '100%' }}>
            <DataGrid 
                getRowId={ (row) => row.id_catalogo_organizacion}
                rows={organizaciones} 
                columns={orgColumns} 
                components={{ Toolbar: GridToolbar }} 
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'fecha_alta', sort: 'desc' }],
                    },
                    pagination: {
                        pageSize: 25,
                        }
                    }}/>
        </div>
        </>
    );

    const UnivManage = () => (
        <>
        <h3 style={{ marginTop: "40px"}}>Gestión de universidades y sus claves de pre registro</h3>
        <hr style={{ marginTop: "44px", marginBottom: "40px" }} />
        <h3 ref={univFormRef}>Agregar una universidad:</h3>
        
        {FormUniv()}
        <hr id="seccion-tabla-org" style={{ marginTop: "51px", marginBottom: "42px" }}  />
        <h3>Universidades:</h3>
        <div style={{ height: 550, width: '100%' }}>
            <DataGrid 
                getRowId={ (row) => row.id_catalogo_universidad}
                rows={universidades} 
                columns={univColumns} 
                components={{ Toolbar: GridToolbar }} 
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'fecha_alta', sort: 'desc' }],
                    },
                    pagination: {
                        pageSize: 25,
                        }
                    }}/>
        </div>
        </>
    );

    return (
        <>
        <AppBarAdmin />
        {ModalClavesOrg()}
        {ModalClavesUniv()}
        {ModalNewClavesUniv()}
        {ModalNewClavesOrg()}

        <div id="seccion-completa" className='card-main'>
            <Tabs
            variant="fullWidth"
            value={tabValue}
            indicatorColor='primary'
            onChange={handleTabChange}>
                <LinkTab label="Organizaciones"  {...a11yProps(0)} />
                <LinkTab label="Universidades"  {...a11yProps(1)} />
            </Tabs>

            {TabPanel({value: tabValue, index: 0, children: OrgManage()})}
            {TabPanel({value: tabValue, index: 1, children: UnivManage()})}

        </div>

        <Snackbar
            open={mensaje.mostrarMensaje}
            autoHideDuration={4500}
            onClose={()=>setMensaje({...mensaje,mostrarMensaje:false})}>
            <MuiAlert elevation={6} variant="filled" severity={mensaje.tipoMensaje}> {mensaje.textoMensaje} </MuiAlert>
        </Snackbar>
        <StickyFooter />  
        </>
    );
}